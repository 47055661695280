import React, {useState, useEffect, useContext, useRef} from "react"
import { NavLink} from "react-router-dom"
import {AppContext} from '../AppContext.js'
import OnboardingNavbar from "../components/Navbars/OnboardingNavbar"
import OnboardingTabbar from "../components/Navbars/OnboardingTabbar"
import Emoji from "react-emoji-render"
import UserViewModel from '../models/UserViewModel'
import {Mixpanel} from '../helpers/Mixpanel'

export default function TellUsMore(props){

  const context = useContext(AppContext);
  const timeoutRef = useRef(null);
  const current_user = context.current_user;
  const [user, setUser] = useState({...current_user.attributes()});
  const [jobOther, setJobOther] = useState(false);
  const [referrerOther, setReferrerOther] = useState(false);
  
  useEffect(() => { 
    Mixpanel.track("Tell Us More Page Loaded")
  }, []);

  const saveUser = (e) => {
    Mixpanel.track("Saving User",user)
    UserViewModel.update(user)
  }

  useEffect(() => {    
    
    if (user.should_save){
      
      if (timeoutRef.current !== null) {          // IF THERE'S A RUNNING TIMEOUT
        clearTimeout(timeoutRef.current);         // THEN, CANCEL IT
      }

      timeoutRef.current = setTimeout(()=> {
        timeoutRef.current = null;               
        saveUser()
      },500);       
    }

  },[user]);

  

  return (<div className="wrapper">
          <OnboardingNavbar />
          <div className="mt-2 mb-5">
            <div className="container">
              <OnboardingTabbar />
              <div className="card border-0">
              <div className="row justify-content-center">
                  {(current_user.hasActivePlan() && current_user.activeSubscription().free_pass) &&
                    <div className="col-8 text-center">
                      <h2 className="font-weight-bolder display-3 mt-4 mb-2">Hey! it's you, and you're<br/> getting Limelite for free...</h2>
                      <p className="lead">We want to take the opportunity to get to know you better, only if you want to though. I mean that's....ok right? <Emoji text=":sweat_smile:" /></p>
                    </div>
                  }
                  {(!current_user.hasActivePlan() || (current_user.hasActivePlan() && !current_user.activeSubscription().free_pass)) && <div className="col-8 text-center">
                      <h2 className="font-weight-bolder display-3 mt-4 mb-2">WOW this is getting serious,<br/>before we take the next step...</h2>
                      <p className="lead">We should get to know eachother, only if you want to though. <br/> I mean that's....ok right? <Emoji text=":sweat_smile:" /></p>
                    </div>
                  }
                  <div className="col-6 text-center">
                    <form className="text-left">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Your Name</label>
                        <input type="text" className="form-control text-capitalize" value={user.name} placeholder="Bill Lumberg" name="name" onChange={(e) => {
                           user['name'] = e.target.value;
                           user['should_save'] = true;
                           setUser({...user})
                        }} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Where do you work?</label>
                        <input type="text" className="form-control text-capitalize" value={user.company} id="exampleInputPassword1" placeholder="Initech" name="company" onChange={(e) => {
                          user['company'] = e.target.value;
                          user['should_save'] = true;
                          setUser({...user})
                        }} />
                        <small id="emailHelp" className="form-text text-muted">That place where you get paid...</small>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">What do they pay you for?</label>
                        <select className="form-control" name="job" selected={user.job} onChange={(e) => {
                          if (e.target.value == "Other") {
                            setJobOther(true)
                          }else{
                            user['job'] = e.target.value;
                            user['should_save'] = true;
                            setUser({...user})
                          }
                        }} >
                          <option>Choose your destiny</option>
                          <option>Because I'm amazing, Duh</option>
                          <option>Demos</option>
                          <option>Trainings</option>
                          <option>Presentations</option>
                          <option>Tutorials</option>
                          <option>Videos</option>
                          <option>Other</option>
                        </select>
                        <small id="emailHelp" className="form-text text-muted">Aside from being really incredibly good looking.</small>
                        {
                          jobOther &&  <input type="text" className="form-control mt-3 text-capitalize" placeholder="What do they pay you for then?" name="job" onChange={(e) => {
                              user['job'] = e.target.value;
                              user['should_save'] = true;
                              setUser({...user})
                          }} />
                          }
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Who told you about us?</label>
                        <select className="form-control" name="referrer" selected={user.referrer} onChange={(e) => {
                          if (e.target.value == "Other") {
                            setReferrerOther(true)
                          }else{
                            user['referrer'] = e.target.value;
                            user['should_save'] = true;
                            setUser({...user})
                          }
                        }} >
                          <option>Who sent you?</option>
                          <option>Uh, none ya business</option>
                          <option>Friend</option>
                          <option>LinkedIn</option>
                          <option>Other</option>
                        </select>
                        <small id="emailHelp" className="form-text text-muted">Yes they will be punished.</small>
                        {
                          referrerOther &&  <input type="text" className="form-control mt-3  text-capitalize" placeholder="We just need a name...that's all" name="referrer" onChange={(e) => {
                            user['referrer'] = e.target.value;
                            user['should_save'] = true;
                            setUser({...user})
                          }} />
                        }
                      </div>
                      <NavLink to="/download-extension" className="btn btn-default rounded-pill mx-auto btn-block mt-5">Next</NavLink>
                      <NavLink to="/download-extension" className="btn btn-link btn-sm text-light btn-block mt-3 rounded-pill mx-auto"> Skip this step </NavLink>
                    </form>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>);
};
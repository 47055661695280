import React, {Component} from "react"
import Emoji from "react-emoji-render"
import { NavLink } from "react-router-dom"
import {Container, UncontrolledTooltip} from "reactstrap"
import FAQ from "./FAQ"
import Slider from "nouislider"

// core components
import { useHotkeys } from 'react-hotkeys-hook'
import LandingNavbar from "../components/Navbars/LandingNavbar.js"
import {Mixpanel} from '../helpers/Mixpanel'
import Typed from 'react-typed'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

function UseHotkeys({hotkey,callback}) {
  useHotkeys(hotkey,callback)
  return null
}

class Landing extends Component {
  
  constructor(props) {

    super(props);
    
    this.slider_opacity = React.createRef();
    this.slider_radius = React.createRef();

  }

  state = {
    showModal:false,
    emailFocused:false,
    passwordFocused:false,  
    calculating:false,
    calculated:false,
    estimated_savings:false,
    estimated_fee:false,
    sending:false,
    sent:false,
    error:false,
    lastScrolledLeft:0,
    lastScrolledTop:0,
    opacity:0.4,
    size:100,
    xMousePos:0,
    yMousePos:0,
    inverted:false,
    show_mouse:true,
    has_lit:false,
    show_hotkey_holder:false,
    show_limelite:false,
    ab:Math.floor((Math.random() * 2) + 1)
  };

  componentDidMount() {
    
    window.addEventListener('scroll', this.handleScroll)
    //Set user if present
    let user_email = new URLSearchParams(window.location.search).get("u");
    if (user_email) Mixpanel.people.set({"$email": user_email});

    //Track landing page load
    Mixpanel.track("Landing Page Loaded",{ab:this.state.ab == 1 ? "Take the boring out" : "Take to the next level"});

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    this.refs.main.scrollTop = 0;

    let opacity_slider = Slider.create(this.slider_opacity.current, {
      start: [this.state.opacity],
      connect: [true, false],
      step: 0.01,
      range: { min: 0.1, max: 1 }
    })

    opacity_slider.on("update", (values, handle) => {
      this.setState({ opacity: values[0] });
    });

    opacity_slider.on("start",() => {
      Mixpanel.track("Customizing Opacity")
      this.setState({ light_on: true});
    });

    opacity_slider.on("end",(values) => {
      this.setState({opacity: values[0], light_on:false });
    });

    let radius_slider = Slider.create(this.slider_radius.current, {
      start: [this.state.size],
      connect: [true, false],
      step: 1,
      range: { min: 30, max: 500 }
    })

    radius_slider.on("update", (values, handle) => {
      this.setState({ size: parseInt(values[0]) });
    });

    radius_slider.on("start",() => {
      Mixpanel.track("Customizing Size")
      this.setState({ light_on: true});
    });

    radius_slider.on("end",(values) => {
      this.setState({ size: parseInt(values[0]), light_on:false });
    });

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  toggleModal = () => {
    this.setState({
      showModal:!this.state.showModal
    });
  };

  handleScroll = (event) => {

        var {xMousePos,yMousePos,lastScrolledLeft,lastScrolledTop} = this.state;

        if(lastScrolledLeft !== window.scrollX){
            xMousePos -= lastScrolledLeft;
            lastScrolledLeft = window.scrollX;
            xMousePos += lastScrolledLeft;
        }
        
        if(lastScrolledTop !== window.scrollY){
            yMousePos -= lastScrolledTop;
            lastScrolledTop = window.scrollY;
            yMousePos += lastScrolledTop;
        }

      this.setState({show_hotkey_holder:!this.state.has_lit,show_limelite:true,xMousePos:xMousePos,yMousePos:yMousePos,lastScrolledLeft:lastScrolledLeft,lastScrolledTop:lastScrolledTop})

  };

  updateMouseHouse = (event) => {
        var eventDoc, doc, body;

        event = event || window.event; 

        if (event.pageX == null && event.clientX != null) {
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            event.pageX = event.clientX +
              (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
              (doc && doc.clientLeft || body && body.clientLeft || 0);
            event.pageY = event.clientY +
              (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
              (doc && doc.clientTop  || body && body.clientTop  || 0 );
        }

        // Use event.pageX / event.pageY here
        this.setState({xMousePos:event.pageX,yMousePos:event.pageY})
  };

  toggleShowMouse = (e) => {
    Mixpanel.track("Show Mouse Toggle",{where:"Hotkey banner"})
    this.setState({show_mouse:!this.state.show_mouse,light_on:true,hardset:true});
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  toggleInverted = (e) => {
    Mixpanel.track("Inverted Toggle",{where:"Hotkey banner"})
    this.setState({inverted:!this.state.inverted,light_on:true,hardset:true});
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  toggleMouseHouse = (e) => {
    this.setState({light_on:!this.state.light_on,hardset:!this.state.light_on,has_lit:true,show_hotkey_holder:false})
    e.preventDefault();
    e.stopPropagation();
    return false;
  };


  render() {

    let {
      ab,
      hovering,
      light_on,
      xMousePos,
      yMousePos,
      inverted,
      show_mouse,
      show_hotkey_holder,
      show_limelite,
      opacity,
      size
    } = this.state;

    let limelite_on = light_on;
    let halfSize = size/2;
    let device = navigator.platform.indexOf('Mac') > -1 ? "Mac" : "PC"

    return <span onMouseMove={this.updateMouseHouse}>
            <UseHotkeys hotkey={device === "Mac" ? 'control+d' : 'alt+shift+d'} callback={(e) => {
              Mixpanel.track("HotKey Toggle")
              this.toggleMouseHouse(e)
            }} />
            <LandingNavbar />
            <main ref="main" >

              <div className={`limelite-hole-local ${inverted && 'inverted'} ${show_mouse && 'show_mouse'} ${!limelite_on && 'inactive'}`} style={limelite_on ? {width:size,height:size,opacity:opacity,top:`${yMousePos - halfSize}px`,left:`${xMousePos - halfSize}px`} : {}} />
              
              <div className={`text-center hotkey-holder d-none ${(limelite_on || show_hotkey_holder) && 'd-sm-block'} animate__animated animate__fadeInUp`}>
                <div className={`w-75 mx-auto ${(inverted || show_hotkey_holder) && 'bg-dark rounded'}`}>
                  <h1 className={`text-white display-4 pt-2`}>Use hotkey 
                    {device === "Mac" && <><kbd className='bg-white text-dark ml-2 px-3 shadow-sm' ><small>Ctrl</small></kbd> + <kbd className='bg-white text-dark mr-2 px-3 shadow-sm' ><small>D</small></kbd> </>}
                    {device !== "Mac" && <><kbd className='bg-white text-dark ml-2 px-3 shadow-sm' ><small>Alt</small></kbd> + <kbd className='bg-white text-dark mr-2 px-3 shadow-sm' ><small>Shift</small></kbd> + <kbd className='bg-white text-dark mr-2 px-3 shadow-sm' ><small>D</small></kbd> </>}
                  to toggle Spotlight</h1>
                  <ul className="nav justify-content-center pb-3">
                    <li className="nav-item">
                      <a className="nav-link text-white" href="#" onClick={this.toggleShowMouse} ><small><i className="fa fa-mouse-pointer"></i> Toggle Mouse</small></a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link text-white" href="#" onClick={this.toggleInverted} ><small><i className="fa fa-dot-circle-o"></i> Invert Spotlight</small></a>
                    </li>
                    <li className="nav-item">
                      <NavLink to={"/users/sign_up?f=Hot Key Description"} className="nav-link text-white" >
                        <small>Download Extension <i className="ni ni-curved-next"></i></small>
                      </NavLink>
                    </li>
                  </ul>
                </div>  
              </div>  
              <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped" onMouseEnter={() => this.setState({hovering:true})}  onMouseMove={() => { if (!hovering) this.setState({hovering:true}) }} onMouseLeave={() => this.setState({hovering:false})} >
                  <div className="shape shape-style-1 shape-success" >
                    <span className={`span-150 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-75 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-75 animate__animated animate__fadeInUp ${limelite_on && 'd-none'}`} style={hovering ? {position:"absolute",top:`${yMousePos - 100}px`,left:`${xMousePos - 100}px`} : {}}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  </div>
                  <Container className="py-lg-md d-flex">
                    <div className="col px-0">
                      <div className="row">
                        <div className="col-lg-7 text-left pt-100 pb-100">
                        <h1 className={`text-white display-2 typed mt-3 mt-sm-0 ${ab > 1 && 'd-none'}`}>Take the boring out of</h1>
                        <h1 className={`text-white display-2 typed mt-3 mt-sm-0 ${ab > 1 && 'd-none'}`}><Typed strings={[
                        'your presentations',
                        'your onboarding',
                        'your tutorials',
                        'your demos.']}
                        typeSpeed={60}
                        backSpeed={60}
                        backDelay={1500}
                        startDelay={1000}
                        smartBackspace
                        >
                    </Typed></h1>
                          <h1 className={`text-white display-2 typed mt-3 mt-sm-0 ${ab == 1 && 'd-none'}`}><Typed strings={[
                        'Take your presentations',
                        'Take your onboarding',
                        'Take your tutorials',
                        'Take your demos']}
                        typeSpeed={60}
                        backSpeed={60}
                        backDelay={1500}
                        startDelay={1000}
                        smartBackspace
                        >
                    </Typed></h1>
                          <h1 className={`text-white display-2 ${ab == 1 && 'd-none'}`}>to the  next level.</h1>
                          <p className="lead text-white">Limelite is an easy-to-use annotation tool that brings your screen to life. <span className="font-weight-bold">Highlight, spotlight and animate</span> any communications with a <span className="font-weight-bold">simple hotkey</span>.</p>
                          <div className="btn-wrapper mt-4">
                            <NavLink to={ "/users/sign_up?f=Hero"} className="btn btn-lg btn-default mr-3" >
                              Download Extension
                            </NavLink>
                            <button className="btn btn-lg btn-info d-none d-sm-inline" type="button" onClick={() => {
                                Mixpanel.track("Live Demo Clicked")
                                this.toggleMouseHouse()
                              }
                              } >
                              Live Demo <Emoji text=":point_right:" />
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-5 mt-md-5 mt-lg-0 px-0 animate__animated animate__pulse pt-100 d-none d-lg-block">
                        <div className="browser-container floating-sm bg-white rounded shadow" onMouseEnter={() => {
                          Mixpanel.track("Hovered on Browser Demo")
                          this.setState({light_on:true,has_lit:true,show_hotkey_holder:false})
                          }}  onMouseLeave={() => {if (this.state.light_on && !this.state.hardset) this.setState({light_on:false})}}  onMouseMove={this.updateMouseHouse}>
                            <div className="browser-row px-1">
                              <div className="browser-column left">
                                <span className="dot" style={{"background":"#ED594A"}}></span>
                                <span className="dot" style={{"background":"#FDD800"}}></span>
                                <span className="dot" style={{"background":"#5AC05A"}}></span>
                              </div>
                              <div className="browser-column middle text-center">
                                <div className="search-bar">http://www.mytutorialsite.com</div>
                              </div>
                              <div className="browser-column right text-left">
                              <img className="shadow ml-2" src={"https://getlimelite.s3-us-west-2.amazonaws.com/lime-neg128.png"} style={{width:"13px"}} /> 
                              </div>
                            </div>

                            <div className="content">
                              <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} alt={"https://getlimelite.s3-us-west-2.amazonaws.com/brand/limelite_browser.svg"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/brand/limelite_browser.svg"} /> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>       
                  </Container>
                  {/* SVG separator */}
                  <div className="separator separator-bottom separator-skew zindex-100 d-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                    >
                      <polygon
                        className="fill-white"
                        points="2560 0 2560 100 0 100"
                      />
                    </svg>
                  </div>
                </section>
                {/* 1st Hero Variation */}
              </div>
              <div className="section features-1" id="clients">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col text-center">
                      <p className="text-muted text-uppercase"><strong>Trusted by presenters everywhere</strong></p>
                    </div>
                  </div>
                  <div className="row align-items-center px-0 animate__animated animate__pulse pb-5">
                    <div className="col">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/amazon.png"} alt="Amazon Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col d-none d-sm-block">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/crowdstrike.png"} alt="Crowdstrike Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/docusign.png"} alt="Docusign Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col d-none d-sm-block">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/fullstory.png"} alt="Fullstory Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col d-none d-sm-block">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/outreach.png"} alt="Outreach Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/salesforce.png"} alt="Salesforce Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col d-none d-sm-block">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/splunk.png"} alt="Splunk Limelite presenter" className="img-fluid" />
                    </div>
                    <div className="col">
                      <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/trusted/uber.png"} alt="Uber Limelite presenter" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="separator separator-bottom separator-skew zindex-100 d-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                    >
                      <polygon
                        className="fill-secondary"
                        points="2560 0 2560 100 0 100"
                      />
                    </svg>
                  </div>
              </div>
              <div className="section features-1 bg-secondary" id="how-it-works">
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 col-lg-8 mx-auto text-center">
                      <h3 className="mb-5"><span className="badge badge-default badge-pill shadow-sm">How it Works</span></h3>
                      <h3 className="display-3">A lightweight, chrome extension<br/>activated in a keystroke</h3>
                      <p>Limelite is that little extra bit that goes a long way.</p>
                    </div>
                  </div><br/><br/>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="info text-center">
                        <div className="icon icon-lg icon-shape icon-shape-default shadow rounded-circle">
                          <i className="ni ni-laptop"></i>
                        </div>
                        <h6 className="info-title text-uppercase text-default">Compatible</h6>
                        <p className="description opacity-8">Works with all video conferencing softwares.</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info text-center">
                        <div className="icon icon-lg icon-shape icon-shape-default shadow rounded-circle">
                          <i className="ni ni-app"></i>
                        </div>
                        <h6 className="info-title text-uppercase text-default">Dynamic</h6>
                        <p className="description opacity-8">Improve sales demos by guiding the audience's attention</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info text-center">
                        <div className="icon icon-lg icon-shape icon-shape-default shadow rounded-circle">
                          <i className="ni ni-satisfied"></i>
                        </div>
                        <h6 className="info-title text-uppercase text-default">Customizable</h6>
                        <p className="description opacity-8">Instantly enhance product demos, connect with customers with your customizable spotlight.</p>
                        
                      </div>
                    </div>
                  </div>
                  <div className="row py-5">
                    <div className="col text-center">
                      <NavLink to={ "/users/sign_up?f=How it works"} className="btn btn-lg btn-default mr-3" >
                        Download Extension
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <section className="section section-lg section-shaped d-none d-sm-block" onMouseEnter={() => this.setState({hovering:true})}  onMouseLeave={() => this.setState({hovering:false})} >
                  <div className="shape shape-style-1 shape-dark" >
                    <span className={`span-150 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-75 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-75 animate__animated animate__fadeInUp ${limelite_on && 'd-none'}`} style={hovering ? {position:"absolute",top:`${yMousePos - 100}px`,left:`${xMousePos - 100}px`} : {}}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                    <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  </div>
                  <div className="container-lg">
                      <div className="row justify-content-center">
                        <div className="col-12 col-lg-6 text-white text-center">
                         <h1 className="display-2 mb-1 text-white">Customize your spotlight</h1>
                         <p className="lead text-white mt-0 mb-5">Invert it, hide the mouse, update the opacity, size and more.</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-md-5 mt-lg-0 animate__animated animate__pulse d-none d-sm-block">
                        <div className="browser-container floating-sm bg-white rounded shadow" onMouseEnter={() => {
                          Mixpanel.track("Hovered on Customize It Browser Demo")
                          this.setState({light_on:true,has_lit:true,show_hotkey_holder:false})
                          }}  onMouseLeave={() => {if (this.state.light_on && !this.state.hardset) this.setState({light_on:false})}}  onMouseMove={this.updateMouseHouse}>
                            <div className="browser-row px-1">
                              <div className="browser-column left">
                                <span className="dot" style={{"background":"#ED594A"}}></span>
                                <span className="dot" style={{"background":"#FDD800"}}></span>
                                <span className="dot" style={{"background":"#5AC05A"}}></span>
                              </div>
                              <div className="browser-column middle text-center">
                                <div className="search-bar">http://www.mytutorialsite.com</div>
                              </div>
                              <div className="browser-column right text-left">
                                <img className="shadow ml-2" src={"https://getlimelite.s3-us-west-2.amazonaws.com/lime-neg128.png"} style={{width:"13px"}} /> 
                              </div>
                            </div>

                            <div className="content">
                              <img className="img-fluid" src={"https://getlimelite.s3-us-west-2.amazonaws.com/brand/limelite_browser.svg"} /> 
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-center">
                         <p className="h1 text-white mb-lg-5"><Emoji text=":point_down:" /> Change it</p>
                          <div className="card mx-auto w-75 mb-lg-5 shadow">
                            <div className="card-body">
                              <h5 className="card-title">Spotlight settings</h5>
                              <div className="row">
                                <div className="col text-right mb-lg-4">
                                  <button id="invert-button" type="button" onClick={() => {
                                      Mixpanel.track("Inverted Toggle",{where:"Customize it section"})
                                      this.setState({inverted:!inverted})
                                    }} className={`${inverted ? 'btn-neutral text-dark' : 'btn-dark'} btn btn-sm rounded-circle`}>
                                    <i className={`fa fa-circle`} aria-hidden="true"></i>
                                  </button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="invert-button"
                                    trigger="hover focus"
                                  >
                                    Invert spotlight
                                  </UncontrolledTooltip>
                                  <button id="mouse-button" type="button" onClick={() => {
                                      Mixpanel.track("Show Mouse Toggle",{where:"Customize it section"})
                                      this.setState({show_mouse:!show_mouse})
                                    }} className={`btn-neutral ${show_mouse ? 'text-dark' : 'text-light'} btn btn-sm rounded-circle`}>
                                    <i className="fa fa-mouse-pointer" aria-hidden="true"></i>
                                  </button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="mouse-button"
                                    trigger="hover focus"
                                  >
                                    Toggle mouse shown
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div className="row mb-4" key="opacity_slider">
                                <div className="col-6 text-left">
                                  <small className="text-muted">Opacity</small>
                                </div>
                                <div className="col-6 text-right">
                                  <small className="text-dark">{Math.abs(parseInt((opacity - 1) * 100))}%</small>
                                </div>
                                <div className="col-12">
                                  <div className="slider slider-success" key="slider_opacity" ref={this.slider_opacity} />
                                </div>
                              </div>
                              <div className="row mb-4" key="radius_slider">
                                <div className="col-6 text-left">
                                  <small className="text-muted">Size</small>
                                </div>
                                <div className="col-6 text-right">
                                  <small className="text-dark">{parseInt(size)}</small>
                                </div>
                                <div className="col-12">
                                  <div className="slider slider-success"  key="slider_radius" ref={this.slider_radius} />
                                </div>
                              </div>
                            </div>
                          </div>
                         <p className="h1 text-white mt-lg-5"><Emoji text=":point_left:" /> Test it</p>
                        </div>
                      </div>    
                        <div className="row pt-5 mt-5">
                          <div className="col text-center">
                            <NavLink to={ "/users/sign_up?f=Customize It"} className="btn btn-lg btn-neutral text-default shadow" >
                              Download Extension
                            </NavLink>
                          </div>
                        </div>
                  </div>
                  {/* SVG separator */}
                  <div className="separator separator-bottom separator-skew zindex-100 d-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                    >
                      <polygon
                        className="fill-white"
                        points="2560 0 2560 100 0 100"
                      />
                    </svg>
                  </div>
                </section>
              <div className="section features-1 bg-white">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <blockquote className="blockquote">
                        <h3 className="mb-4">"Before Limelite my demos weren't nearly as effective. Now with Limelite I get much more time to spend with my family <span className="font-weight-bolder">Limelite saved my marriage.</span>"</h3>
                        <footer className="blockquote-footer">Anonymous very real Limelite user</footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section features-1 bg-secondary" id="what">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 mx-auto text-center">
                      <h2 className="mb-1 display-3" >What are people saying?</h2>
                      <p className="lead mt-1">Spoiler alert! they like Limelite a lot :)</p>
                    </div>
                  </div><br/><br/>
                  <div className="row">
                    <div className="col mb-3">
                      <div className="card shadow-sm mb-4">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-3 col-lg-2">
                              <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/customers/customer-5.jpeg"}  alt="Rounded image" className="img-fluid rounded shadow align-self-center" /> 
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 mt-3 mt-md-0">
                              <blockquote className="blockquote pt-2">
                                <h5>LIT</h5>
                                <p className="my-2">“Limelite helps me show my work with my team, prospects, and sponsors quickly and effectively. I love how easy it is to make sure we're guiding our audience's attention to areas of interest and can draw attention to the things that matter.”</p>
                                <footer className="blockquote-footer">Scott Barker, <cite title="Source Title">SalesHacker</cite></footer>
                              </blockquote>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-sm mb-4 text-sm-right">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-9 col-lg-10 mt-3 mt-md-0 order-1 order-md-0">
                              <blockquote className="blockquote pt-2">
                                <h5>LIMELITE IS A DEAL CLOSER</h5>
                                <p className="my-2">"Limelite accompanies me on every sales call - with a simple keystroke I can quickly draw attention to exactly what I want my prospect to see and nail my sales pitch. If you’re focused on simplicity and value, Limelite is the best tool for the job.”</p>
                                <footer className="blockquote-footer">David Brudnicki, <cite title="Source Title">Sales Engineer</cite></footer>
                              </blockquote>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center text-center order-0 order-md-1">
                              <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/customers/customer-david.png"}  alt="Rounded image" className="img-fluid rounded shadow" /> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-sm mb-4">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-3 col-lg-2 text-center">
                              <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/customers/customer-annie.jpg"}  alt="Rounded image" className="img-fluid rounded shadow" /> 
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 mt-3 mt-md-0">
                              <blockquote className="blockquote pt-2">
                                <h5>MY PERFECT DEMO TOOL</h5>
                                <p className="my-2">“I use Limelite constantly, whether to quickly explain or show something to someone else. It takes so little time and helps quickly show prospects how to use areas of our software.”</p>
                                <footer className="blockquote-footer">Annie Roche, <cite title="Source Title">Account Executive</cite></footer>
                              </blockquote>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-sm mb-4">
                        <div className="card-body text-sm-right">
                          <div className="row">
                            <div className="col-12 col-md-9 col-lg-10 mt-3 mt-md-0 order-1 order-md-0">
                              <blockquote className="blockquote pt-2">
                                <h5>MY GO-TO FOR DEMOING</h5>
                                <p className="my-2">“For anyone selling software, this is a must. You can captivate your buyer and drive focus to key features instead of their attention wonder all over the screen. It reduces complexity to the buyer and simplifies how they view the tool. I'll never be caught in a demo without LimeLite!"</p>
                                <footer className="blockquote-footer">Justin Reese, <cite title="Source Title">Account Executive</cite></footer>
                              </blockquote>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 text-center order-0 order-md-1">
                              <LazyLoadImage effect="blur" className="img-fluid" width={"100%"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/customers/customer-justin.jpeg"}  alt="Rounded image" className="img-fluid rounded shadow" /> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-5">
                    <div className="col text-center">
                      <NavLink to={"/users/sign_up?f=Testimonials"} className="btn btn-lg btn-default mr-3" >
                        Download Extension
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <FAQ />
              <footer className="footer bg-secondary">
                <div className="container">
                  <div className="row align-items-center justify-content-md-between">
                    <div className="col-md-6">
                      <div className="copyright text-default">
                        &copy; 2020 Limelite Enterprises LLC <br/> 
                        <img className="mr-1" src={"https://getlimelite.s3-us-west-2.amazonaws.com/lime-neg-grayblue256.png"} style={{width:"20px"}} />  <small><a href="/terms" target="_blank" onClick={() => Mixpanel.track("Terms clicked", {"position": "footer"}) } className="text-default">Terms</a> &middot; <a href="/privacy" target="_blank" onClick={() => Mixpanel.track("Privacy clicked", {"position": "footer"}) } className="text-default">Privacy</a></small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ul className="nav nav-footer justify-content-end">
                        <li className="nav-item">
                          <a href="#clients" className="nav-link" onClick={() => Mixpanel.track("Clients clicked", {"position": "footer"}) } >Clients</a>
                        </li>
                        <li className="nav-item">
                          <a href="#how-it-works" className="nav-link" onClick={() => Mixpanel.track("How it works clicked", {"position": "footer"}) } >How it Works</a>
                        </li>
                        <li className="nav-item">
                          <a href="#what" className="nav-link" onClick={() => Mixpanel.track("Reviews clicked", {"position": "footer"}) } >Say what?</a>
                        </li>
                        <li className="nav-item">
                          <a href="#faw" className="nav-link" onClick={() => Mixpanel.track("Tell Me More clicked", {"position": "footer"}) } >What about...</a>
                        </li>
                        <li className="nav-item">
                          <a href="#contact" className="nav-link" onClick={() => Mixpanel.track("Contact clicked", {"position": "footer"}) } >Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>    
            </main>
          </span>;
  }
}

export default Landing;

import React, {useContext} from "react"
import { NavLink,useParams} from "react-router-dom"
import {AppContext} from '../../AppContext.js'
import {Mixpanel} from '../../helpers/Mixpanel';

const OnboardingTabbar = (props) => {

  let { id } = useParams();
  let {clickable} = props;

  const context = useContext(AppContext);
  const current_user = context.current_user;
  const has_multiple_seats_plan = current_user.hasMultipleSeatsPlan() && current_user.isSubscriptionOwner();
  
    if (clickable !== undefined && clickable == false){
      return  <ul className="nav nav-pills nav-pills-default nav-fill flex-column flex-md-row mb-3"  id="tabs-icons-text" role="tablist">
          {
            (!current_user.hasActivePlan() || current_user.isSubscriptionOwner()) && 
                <li className="nav-item">
                  <a className={`nav-link mb-sm-3 mb-md-0 shadow-none disabled`} disabled href="#"><strong>1.</strong> Pick a plan</a>
                </li>
          }
          <li className="nav-item">
            <a className={`nav-link mb-sm-3 mb-md-0 shadow-none disabled`} disabled href="#"><strong>{!current_user.isSubscriptionOwner() ? "1" : "2"}.</strong> Tell us more</a>
          </li>

          {
            has_multiple_seats_plan &&
            <li className="nav-item">
              <a className={`nav-link mb-sm-3 mb-md-0 shadow-none disabled`} disabled href="#"><strong>3.</strong> Workspace</a>
            </li>
          }
          <li className="nav-item">
          <a className={`nav-link mb-sm-3 mb-md-0 shadow-none disabled`} disabled href="#"><strong>{has_multiple_seats_plan ? "4":<span>{!current_user.isSubscriptionOwner() ? "2" : "3"}</span>}.</strong> Download Limelite</a>
          </li>
        </ul>;
    }

    return <ul className="nav nav-pills nav-pills-default nav-fill flex-column flex-md-row mb-3"  id="tabs-icons-text" role="tablist">
        {
        (!current_user.hasActivePlan() || current_user.isSubscriptionOwner()) && <li className="nav-item"  onClick={() => Mixpanel.track("Pick a plan clicked", {"position": "header"}) }>
          <NavLink className={`nav-link mb-sm-3 mb-md-0 ${id && 'shadow-none'}`} to="/plans"><strong>1.</strong> Pick a plan</NavLink>
        </li>
        }
        <li className="nav-item"  onClick={() => Mixpanel.track("Tell us more clicked", {"position": "header"}) }>
          <NavLink className={`nav-link mb-sm-3 mb-md-0 ${id && 'shadow-none'}`} to="/tell-us-more"><strong>{!current_user.isSubscriptionOwner() ? "1" : "2"}.</strong> Tell us more</NavLink>
        </li>
        {
          has_multiple_seats_plan &&
          <li className="nav-item">
            <NavLink className={`nav-link mb-sm-3 mb-md-0 ${id && 'shadow-none'}`} to="/workspaces/new"><strong>3.</strong> Workspace</NavLink>
          </li>
        }
        <li className="nav-item"  onClick={() => Mixpanel.track("Download Limelite clicked", {"position": "header"}) }>
        <NavLink className={`nav-link mb-sm-3 mb-md-0 ${id && 'shadow-none'}`} to="/download-extension"><strong>{has_multiple_seats_plan ? "4":<span>{!current_user.isSubscriptionOwner() ? "2" : "3"}</span>}.</strong> Download Limelite</NavLink>
        </li>
      </ul>;
}

export default OnboardingTabbar;

import React, { useState } from "react";
import { Collapse} from 'reactstrap';
import {Mixpanel} from '../helpers/Mixpanel';

const FAQ = (props) => {

  const [openIndex, setOpenIndex] = useState(0);
  const FAQS = [
    {
      q:'Does Limelite only work on other browsers?',
      a:'Only on Chromium based browsers for now (Chrome, Brave, etc).'
    },
    {
      q:'I’m super important and busy - is trying Limelite worth my time?',
      a:'Worth your time. Worth your money. So good you’ll tell your friends. '
    },
    {
      q:'What is the future for Limelite? Is this just a spotlight app?',
      a:'That’s a secret (for now).'
    },
    {
      q:'Is it complicated? I’m not very tech savvy. ',
      a:'Grandma just left us a testimonial: “Hi Sweetie, I can’t believe how easy this is”'
    },
    {
      q:'Do you really expect me to pay for this?',
      a:'We don’t expect you to, but we promise it’s going to be worth it. '
    },
    {
      q:'Does Limelite do animal testing? ',
      a:'Only on pictures of cool cats & kittens. - Carole Baskin'
    },
    {
      q:'Who is this for?',
      a:'Sales professionals, educators, marketers. Anyone with Zoom.'
    },
    {
      q:'How’d you come up with the idea for the product?',
      a:'Waving mouses made us dizzy.'
    },
    {
      q:'What makes Limelite special?',
      a:'The world’s easiest app. Prove us wrong.'
    },
    {
      q:'Describe your products in 3 words.',
      a:'Donut on screen.'
    },
    {
      q:'What’s the problem with just waving my mouse in circles anyway?',
      a:'Please don’t do that to people.'
    },
    {
      q:'Why is “abbreviated” such a long word?',
      a:'TLDR; LOL'
    },
    {
      q:'My question wasn’t answered here.',
      a:'Reach out to hello@getlimelite.com so we can help or use the chat!'
    }
  ];
  
  const toggle = (index) => {
    Mixpanel.track(`Toggled FAQ - ${FAQS[index].q}`,FAQS[index])
    index == openIndex ? setOpenIndex(-1) : setOpenIndex(index);
  }


  return ( <div className="section bg-white" id="faq">
    <div className="accordion-1">
      <div className="container">
        <div className="row">
          <div className="col-10 col-lg-6 mx-auto text-center">
            <h2 className="mt-5 mb-4 display-3">Frequently asked questions</h2>
            <p className="mb-5">Questions get asked and we try to provide all the answers we can. If we missed something let us know alright?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ml-auto">
            <div className="accordion shadow-sm" id="accordionExample">
              {
                FAQS.map((faq,index) => 
                <div className="card" key={`faq_index_${index}`}>
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <p className="clickable mb-0 font-weight-bolder w-100 text-default text-left collapsed" type="button" onClick={() => toggle(index)} >
                        {faq.q}
                        <i className={`ni ni-bold-${openIndex == index ? 'up' : 'down'} float-right pt-1`}></i>
                      </p>
                    </h5>
                  </div>
                  <Collapse isOpen={openIndex == index}>
                    <div className="card-body opacity-8">
                    {faq.a}
                  </div>
                  </Collapse>
                </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default FAQ;

import React, {useState, useEffect} from "react"
import { NavLink} from "react-router-dom";
import { getParam } from '../helpers/QueryParameters'
import FeedbackNavbar from "../components/Navbars/FeedbackNavbar"
import Emoji from "react-emoji-render"
import FeedbackViewModel from '../models/FeedbackViewModel'
import UserViewModel from '../models/UserViewModel'
import {Mixpanel} from '../helpers/Mixpanel'

export default function Feedback(props){

  const [feedback, setFeeback] = useState({answers:[],comments:''});
  const [creating, setCreating] = useState(false);
  const [created, setCreated] = useState(false);

  useEffect(() => { 
    Mixpanel.track("Feedback Page Loaded")
    UserViewModel.update({has_seen_tutorial:false})
  }, []);

  let saveTimer = false;

  const saveFeedback = (e) => {
    setCreating(true)
    Mixpanel.track("Saving Feedback",{feedback:feedback})
    FeedbackViewModel.create(feedback,(response) => {
      if (response instanceof FeedbackViewModel) {
        setCreated(true)
        setCreating(false)
        setTimeout(location.href="/home",5000);
      }
      else console.log("error",response)
    })
  }



  return (<div className="wrapper">
          <FeedbackNavbar />
          <div className="mt-2 mb-5">
            <div className="container-lg">
              <div className="card border-0">
              <div className="row justify-content-center">
                  <div className="col-12 text-center mb-4">
                    <h2 className="font-weight-bolder display-3 mt-4 mb-1">Help Us Improve</h2>
                    <p className="lead mt-0">We'd love to get a little feedback from you <Emoji text=":sweat_smile:" /></p>
                  </div>
                  {created ? <div className="text-center">
                    <h1 className="mb-5 mt-5"><i className="text-white ni ni-check-bold bg-success p-3 shadow-sm rounded-circle" /></h1>
                    <p> Thanks for the feedback!</p>
                  </div> :
                    <>
                  <div className="col-6 d-flex">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/dQw4w9WgXcQ?start=43&autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay></iframe>
                    </div>
                  </div>
                  <div className="col-6">
                    <h2 className="font-weight-bolder display-4 mt-4 mb-4">Why did you uninstall LimeLite?</h2>
                    <small className="text-muted">Check all that apply:</small>
                    <form className="text-left mt-4">
                      <div className="form-check mb-2">
                        <input className="form-check-input mt-2 mr-3" type="checkbox" value="" id="websites" onChange={(e) => {
                          
                          if(e.target.checked) feedback.answers.push("LimeLite doesn't work on my favorite websites")
                          else {
                            let index = feedback.answers.findIndex((a) => a === "LimeLite doesn't work on my favorite websites")
                            if (index > -1) feedback.answers.splice(index,1)
                          }

                          setFeeback(feedback)

                        }} />
                        <label className="form-check-label h5" htmlFor="websites">
                          Limelite doesn't work on my favorite websites
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input className="form-check-input mt-2 mr-3" type="checkbox" value="" id="google_docs" onChange={(e) => {
                          
                          if(e.target.checked) feedback.answers.push("LimeLite doesn't work on Google Docs")
                          else {
                            let index = feedback.answers.findIndex((a) => a === "LimeLite doesn't work on Google Docs")
                            if (index > -1) feedback.answers.splice(index,1)
                          }

                          setFeeback(feedback)

                        }} />
                        <label className="form-check-label h5" htmlFor="google_docs">
                          Limelite doesn't work on Google Docs
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input className="form-check-input mt-2 mr-3" type="checkbox" value="" id="figma" onChange={(e) => {
                          
                          if(e.target.checked) feedback.answers.push("LimeLite doesn't work on Figma")
                          else {
                            let index = feedback.answers.findIndex((a) => a === "LimeLite doesn't work on Figma")
                            if (index > -1) feedback.answers.splice(index,1)
                          }

                          setFeeback(feedback)

                        }}/>
                        <label className="form-check-label h5" htmlFor="figma">
                          Limelite doesn't work on Figma
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input className="form-check-input mt-2 mr-3" type="checkbox" value="" id="watermark" onChange={(e) => {
                          
                          if(e.target.checked) feedback.answers.push("The watermark gets on my nerves")
                          else {
                            let index = feedback.answers.findIndex((a) => a === "The watermark gets on my nerves")
                            if (index > -1) feedback.answers.splice(index,1)
                          }

                          setFeeback(feedback)

                        }}/>
                        <label className="form-check-label h5" htmlFor="watermark">
                          The watermark gets on my nerves
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input className="form-check-input mt-2 mr-3" type="checkbox" value="" id="expensive" onChange={(e) => {
                          
                          if(e.target.checked) feedback.answers.push("LimeLite is too expensive")
                          else {
                            let index = feedback.answers.findIndex((a) => a === "LimeLite is too expensive")
                            if (index > -1) feedback.answers.splice(index,1)
                          }

                          setFeeback(feedback)

                        }}/>
                        <label className="form-check-label h5" htmlFor="expensive">
                          Limelite is too expensive
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input className="form-check-input mt-2 mr-3" type="checkbox" value="" id="malcontent" onChange={(e) => {
                          
                          if(e.target.checked) feedback.answers.push("I'm a malcontent and will never find peace in anything, sigh")
                          else {
                            let index = feedback.answers.findIndex((a) => a === "I'm a malcontent and will never find peace in anything, sigh")
                            if (index > -1) feedback.answers.splice(index,1)
                          }

                          setFeeback(feedback)

                        }}/>
                        <label className="form-check-label h5" htmlFor="malcontent">
                          I'm a malcontent and will never find peace in anything, sigh
                        </label>
                      </div>
                      <div className="form-group mt-5 mb-3">
                        <label for="exampleFormControlTextarea1">Additional Comments</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" onChange={(e) => {
                          feedback.comments = e.target.value;
                          setFeeback(feedback)

                        }}></textarea>
                      </div>
                      <button className="btn btn-default rounded-pill mx-auto btn-block mt-5" onClick={saveFeedback} disabled={creating} >Send {creating && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>
                    </form>
                  </div>
                    </>}
              </div>
              </div>
            </div>
          </div>
        </div>);
};
import mixpanel from 'mixpanel-browser';
import { getMetaTag } from './Helpers'

let env_check = true;
let initialized = false;

let actions = {
  identify: (id) => {
    if (!initialized) {
      mixpanel.init(getMetaTag("mixpanel-token"))
      initialized = true
    }
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (!initialized) {
      mixpanel.init(getMetaTag("mixpanel-token"))
      initialized = true
    }
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (!initialized) {
      mixpanel.init(getMetaTag("mixpanel-token"))
      initialized = true
    }
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (!initialized) {
        mixpanel.init(getMetaTag("mixpanel-token"))
        initialized = true
      }
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
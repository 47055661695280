import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {AppContext,AppProvider} from './AppContext'
import Landing from "./views/Landing"
import Plans from "./views/Plans"
import {Plan} from "./views/Plan"
import Feedback from "./views/Feedback"
import TellUsMore from "./views/TellUsMore"
import WorkspaceNew from "./views/WorkspaceNew"
import {Tutorial} from "./views/Tutorial"
import {Download} from "./views/Download"
import Home from "./views/Home"
import Share from "./views/Share"
import Signup from "./views/Signup"

export default class App extends React.Component {

  render() {
    
    return <AppProvider>
            <AppContext.Consumer>
              {
                (context) => 
                <BrowserRouter>
                  <Switch>
                    <Route path="/" exact render={props => context.current_user ? <Redirect to="/home" /> : <Landing {...props} context={context} />} />
                    <Route path="/home" exact render={props => <Home {...props} context={context} />} />
                    <Route path="/share" exact render={props => <Share {...props} context={context} />} />
                    <Route path="/plans/:id" render={props => <Plan key={location.pathname} context={context} {...props} />} />
                    <Route path="/feedbacks" render={props => <Feedback key={location.pathname} context={context} {...props} />} />
                    <Route path="/plans" render={props => <Plans key={location.pathname} context={context} {...props} />} />
                    <Route path="/tell-us-more" render={props => <TellUsMore key={location.pathname} context={context} {...props} />} />
                    <Route path="/workspaces/new" render={props => <WorkspaceNew key={location.pathname} context={context} {...props} />} />
                    <Route path="/workspaces/:id" render={props => <WorkspaceNew key={location.pathname} context={context} {...props} />} />
                    <Route path="/tutorial" render={props => <Tutorial key={location.pathname} context={context} {...props} />} />
                    <Route path="/download-extension" render={props => <Download key={location.pathname} context={context} {...props} />} />
                    <Route path="/users/sign_up" exact render={props => <Signup key="sign_up" {...props} context={context} view={"sign_up"} />} />
                    <Route path="/users/sign_in" exact render={props => <Signup key="sign_in" {...props} context={context} view={"sign_in"} />} />
                    <Route path="/users/password/new" exact render={props => <Signup key="reset_password" {...props} context={context} view={"password/new"} />} />
                    <Route path="/users/password/edit" exact render={props => <Signup key="reset_password" {...props} context={context} view={"password/edit"} />} />
                    <Route path="/users/invitation/accept" exact render={props => <Signup key="sign_up" {...props} context={context} view={"sign_up"} />} />
                    <Redirect to="/home" />
                  </Switch>
                </BrowserRouter>
              }
            </AppContext.Consumer>
          </AppProvider>;
    
  }
}
import axios from 'axios';
import ViewModel from './ViewModel';
import PriceViewModel from './PriceViewModel';
import {toQueryString} from '../helpers/Helpers'

export default class PlanViewModel extends ViewModel {
  
  static url = "/plans"

  static all = (params = {},callback) => {

    axios.get(`${this.url}.json?${toQueryString(params)}`).then((response) => {
      callback(response.data.map((p) => new PlanViewModel(p) ))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static load = (id,callback) => {

    axios.get(`${this.url}/${id}.json`).then((response) => {
      callback(new PlanViewModel(response.data))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static create = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`${this.url}`,{plan:data}, options).then((response) => {
      callback(new PlanViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  static update = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`${this.url}`,{plan:data}, options).then((response) => {
      callback(new PlanViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  constructor(object) {
    super(object)
    this.prices = object.prices.map((p) => new PriceViewModel(p))
  }

  shortInterval = () => {
    if (this.interval == "month") return "mo";
    return "yr"
  }

}
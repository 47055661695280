import React, { useState, useContext, useEffect }  from "react"
import { NavLink } from "react-router-dom"
import {AppContext} from '../AppContext.js';
import {Mixpanel} from '../helpers/Mixpanel'
import UserViewModel from '../models/UserViewModel'
import WorkspaceViewModel from '../models/WorkspaceViewModel'
import WorkspaceUserViewModel from '../models/WorkspaceUserViewModel'
import EmailShareInput from './EmailShareInput'
import WorkspaceUserButton from './WorkspaceUserButton'
import WorkspaceUserLeaveButton from './WorkspaceUserLeaveButton'
import {useCopyToClipboard} from 'react-use'
import {Loader} from "../components/Loader"
import {UncontrolledPopover,PopoverHeader,PopoverBody} from 'reactstrap';
import Moment from 'react-moment'
import 'moment-timezone'
Moment.globalLocal = true;

export default function Workspace(props){

    const context = useContext(AppContext);
    const current_user = context.current_user;
    const [workspaceUser,setWorkspaceUser] = useState({...new WorkspaceUserViewModel(props.workspace_user)})
    const [workspace,setWorkspace] = useState({...new WorkspaceViewModel(props.workspace)})
    const [workspaceUsers,setWorkspaceUsers] = useState([])
    const [state, copyToClipboard] = useCopyToClipboard();
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    
    const inviteUsers = (emails) => {
      
      let temp_emails = emails.filter((e) => !e.sent ).map((e) => e.email );
  
      Mixpanel.track("Inviting workspace users via Email",{emails:temp_emails,workspace_id:workspace.id})
  
      setSending(true)
  
      UserViewModel.invite({emails:temp_emails,workspace_id:workspace.id},(new_emails) => {
        setEmails([...new_emails])
        setSending(false)
        setSent(true)
      })
  
    }

    const loadWorkspaceUsers = () => {
      WorkspaceViewModel.loadUsers(workspace.objectId,(users,error) => {
        setLoading(false)
        if (users) {
          setWorkspaceUsers([...users])
        }
      })
    }

    useEffect(() => { 
      Mixpanel.track("Workspace Page Loaded")
      loadWorkspaceUsers()
    }, []);
    
    let workspaceLink = `${location.origin}/users/sign_up?w_t=${workspace.share_token}&i_t=${context.current_user.global_invite_token}`;
    let subscription = current_user.subscriptionFromWorkpace(workspace);

    return <>
            <p className={`pb-0 my-0 pb-2 border-bottom`}>{workspace.name} {current_user.isOwnerOfSubscription(subscription) && <NavLink to={`/workspaces/${workspace.objectId}`}><i className="ml-3 fa fa-cog float-right hover-float text-dark rounded-circle clickable" aria-hidden="true"></i></NavLink>} {!current_user.isOwnerOfSubscription(subscription) && <WorkspaceUserLeaveButton key={`workspace_user_${workspaceUser.id}_role_${workspaceUser.role_id}`} workspace_user={workspaceUser} workspace={workspace} afterRemove={() => window.location.reload() } />} <span className="badge badge-dark text-capitalize float-right">{workspaceUser.role.name.replace('workspace','').replace('_',' ')}</span>  </p>
            {subscription.cancel_at_period_end && <div className="mt-3 alert alert-danger" role="alert">
                  Workspace plan to be canceled on <strong><Moment format="M/D/YYYY" unix>{subscription.current_period_end}</Moment></strong>  <i className="fa fa-question-circle" id="workspaceCancelQuestion" aria-hidden="true"></i>
                  <UncontrolledPopover trigger="hover click" placement="bottom" target="workspaceCancelQuestion">
                    <PopoverHeader>Workspace cancellation</PopoverHeader>
                    <PopoverBody>
                      {current_user.isOwnerOfSubscription(subscription) ? <span>You've elected to cancel the plan attached to this workspace. <br/><br/> On <strong><Moment format="M/D/YYYY" unix>{subscription.current_period_end}</Moment></strong> all users will be removed from the workspace and assigned free plans <br/><em>(unless they have upgraded to a paid plan before then)</em>.</span> : <span>The owner of this workspace has elected to cancel the plan attached to this workspace. <br/><br/> On <strong><Moment format="M/D/YYYY" unix>{subscription.current_period_end}</Moment></strong> you will be removed from the workspace and assigned free plan <br/><em>(unless you have upgraded to a paid plan before then)</em>.</span>}
                    </PopoverBody>
                  </UncontrolledPopover>
                </div> }
            <div className="card mt-3 rounded shadow-sm">
              <div className="card-header py-3">
                <em className="float-right">{workspaceUsers.length}/{workspace.max_seats}</em>
                Team members
              </div>
              {loading && <Loader containerClass="py-5 d-flex justify-content-center" />}

              <ul className="list-group list-group-flush">
                {workspaceUsers.map((w_u) =>  <li className={`list-group-item`} key={`workspace_${workspace.id}_workspace_user_${w_u.user.id}_for_user_${current_user.id}`} >{w_u.user.name || w_u.user.email} {w_u.user.name && <em className="text-muted"><small>({w_u.user.email})</small></em>} 
                  {(workspaceUser.canManageUsers() && workspaceUser.canManageUser(w_u)) ? <WorkspaceUserButton key={`workspace_user_${w_u.id}_role_${w_u.role_id}_pending_role_${w_u.pending_role_id}`} workspace_user={w_u} workspace={workspace} afterRemove={loadWorkspaceUsers} /> : <span className="badge badge-info text-capitalize float-right">{w_u.role.name.replace('workspace','').replace('_',' ')}</span>} {(w_u.pending_role_id && workspaceUser.canManageUsers() && workspaceUser.canManageUser(w_u)) && <span className="float-right mr-4" ><small><em>Pending Transfer</em></small></span>}
                  </li> )}
                {workspaceUsers.length === 1 && <li className={`list-group-item disabled`}>Open seat <em className="text-muted"><small>(Minimum 2 on plan)</small></em> <span className="text-light text-capitalize float-right">Open</span></li>}
              </ul>
            </div>

            {
            (workspaceUser.canInviteUsers() && !subscription.cancel_at_period_end) && <div className="card shadow-sm mt-3">
              <div className="card-body">
                  <label className="font-weight-bolder">Invite more members</label>
                  <p className="form-text text-dark mb-1">Enter your team emails below and we'll send them the link</p>
                  <EmailShareInput key={`workspace_${workspace.id}_sending_${sending}_sent_${sent}_emails_${emails.map((e) => e.email).join(',')}`} emails={emails} sending={sending} sent={sent} onSend={inviteUsers}/>
                  <small>Seperate emails by spaces</small>
                  <p className="font-weight-bolder my-3 text-center pb-3 border-bottom">OR</p>
                  <p className="form-text text-dark mb-1">Share link below to your team to add them to the workspace <span className="text-info">(recommended)</span></p>
                  <div className="input-group input-group-sm shadow-sm mb-3">
                    <input type="text" className="form-control" placeholder="" value={`https://getlimelite.com/users/sign_up?w_t=${workspace.share_token}&i_t=${current_user.global_invite_token}`} />
                    <div className="input-group-append">
                      <button className="btn btn-default" type="button" id="button-addon2" onClick={() => {
                          Mixpanel.track("Share Workspace Link Copied",{source:"Workspace New Page"})
                          copyToClipboard(workspaceLink)
                        }}>
                        {state.value == workspaceLink ? "Copied!":"Copy"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }

          </>;

}

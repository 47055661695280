import React, { useState, useContext, useEffect }  from "react"
import {AppContext} from '../AppContext.js';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Mixpanel} from '../helpers/Mixpanel'
import WorkspaceViewModel from '../models/WorkspaceViewModel'
import WorkspaceUserViewModel from '../models/WorkspaceUserViewModel'


export default function WorkspaceUserLeaveButton(props){

    const afterRemove = props.afterRemove;
    const context = useContext(AppContext);
    const current_user = context.current_user;
    const [workspaceUser,setWorkspaceUser] = useState({...props.workspace_user})
    const [workspace,setWorkspace] = useState({...props.workspace})
    const [confirming, setConfirming] = useState(false);
    const [removing, setRemoving] = useState(false);
    const toggleConfirming = () => setConfirming(!confirming)
    
    const removeUser = () => {
      Mixpanel.track("Leaving Workspace",{workspace:workspace})
      setRemoving(true)
      WorkspaceUserViewModel.destroy(workspaceUser.id,() => {
        setRemoving(false)
        setConfirming(false)
        afterRemove()
      })
    }


    return <>
            <i className="ml-3 fa fa-times float-right hover-float text-dark rounded-circle clickable" aria-hidden="true" onClick={toggleConfirming}></i>
            <Modal isOpen={confirming} toggle={toggleConfirming} size={"sm"}>
              <ModalHeader toggle={toggleConfirming}>Confirm Leave</ModalHeader>
              <ModalBody className="text-center">
                <p>Are you sure you want to leave workspace <br/><strong>{workspace.name}</strong>?</p>
                <button className="btn btn-danger my-3 rounded-pill" onClick={removeUser} disabled={removing}>{removing ? "Leaving..." : "Leave workspace"}</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggleConfirming}>Close</button>
              </ModalFooter>
            </Modal>
          </>;

}

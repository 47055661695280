import axios from 'axios';
import ViewModel from './ViewModel';
import {toQueryString} from '../helpers/Helpers'

export default class SubscriptionViewModel extends ViewModel {
  
  static url = "/limelite_subscriptions"

  static all = (params = {},callback) => {

    axios.get(`${this.url}.json?${toQueryString(params)}`).then((response) => {
      callback(response.data.map((p) => new SubscriptionViewModel(p) ))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static load = (id,callback) => {

    axios.get(`${this.url}/${id}.json`).then((response) => {
      callback(new SubscriptionViewModel(response.data))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static create = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`${this.url}.json`,{limelite_subscription:data}, options).then((response) => {
      callback(new SubscriptionViewModel(response.data))
    }).catch((error) => {
      callback(error.response.data.base.pop())
    })

  }

  static update = (id,data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`${this.url}/${id}.json`,{limelite_subscription:data}, options).then((response) => {
      callback(new SubscriptionViewModel(response.data))
    }).catch((error) => {
      callback(error.response.data.base.pop())
    })

  }

}
import React, {useState, useEffect, useContext} from "react";
import {AppContext} from "../AppContext"
import WorkspaceViewModel from "../models/WorkspaceViewModel";
import {Loader} from "../components/Loader"

const AllowedDomains = (props) => {

  const context = useContext(AppContext);
  const current_user = context.current_user;
  const [workspace,setWorkspace] = useState({...props.workspace})
  const [loading,setLoading] = useState(true)
  const [modal,setModal] = useState(props.open || false)
  const [error,setError] = useState(false)
  const [domain,setDomain] = useState('')
  const toggle = () => setModal(!modal);

  const loadWorkspace = () => {
    WorkspaceViewModel.load(workspace.objectId,(w,error) => {
      if (w) {
        setWorkspace({...w})
      }
    })
  }

  const addDomain = () => {
    WorkspaceViewModel.addDomain(workspace.objectId, domain,(d,error) => {
      if (d.id) {
        setDomain('')
        loadWorkspace()
      }
      if (error) setError(error)
    })
  }

  const removeDomain = (domain) => {
    WorkspaceViewModel.removeDomain(workspace.objectId, domain.id,(e,error) => {
      if (e.id) loadWorkspace()
      if (error) setError(error)
    })
  }

  return <div className="card mt-3">
          <div class="card-header py-2">
            Allowed domains
          </div>
          <div className="card-body">
            <small className="form-text text-muted mb-3">
              Add domains you'd like to allow to join your workspace
            </small>
            {workspace.allowed_domains.map((d) => <div className="input-group input-group-sm shadow-sm mb-2" key={`allowed_domain_${d.id}`}>
                                                  <input type="text" className="form-control" placeholder="mycompanydomain.com" value={d.domain}/>
                                                  <div className="input-group-append">
                                                    <button class="btn btn-danger shadow-sm" type="button" onClick={() => removeDomain(d)}>- Remove</button>
                                                  </div>
                                                </div> )}
            <div className="input-group input-group-sm shadow-sm mt-2" key={`new_domain`}>
              <input type="text" className="form-control" placeholder="mycompanydomain.com" value={domain} onChange={(e) => 
              setDomain(e.target.value)
              }/>
              <div className="input-group-append">
                <button class="btn btn-secondary shadow-sm" type="button" onClick={addDomain}>+ Add</button>
              </div>
            </div> 
          </div>
        </div>;
}

export default AllowedDomains;

import React, { useState, useEffect, useContext }  from "react"
import {AppContext} from '../AppContext'
import { useParams, NavLink, useHistory } from "react-router-dom"
import {Loader} from "../components/Loader"
import OnboardingNavbar from "../components/Navbars/OnboardingNavbar"
import OnboardingTabbar from "../components/Navbars/OnboardingTabbar"
import HomeNavbar from "../components/Navbars/HomeNavbar"
import LimeliteBackground from './LimeliteBackground';
import { getParam } from '../helpers/QueryParameters'
import { getMetaTag } from '../helpers/Helpers'
import PlanViewModel from "../models/PlanViewModel"
import SubscriptionViewModel from "../models/SubscriptionViewModel"
import Card from "./Card"
import CardForm from "./CardForm"
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import Emoji from "react-emoji-render"
import Moment from 'react-moment'
import {Mixpanel} from '../helpers/Mixpanel'
import 'moment-timezone'

Moment.globalLocal = true;

const Plan = () => {


  let history = useHistory();
  const context = useContext(AppContext);
  const current_user = context.current_user;

  const [loadingPlan, setLoadingPlan] = useState({loading:true,plan:false,error:false});
  const [sending, setSending] = useState(false);
  const [useNewCard, setUseNewCard] = useState(!current_user.hasCards());
  const [interval, setInterval] = useState(getParam("interval"));
  let { id } = useParams();
  
  const with_trial = getParam("with_trial") == "true";

  useEffect(() => {
    PlanViewModel.load(id,(plan,error) => {
      if (plan) {
        Mixpanel.track(with_trial ? "Plan Chosen - Free Trial" : "Plan Chosen - Buy Now",{plan:plan,interval:interval})
        Mixpanel.track("Plan Page Loaded")
        setLoadingPlan({plan:plan,loading:false,error:false})
      }
      else setLoadingPlan({plan:false,loading:false,error:error})
    })
  }, []);

  const { loading, plan, error } = loadingPlan;
  let price = false;

  if (plan) price = plan.prices.find((p) => p.interval == interval);
  
  let hasActiveSubscription = current_user.hasActivePlan();
  let activeSubscription = hasActiveSubscription ? current_user.activeSubscription() : false
  let isActivePlan = hasActiveSubscription && activeSubscription.plan.id == plan.id;
  let isActivePrice = hasActiveSubscription && activeSubscription.plan.id == plan.id && price.id == activeSubscription.price.id;


  const createSubscription = (result) => {
    
    Mixpanel.track("Creating Subscription",{plan:plan,with_trial:with_trial})

    setSending(true)

    if (isActivePlan){
      SubscriptionViewModel.update(activeSubscription.id,{type:"LimeliteSubscription::Stripe",with_trial:with_trial,payment_method_id:result.paymentMethod ? result.paymentMethod.id : null, price_id:price.id, plan_id:plan.id},(response) => {
        if (response instanceof SubscriptionViewModel) {
          Mixpanel.track("Subscription Updated!",{plan:plan,with_trial:with_trial})
          current_user.onboarding_complete ? location.href = `/home?updated_plan=true&plan_name=${plan.name}` : history.push("/tell-us-more");
        }
        else {
          Mixpanel.track("Error Updating Subscription",{plan:plan,with_trial:with_trial,error:response})
          setSending(false)
          setLoadingPlan({plan:plan,loading:false,error:response})
        }
      })
    }else{
      SubscriptionViewModel.create({type:"LimeliteSubscription::Stripe",with_trial:with_trial,payment_method_id:result.paymentMethod ? result.paymentMethod.id : null, price_id:price.id, plan_id:plan.id},(response) => {
        if (response instanceof SubscriptionViewModel) {
          Mixpanel.track("Subscription Created!",{plan:plan,with_trial:with_trial})
          if (plan.multiple_seats){
            location.href = `/workspaces/${response.workspace.objectId}`
          }else{
            current_user.onboarding_complete ? location.href = `/home?updated_plan=true&plan_name=${plan.name}` : history.push("/tell-us-more");
          }
        }
        else {
          setSending(false)
          Mixpanel.track("Error Creating Subscription",{plan:plan,with_trial:with_trial,error:response})
          setLoadingPlan({plan:plan,loading:false,error:response})
        }
      })
    }

  }

  return (
    <div className={`wrapper ${current_user.onboarding_complete && 'profile-page'}`}>
            {current_user.onboarding_complete ? <HomeNavbar /> : <OnboardingNavbar />}
              {current_user.onboarding_complete && <LimeliteBackground />}
            <div className="mt-2 mb-5">
              <div className="container">
                {!current_user.onboarding_complete && <OnboardingTabbar /> }
              <div className={!current_user.onboarding_complete ? "card border-0" : "card shadow mt--300 p-5"}>
                <div className="row">
                  {
                  !loading && <div className="col ml-auto mr-auto text-center">
                      {price.amount > 0 ? <span>
                        <h2 className="mb-2">Hungry for the <span className="font-weight-bolder">{plan.name}</span>, huh?</h2>
                        <p className="lead my-0">You've made a good choice - now give us that card number...please <Emoji text=":innocent:" /></p>
                        {context.current_user.hasMultipleSeatsPlan() && <p className="text-danger mb-0"><strong className="font-weight-bold">Are you sure?</strong> downgrading your current team plan will remove everyone from your workspace</p>}
                      </span>:<span>
                        <h2 className="mb-2">You sure about downgrading to the <span className="font-weight-bolder">{plan.name}</span>?</h2>
                        <p className="lead my-0">We'd love to keep you as a paid customer - anything we can do <a href="#contact">please let us know</a>... <Emoji text=":innocent:" /></p>
                        {context.current_user.hasMultipleSeatsPlan() && <p className="text-danger mb-0"><strong className="font-weight-bold">Are you sure?</strong> downgrading your current team plan will remove everyone from your workspace</p>}
                      </span>}
                    </div>
                  }
                </div>
                {
                  price.amount > 0 && <ul className="nav nav-pills flex-column flex-md-row mt-3 mb-0 justify-content-center" id="tabs-icons-text" role="tablist">
                    <li className="nav-item pr-0">
                      <a className={`nav-link ${interval == "month" ? 'bg-light' : 'border-light'} text-dark mb-sm-3 mb-md-0`} href="#" onClick={(e) => {
                        setInterval('month')
                        e.preventDefault();
                        return false
                      }}>Monthly</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${interval == "year" ? 'bg-light' : 'border-light'} text-dark mb-sm-3 mb-md-0`} href="#" onClick={(e) => {
                        setInterval('year')
                        e.preventDefault();
                        return false
                      }} >Yearly</a>
                    </li>
                  </ul>
                }
                <div className="row justify-content-center">
                  {loading && <Loader containerclassName={'mt-5'} />}
                  {
                  price.amount <= 0 && <div className="container pb-5 mt-4">
                      <div className="row">
                        <div className="col-lg-7 mx-auto">
                            <div className="bg-white rounded-lg shadow p-5 text-center mt-3 mb-4">
                              <h3>We'll miss you <Emoji text=":cry:" /></h3>
                              <p>Confirm you're ready to downgrade</p>
                              <button className="btn btn-lg btn-danger rounded-pill" disabled={sending} onClick={createSubscription}>I'm ready to downgrade {sending && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>
                            </div>
                            {
                              current_user.hasPaidPlan() && <p className="font-weight-bolder text-center"><small>All changes take effect at the end of current billing period <br/> <em>{current_user.paidSubscription().plan.name} features will be available to you until <strong className="text-info"><Moment format="M/D/YYYY" unix>{current_user.paidSubscription().current_period_end}</Moment></strong></em></small></p>
                            }
                          </div>
                      </div>
                    </div>
                  }
                  {
                  price.amount > 0 && <div className="container pb-5 mt-4">
                    <div className="row">
                      <div className="col-lg-7 mx-auto">
                        <div className="bg-white rounded-lg shadow p-5">
                          <ul role="tablist" className="nav nav-pills rounded-pill nav-fill mb-3 d-none">
                            <li className="nav-item">
                              <a data-toggle="pill" href="#nav-tab-card" className="nav-link active rounded-pill">
                                                  <i className="fa fa-credit-card mr-1"></i>
                                                  Credit Card
                                              </a>
                            </li>
                            <li className="nav-item">
                              <a data-toggle="pill" href="#nav-tab-paypal" className="nav-link rounded-pill">
                                                  <i className="fa fa-paypal mr-1"></i>
                                                  Paypal
                                              </a>
                            </li>
                            <li className="nav-item">
                              <a data-toggle="pill" href="#nav-tab-bank" className="nav-link rounded-pill">
                                                  <i className="fa fa-university mr-1"></i>
                                                  Bank Transfer
                                              </a>
                            </li>
                          </ul>
                          {
                            error && 
                              <div className="alert alert-danger text-center" role="alert">
                                <strong>Uh Oh!</strong> {error}
                              </div>
                          }
                          <label className="font-weight-bold">Details</label> <br/>
                          <div className="row font-weight-bold border-bottom mb-3">
                            <div className="col">
                              <p className="mb-0">{plan.name}</p>
                              { 
                                with_trial && 
                                <small className="mb-2"><em>First payment on <span className="text-info"><Moment format="MMM Do" add={{ days: 7 }}>{new Date()}</Moment></span></em></small>
                              }
                            </div>
                            <div className="col text-right">
                            <p>${price.amount}{price.amount > 0 && <span>/<small>{plan.multiple_seats && 'user/'}{price.shortInterval()}</small></span>}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">

                            </div>
                            <div className="col">

                            </div>
                          </div>
                          {
                            isActivePrice &&
                            <div className="tab-content">
                              <p className="lead text-center">You are already subscribed to this plan</p>
                            </div>
                          }
                          { ! isActivePrice &&
                            <div className="tab-content">
                              {
                                !useNewCard && <button className="btn btn-sm btn-link float-right rounded-pill border border-top" onClick={() => setUseNewCard(true)}>+ Add New Card</button>
                              }
                              <label className="font-weight-bold">Card Details</label> <br/>
                                {
                                  (!useNewCard && current_user.hasCards()) && <ul className="list-group mt-2">
                                  {context.current_user.cards.map((card) => <Card key={`${card.id}_card_checkout`} card={card} editable={false} />)}
                                  </ul>
                                }
                                {
                                  ( useNewCard && current_user.hasCards() ) &&
                                  <div className="text-center"><button className="btn btn-sm btn-link rounded-pill border border-bottom mt-3" onClick={() => setUseNewCard(false)}>Use Existing Card</button></div>
                                }
                                {
                                  useNewCard &&
                                  <Elements stripe={loadStripe(getMetaTag('stripe-key'))}>
                                    <CardForm key={`checkout_${loading}_${error}_${plan.id}_${price.id}`} afterCardCreation={createSubscription} helperText={plan.multiple_seats ? "Minimum 2 seats for team plans" : false} amount={plan.multiple_seats ? (price.amount * 2) : price.amount} sending={loading} />
                                  </Elements>
                                }
                                {
                                  !useNewCard &&
                                  <>
                                  <button type="button" onClick={createSubscription} disabled={sending} className="subscribe btn btn-default mt-4 btn-block rounded-pill shadow-sm">
                                      <span>
                                      {getParam("with_trial") == "true" ? "Start 7-day Trial": `Pay $${plan.multiple_seats ? price.amount * 2 : price.amount}` } {sending && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
                                      </span>
                                  </button>
                                  {plan.multiple_seats && <p className="text-center mb-0"><small> Minimum 2 seats for team plans </small></p>}
                                  </>
                                }
                                { current_user.onboarding_complete && <p className="text-center mt-3"><small><strong>Bill will be Pro-rated based on today's date <span className="text-info"><Moment format="M/D/YYYY">{new Date()}</Moment></span></strong></small></p> }
                              { !current_user.onboarding_complete && <NavLink to="/tell-us-more" className="btn btn-link btn-sm text-light btn-block mt-3 rounded-pill mx-auto"> Skip and use free plan </NavLink> }
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export {Plan}

// export default class Plan extends React.Component {

//   state = {
//     plan:false,
//     plans:[],
//     loading:true,
//     interval:getParam("interval"),
//     error:false
//   }

//   componentDidMount(){
//     this.loadPlan();
//   }

//   loadPlan = () => {

//     PlanViewModel.load(this.props.match.params.id,(plan,error) => {
//       if (plan) this.setState({plan:plan,loading:false})
//       else this.setState({error:error,loading:false})
//     })

//   }

//   updateInterval = (interval,e) => {
//     this.setState({interval:interval})
//     e.preventDefault();
//     e.stopPropagation();
//     return false
//   }

//   render() {
    
//     let {loading,plan,interval,error} = this.state;
//     let price = false;

//     if (plan) price = plan.prices.find((p) => p.interval == interval);

//     return <div className="wrapper">
//             <OnboardingNavbar />
//             <div className="mt-2 mb-5">
//               <div className="container">
//                 <ul className="nav nav-pills nav-pills-default nav-fill flex-column flex-md-row mb-3" id="tabs-icons-text" role="tablist">
//                   <li className="nav-item">
//                     <a className="nav-link mb-sm-3 mb-md-0 disabled shadow-none" id="plan-icon-pick" data-toggle="tab" href="#" role="tab" aria-selected="false"><strong>1.</strong> Pick a plan</a>
//                   </li>
//                   <li className="nav-item">
//                     <a className="nav-link mb-sm-3 mb-md-0 disabled shadow-none" id="plan-icon-tell-us" data-toggle="tab" href="#" role="tab" aria-selected="false"><strong>2.</strong> Tell us more</a>
//                   </li>
//                   <li className="nav-item">
//                     <a className="nav-link mb-sm-3 mt-md-3 mt-lg-0 disabled shadow-none" id="plan-icon-download" data-toggle="tab" href="#" role="tab" aria-selected="false"><strong>3.</strong> Download LimeLite</a>
//                   </li>
//                 </ul>
//                 <div className="progress d-none">
//                   <div className="progress-bar bg-default" role="progressbar" aria-valuenow="33.33" aria-valuemin="0" aria-valuemax="100" style={{"width": "33.33%"}}></div>
//                 </div>
//                 <div className="row">
//                   {
//                   !loading && <div className="col-md-6 ml-auto mr-auto text-center">
//                       <h2>Hungry for the <span className="font-weight-bolder">{plan.name}</span>, huh?</h2>
//                       <h4 className="description">You've made a good choice - now give us that card number</h4>
//                     </div>
//                   }
//                 </div>
//                 <ul className="nav nav-pills flex-column flex-md-row mt-3 mb-0 justify-content-center" id="tabs-icons-text" role="tablist">
//                   <li className="nav-item pr-0">
//                     <a className={`nav-link ${interval == "month" ? 'bg-light' : 'border-light'} text-dark mb-sm-3 mb-md-0`} href="#" onClick={(e) => this.updateInterval('month',e)}>Monthly</a>
//                   </li>
//                   <li className="nav-item">
//                     <a className={`nav-link ${interval == "year" ? 'bg-light' : 'border-light'} text-dark mb-sm-3 mb-md-0`} href="#" onClick={(e) => this.updateInterval('year',e)} >Yearly</a>
//                   </li>
//                 </ul>
//                 <div className="row justify-content-center">
//                   {loading && <Loader containerclassName={'mt-5'} />}
//                   <div className="container pb-5 mt-3">
//                     <div className="row">
//                       <div className="col-lg-7 mx-auto">
//                         <div className="bg-white rounded-lg shadow p-5">
//                           <ul role="tablist" className="nav nav-pills rounded-pill nav-fill mb-3 d-none">
//                             <li className="nav-item">
//                               <a data-toggle="pill" href="#nav-tab-card" className="nav-link active rounded-pill">
//                                                   <i className="fa fa-credit-card mr-1"></i>
//                                                   Credit Card
//                                               </a>
//                             </li>
//                             <li className="nav-item">
//                               <a data-toggle="pill" href="#nav-tab-paypal" className="nav-link rounded-pill">
//                                                   <i className="fa fa-paypal mr-1"></i>
//                                                   Paypal
//                                               </a>
//                             </li>
//                             <li className="nav-item">
//                               <a data-toggle="pill" href="#nav-tab-bank" className="nav-link rounded-pill">
//                                                   <i className="fa fa-university mr-1"></i>
//                                                   Bank Transfer
//                                               </a>
//                             </li>
//                           </ul>
//                           <label className="font-weight-bold">Details</label> <br/>
//                           <div className="row font-weight-bold border-bottom mb-3">
//                             <div className="col">
//                               <p>{plan.name}</p>
//                             </div>
//                             <div className="col text-right">
//                             <p>${price.amount}{price.amount > 0 && <span>/<small>{price.shortInterval()}</small></span>}</p>
//                             </div>
//                           </div>
//                           <div className="row">
//                             <div className="col">

//                             </div>
//                             <div className="col">

//                             </div>
//                           </div>
//                           <div className="tab-content">
//                             <Elements stripe={loadStripe(getMetaTag('stripe-key'))}>
//                               <CardElement />
//                               <button type="button" className="subscribe btn btn-primary mt-4 btn-block rounded-pill shadow-sm"> Confirm Trial </button>
//                             </Elements>
//                             <div id="nav-tab-card" className="tab-pane fade show active d-none">
//                               <p className="alert alert-success">Some text success or error</p>
//                               <form role="form">
//                                 <div className="form-group">
//                                   <label for="username">Full name (on the card)</label>
//                                   <input type="text" name="username" placeholder="Jason Doe" required className="form-control"/>
//                                 </div>
//                                 <div className="form-group">
//                                   <label for="cardNumber">Card number</label>
//                                   <div className="input-group">
//                                     <input type="text" name="cardNumber" placeholder="Your card number" className="form-control" required/>
//                                     <div className="input-group-append">
//                                       <span className="input-group-text text-muted">
//                                                                   <i className="fa fa-cc-visa mx-1"></i>
//                                                                   <i className="fa fa-cc-amex mx-1"></i>
//                                                                   <i className="fa fa-cc-mastercard mx-1"></i>
//                                                               </span>
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div className="row">
//                                   <div className="col-sm-8">
//                                     <div className="form-group">
//                                       <label><span className="hidden-xs">Expiration</span></label>
//                                       <div className="input-group">
//                                         <input type="number" placeholder="MM" name="" className="form-control" required/>
//                                         <input type="number" placeholder="YY" name="" className="form-control" required/>
//                                       </div>
//                                     </div>
//                                   </div>
//                                   <div className="col-sm-4">
//                                     <div className="form-group mb-4">
//                                       <label data-toggle="tooltip" title="Three-digits code on the back of your card">CVV
//                                                                   <i className="fa fa-question-circle ml-1"></i>
//                                                               </label>
//                                       <input type="text" required className="form-control"/>
//                                     </div>
//                                   </div>



//                                 </div>
//                                 <button type="button" className="subscribe btn btn-primary btn-block rounded-pill shadow-sm"> Confirm  </button>
//                               </form>
//                             </div>
//                             <div id="nav-tab-paypal" className="tab-pane fade">
//                               <p>Paypal is easiest way to pay online</p>
//                               <p>
//                                 <button type="button" className="btn btn-primary rounded-pill"><i className="fa fa-paypal mr-2"></i> Log into my Paypal</button>
//                               </p>
//                               <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                               </p>
//                             </div>
//                             <div id="nav-tab-bank" className="tab-pane fade">
//                               <h6>Bank account details</h6>
//                               <dl>
//                                 <dt>Bank</dt>
//                                 <dd> THE WORLD BANK</dd>
//                               </dl>
//                               <dl>
//                                 <dt>Account number</dt>
//                                 <dd>7775877975</dd>
//                               </dl>
//                               <dl>
//                                 <dt>IBAN</dt>
//                                 <dd>CZ7775877975656</dd>
//                               </dl>
//                               <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                               </p>
//                             </div>
//                           </div>

//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>;
//   }
// }

import React, { useState, useContext, useEffect }  from "react"
import {AppContext} from '../AppContext.js';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

// core components
import {useCopyToClipboard} from 'react-use'
import Card from "./Card"
import LimeliteBackground from './LimeliteBackground';
import HomeNavbar from "../components/Navbars/HomeNavbar.js";
import Moment from 'react-moment'
import 'moment-timezone'
import { NavLink } from "react-router-dom";
import {Mixpanel} from '../helpers/Mixpanel'
import Emoji from "react-emoji-render";
import UserViewModel from "../models/UserViewModel.js";
import InvitationsModal from "./InvitationsModal";

Moment.globalLocal = true;

export default function Share(props){

    const [state, copyToClipboard] = useCopyToClipboard();
    const context = useContext(AppContext);
    const current_user = context.current_user;
    let inviteLink = `${location.origin}/users/sign_up?i_t=${current_user.global_invite_token}`;

    useEffect(() => {
      Mixpanel.track("Share Page Loaded")
    },[]);

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [emails, setEmails] = useState([]);
    const [emailString, setEmailString] = useState("");
    const [showReferrals, setShowReferrals] = useState(false);
    const [message, setMessage] = useState("Hi, I want you to try Limelite. It's a great tool to wow prospects during and demo and if I get a lot of referrals then I get free donuts so.....");
    const toggle = () => setPopoverOpen(!popoverOpen);

    const removeEmail = (email) => {
      
      let emailIndex = emails.findIndex((e) => e == email )
      emails.splice(emailIndex,1)
      setEmails([...emails])

    }

    const updateEmails = (e) => {
      
      let value = e.target.value;
      setEmailString(value)
      if (value.split(" ").length < 2) return;

      emails.push(e.target.value.split(" ")[0])

      setEmails([...emails])
      setEmailString("")

    }

    const inviteUsers = () => {
      
      let temp_emails = emails;
      if (temp_emails.length === 0) temp_emails = [emailString];

      Mixpanel.track("Inviting users via Email",{emails:temp_emails})
  
      setSending(true)
  
      UserViewModel.invite({emails:temp_emails,message:message},() => {
        setSending(false)
        setSent(true)
      })
  
    }

    return <div className="wrapper profile-page">
            <HomeNavbar />
            <LimeliteBackground />
            <section className="section bg-secondary">
              <div className="container">
                <div className="card card-profile shadow mt--450">
                  <div className="px-4">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="px-2 pt-3">
                          <h1 className="display-3 d-none d-sm-block">Share Limelite <img className="mx-3" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/backhand-index-pointing-right.png" /> {current_user.hasPaidPlan() ? "Get Limelite for Free" :"Get Donuts"}</h1>
                          <h3 className="font-weight-bolder d-sm-none text-center">Share Limelite <br/><Emoji text=":point_down:" className="mr-3 display-1 d-inline-block" /><br/> {current_user.hasPaidPlan() ? "Get Limelite for Free" :"Get Donuts"}</h3>
                        </div>
                        <div className="p-2">
                          {current_user.hasPaidPlan() ? <div className="text-center text-sm-left">
                            <Emoji text=":money_mouth_face:" className="mr-sm-3 display-1 d-inline-block" />
                            <Emoji text=":money_mouth_face:" className="mr-3 display-1 d-none d-sm-inline-block" />
                            <Emoji text=":money_mouth_face:" className="mr-3 display-1 d-none d-sm-inline-block" />
                            <Emoji text=":money_mouth_face:" className="mr-3 display-1 d-none d-sm-inline-block" height="70px" />
                            <Emoji text=":money_mouth_face:" className="mr-3 display-1 d-none d-sm-inline-block" height="70px" />
                            <Emoji text=":money_mouth_face:" className="mr-3 display-1 d-none d-sm-inline-block" height="70px" /> 
                          </div>:
                          <>
                            <img className="mr-sm-3" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" />
                            <img className="mr-3 d-none d-sm-inline-block" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" />
                            <img className="mr-3 d-none d-sm-inline-block" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" />
                            <img className="mr-3 d-none d-sm-inline-block" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" />
                            <img className="mr-3 d-none d-sm-inline-block" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" />
                            <img className="mr-3 d-none d-sm-inline-block" height="70px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" />
                          </>}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-5">
                        <button className="btn btn-secondary btn-block rounded-pill" onClick={setShowReferrals}><Emoji text=":doughnut:" className="mr-3 d-sm-inline-block position-absolute" style={{top:"8px",left:"8px"}} />Your Referral Count <span className="ml-4 bg-white text-default rounded-pill px-3 py-1 position-absolute" style={{top:"7px",right:"5px"}}>{current_user.accepted_invitations_count}</span></button>
                        {current_user.hasPaidPlan() && <button className="btn btn-success btn-block rounded-pill mt-2" onClick={setShowReferrals}><Emoji text=":money_mouth_face:" className="mr-3 d-sm-inline-block  position-absolute" style={{top:"10px",left:"8px"}} /> Your Paid Referral Count <span className="ml-4 bg-white text-default rounded-pill px-3 py-1 position-absolute" style={{top:"7px",right:"5px"}}>{current_user.paid_accepted_invitations_count}</span></button> }
                        <InvitationsModal key={`invitations_modal_${showReferrals}`} open={showReferrals} onClose={() => setShowReferrals(false)} />    
                      </div>
                    </div>
                    {!current_user.hasPaidPlan() && <div className="row mt-4">
                      <div className="col-sm-7 mt-4">
                        <div className="card text-center shadow-sm">
                          <div className="card-body">
                              <p className="mb-0 font-weight-bold mb-2"><span className="text-info">Want to get limelite for free for referrals?</span></p>
                              <p className="mb-0 mt-0">Paid users get a <span className="font-weight-bold">free month (a $5 value)</span> for each referral of a paying customer.</p>
                              <NavLink to="/plans" className="btn btn-sm btn-primary rounded-pill my-4">+ Upgrade Plan</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>}
                    {current_user.hasPaidPlan() && 
                    <div className="row mt-4">
                      <div className="col-sm-7 mt-4">
                        <div className="card text-center shadow-sm">
                          <div className="card-body">
                            <p className="mb-0 font-weight-bold mb-2"><span className="text-dark">Ready to Limelite for free?</span></p>
                            <p className="mb-0 lead mt-0">Your <strong>{current_user.paid_accepted_invitations_count}</strong> paid referrals has earned you <br/><strong>${current_user.paid_accepted_invitations_count * 5}</strong> in Limelite credit</p>
                            <p className="text-info mb-0"><small>*Referral credited after invited user completes trial period</small></p>
                            <p className="text-dark mb-0"><small>*Credit only rewarded for referrals that add card info</small></p>
                            <p className="text-dark"><small>*Invoices will be $0.00 for each free month</small></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    <hr/>
                    <div className="row mt-4">
                      <div className="col-lg-7">
                        <h1 className="display-4">Share your link</h1>
                        <p className="form-text text-dark">The more shares the more chance to get donuts! Bam</p>
                        <div className="input-group input-group-sm shadow-sm mt-2">
                            <input type="text" className="form-control" placeholder="" value={`https://getlimelite.com/users/sign_up?i_t=${current_user.global_invite_token}`} onChange={() => console.log("no change")} />
                            <div className="input-group-append">
                              <button className="btn btn-default" type="button" id="button-addon2" onClick={() => {
                                  Mixpanel.track("Share Link Copied",{source:"Share Page"})
                                  copyToClipboard(inviteLink)
                                }}>
                                {state.value == inviteLink ? "Copied!":"Copy Link"}
                              </button>
                            </div>
                          </div>
                      </div>
                    </div>
                    <hr/>
                    <div className="row mt-4">
                      <div className="col-lg-7">
                        <h1 className="display-4">Share via email</h1>
                        <p className="form-text text-dark">Enter your friends emails below and we'll send them your referal link</p>
                        {emails.map((e) => <span className="badge badge-info badge-pill text-lowercase mr-1">{e} <i className="fa fa-times ml-1" onClick={() => removeEmail(e)} ></i></span> )}
                        <div className="input-group input-group-sm shadow-sm mt-2">
                          <input type="text" className="form-control" placeholder="to: (enter contacts email)" value={emailString} onChange={updateEmails} />
                          <div className="input-group-append">
                            <button className="btn btn-default" disabled={sending} type="button" id="button-addon2" onClick={inviteUsers}>
                              {sent ? "Sent!":"Send Emails"} {sending && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
                            </button>
                          </div>
                        </div>
                        <small>Seperate emails by spaces</small>
                        <div className="form-group mt-3">
                          <textarea className="form-control" placeholder="Message to contact" value={message} id="exampleFormControlTextarea1" onChange={(e) => setMessage(e.target.value) }rows="4"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>;
}

import axios from 'axios';
import ViewModel from './ViewModel';
import WorkspaceViewModel from './WorkspaceViewModel';

export default class UserViewModel extends ViewModel {
  
  static url = "users"

  static load = (id,callback) => {

    axios.get(`/api/v1/${this.url}/${id}.json`).then((response) => {
      callback(new UserViewModel(response.data))
    }).catch((error) => {
      console.log("error",error)
      callback(null,error.response.data.error)
    })
  }

  static setPlatform = () => {
    axios.get(`/me/platform`,{p:navigator.platform}).then((response) => {
      console.log('platform set',response)
    }).catch((error) => {
      console.log('error setting platform',error)
    })
  }

  static clearSession = (callback) => {
    axios.get(`/me/clear_session`).then((response) => {
      callback({success:true})
    }).catch((error) => {
      callback({error:error})
    })
  }

  static invitations = (callback) => {

    axios.get(`/api/v1/${this.url}.json`).then((response) => {
      callback(response.data.map((u) => new UserViewModel(u)))
    }).catch((error) => {
      console.log("error",error)
      callback(null,error.response.data.error)
    })
  }


  static inviter = (token,callback) => {

    axios.get(token ? `/api/v1/${this.url}/inviter.json?i_t=${token}` : `/api/v1/${this.url}/inviter.json`).then((response) => {
      let obj = {}
      if (response.data.inviter) obj['inviter'] = new UserViewModel(response.data.inviter)
      if (response.data.invited) obj['invited'] = new UserViewModel(response.data.invited)
      if (response.data.invited_for) obj['invited_for'] = new WorkspaceViewModel(response.data.invited_for)
      callback(obj)
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static invite = (data,callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`/invite`,data,options).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static confirmation = (callback) => {

    axios.get(`/me/resend_confirmation`).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(null,error.response.data.error)
    })

  }

  static create = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`/api/v1/${this.url}/sign_up`,{user:data}, options).then((response) => {
      callback(new UserViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  static login = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`/api/v1/${this.url}/sign_in`,{user:data}, options).then((response) => {
      callback(new UserViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  static resetPassword = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`/users/password.json`,{user:data}, options).then((response) => {
      callback(new UserViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  static resetPasswordEmail = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`/users/password.json`,{user:data}, options).then((response) => {
      callback(response.data)
    }).catch((error) => callback(error.response.data.error))

  }


  static update = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`/api/v1/${this.url}/update`,{user:data}, options).then((response) => {
      if (callback) callback(new UserViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  static updatePhoto = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`/api/v1/${this.url}/update`,data, options).then((response) => {
      if (callback) callback(new UserViewModel(response.data))
    }).catch((error) => callback(error.response.data.error))

  }

  static googleAuth = (callback) => {
    axios.get(`/users/auth/google_oauth2`).then((response) => {
      console.log("response",response)
      callback(response)
    }).catch((error) => {
      console.log("response",error)  
      callback(error.response.data.error)
    })
  }

  static linkedInAuth = (callback) => {
    axios.get(`/users/auth/linkedin`).then((response) => {
      console.log("response",response)
      callback(response)
    }).catch((error) => {
    console.log("response",error)  
    callback(error.response.data.error)
    })
  }


  logout(callback){
    axios.get(`/api/v1/users/logout`).then((response) => {
      callback({message:"Success"})
    }).catch((error) => callback(null,error.response.data.error))

  }

  plans(){
    return this.limelite_subscriptions.map((s) => s.plan )
  }

  activeSubscriptions() {
    return this.limelite_subscriptions.filter((s) => !s.cancel_at_period_end )
  }

  paidSubscriptions() {
    return this.limelite_subscriptions.filter((s) => s.price.amount > 0 )
  }
  
  hasActivePlan(){
    return this.activeSubscriptions().length > 0
  }

  activeSubscription() {
    return this.activeSubscriptions()[0];
  }

  isSubscriptionOwner() {
    if (this.hasPaidPlan()){
      return this.paidSubscription().user_id == this.id
    }else{
      return this.hasActivePlan() && this.activeSubscription().user_id == this.id
    }
  }

  activePlan() {
    return this.activeSubscriptions()[0].plan
  }

  hasMultipleSeatsPlan(){
    return this.hasActivePlan() && this.limelite_subscriptions.map((s) => s.plan.multiple_seats ).some((t) => t === true)
  }

  hasPaidPlan(){
    return this.paidSubscriptions().length > 0;
  }

  paidSubscription() {
    return this.paidSubscriptions()[0]
  }

  paidPlans(){
    return this.limelite_subscriptions.filter((s) => s.price.amount > 0 ).map((s) => s.plan )
  }

  paidPlanToBeCancelled() {
    return this.paidSubscription().cancel_at_period_end
  }

  subscriptionFromWorkpace(workspace){
    return this.limelite_subscriptions.find((s) => s.subscribable_id === workspace.id && s.subscribable_type === "Workspace")
  }

  isOwnerOfSubscription(subscription){
    return subscription.user_id === this.id
  }

  isOwnerOfWorkspace(workspace){
    let sub = this.subscriptionFromWorkpace(workspace)
    return this.isOwnerOfSubscription(sub)
  }

  hasCards(){
    return this.cards.length > 0
  }

  primaryCard() {
    return this.cards.find((c) => c.primary)
  }

  spotlights() {
    return this.stats.find((s) => s.name == "spotlights").value;
  }

  onboardingPath(){
    if (this.onboarding_complete) return '/home'
    if (this.limelite_subscriptions.length > 0) return '/tell-us-more'
    return '/plans'
  }

  hotkeySetting(){
    return this.settings.find((s) => s.name == "hot_key" ).value
  }

  attributes(){
    return {
      name:this.name,
      job:this.job,
      company:this.company,
      referrer:this.referrer
    }
  }

}
import React,{useState,useEffect,useRef,useContext} from "react";
import OnboardingNavbar from "../components/Navbars/OnboardingNavbar"
import {AppContext} from '../AppContext.js';
import {useWindowSize} from 'react-use'
import Emoji from "react-emoji-render"
import Confetti from 'react-confetti'
import { NavLink} from "react-router-dom"
import UserViewModel from '../models/UserViewModel'
import {Mixpanel} from '../helpers/Mixpanel'
import actionCable from 'actioncable'

export const Tutorial = () => {
  
  const context = useContext(AppContext)
  const current_user = context.current_user
  const videoTag = useRef();
  const { width, height } = useWindowSize()
  const [step, setStep] = useState("begin");
  const [showPinHelper, setShowPinHelper] = useState(true);
  const [showHotkeyHelper, setShowHotkeyHelper] = useState(true);
  const cable = actionCable.createConsumer(`wss://${location.hostname}${location.hostname === "localhost" ? `:${location.port}`:''}/cable?token=${current_user.token}`)
  const [channel, setChannel] = useState(null)

  useEffect(() => {
    Mixpanel.track("Tutorial Page Loaded")
  },[]);

  useEffect(() => {

    const channel = cable.subscriptions.create(
      { channel: 'UserChannel' },
      {
        received: (data) => {
          if (data.reload_user) context.loadUser();
        },
      }
    )

    setChannel(channel)

    return () => {
      channel.unsubscribe()
    }

  }, [])

  const setVolume = () => {
    videoTag.current.volume = 0.3
  }

  if (step === "begin") UserViewModel.update({has_seen_tutorial:true})

  Mixpanel.track(`Tutorial Step ${step}`)

  let hotKeyElements = current_user.hotkeySetting().split("+").map((k,index) => <>{index > 0 && " + "}<kbd className='bg-light py-2 text-dark ml-2 px-3 shadow border floating' ><small>{k}</small></kbd></>)

  return <div className="wrapper">
    <div className="position-absolute" style={{top:"5px",right:"5px",zIndex:1}}>
          {(showHotkeyHelper && step === "hotkey") && <div className="card shadow mb-2">
            <div className="card-body text-center pt-0">
              <h1 className="floating-lg"><Emoji text=":point_up:" /></h1>
              <p className="mb-1 mt-3">Not a fan of hotkey</p>
              <p className="mt-2 mb-3 font-weight-bolder"> {hotKeyElements} ? </p>
              <p className="font-weight-bold">Update it!!</p>
              <button onClick={() => {
                Mixpanel.track("Sweet thx (Hotkey reminder) clicked")
                setShowHotkeyHelper(false)
                }} className="btn btn-sm rounded-pill btn-default mt-3 btn-block">Sweet thx</button>
            </div>
          </div>}
          { showPinHelper && <div className="card shadow">
            <div className="card-body text-center pt-0">
              <h1 className="floating-lg"><Emoji text=":point_up:" /></h1>
              <p className="mb-1 mt-3">Not seeing the <img src={"https://getlimelite.s3-us-west-2.amazonaws.com/lime-neg128.png"} style={{width:"25px",marginTop:"-10px"}} /> ?</p>
              <p className="font-weight-bold">Make sure you <strong>pin it</strong>!</p>
              <p><img src={"https://getlimelite.s3-us-west-2.amazonaws.com/puzzle.svg"} style={{width:"25px"}} /> <Emoji className="h3 mx-3" text=":point_right:" />  <img src={"https://getlimelite.s3-us-west-2.amazonaws.com/push-pin.svg"} style={{height:"25px"}} /> </p>
              <button onClick={() => {
                Mixpanel.track("Got it (Pin reminder) clicked")
                setShowPinHelper(false)
                }} className="btn btn-sm rounded-pill btn-info mt-3 btn-block">Got it</button>
            </div>
          </div>}
        </div>
    
    {step == "donzo" && <Confetti width={width} height={height} recycle={false} numberOfPieces={1000} />}
    <OnboardingNavbar />
    <div className="mt-2 mb-5">
      <div className="container">
        <div className="row">
          {
            step === "begin" &&
            <div className="col-md-8 mx-auto text-center mt-5">
                <h3 className="font-weight-bolder mt-5 animate__animated animate__slow animate__fadeInUp">Download complete...</h3>
                <h1 className="mt-1 display-1 mb-2 animate__animated animate__slow animate__delay-1s animate__fadeInUp"><strong>Let us begin</strong></h1>
                <h1 className="mt-4 display-4 mb-2 animate__animated animate__slow animate__delay-2s animate__fadeInUp">It's quite simple really...are you ready?</h1>
                <button type="button" className="w-75 btn btn-primary btn-lg btn-block my-5 rounded-pill mx-auto animate__animated animate__delay-3s animate__fadeInUp" onClick={() => setStep("ready")}> I'm Ready <Emoji text=":upside_down_face:" /></button>
            </div>
          }
          {
            step === "ready" &&
            <div className="col-md-8 mx-auto text-center mt-5">
                <h3 className="font-weight-bolder mt-5 animate__animated animate__slow animate__fadeInUp">Your extension is installed...</h3>
                <h1 className="mt-1 display-1 mb-4 animate__animated animate__slow animate__delay-1s animate__fadeInUp limelite-tutorial step-0"><strong>Now use the Hotkey to</strong> </h1>
                <h1 className="mt-1 display-1 mb-4 limelite-tutorial d-none step-1" llanimateclass="animate__animated animate__slow animate__fadeInUp " ><strong>Great! Now again to</strong> </h1> 
                <h1 key="yet_another_one" className="d-none mt-1 display-1 mb-4 limelite-tutorial step-2" llanimateclass="animate__animated animate__slow animate__fadeInUp "><strong>Look at you!</strong></h1> 
                <h1 className={`text-dark display-3 pt-2 animate__animated animate__slow animate__delay-1s animate__fadeInUp`}>{hotKeyElements}</h1>
                <h1 className="mt-4 display-1 mb-2 animate__animated animate__slow animate__delay-1s animate__fadeInUp limelite-tutorial step-0"><strong>Turn your Spotlight ON</strong></h1>
                <h1 className="mt-4 display-1 mb-2 limelite-tutorial d-none step-1" llanimateclass="animate__animated animate__slow animate__fadeInUp "><strong>Turn your Spotlight OFF</strong></h1>
                <h1 key="other_one" className="d-none mt-4 display-1 mb-2 limelite-tutorial step-2" llanimateclass="animate__animated animate__slow animate__delay-1s animate__fadeInUp"> <strong>You're doing it!</strong> </h1>
                <h1 className="d-none limelite-tutorial step-2" llanimateclass="animate__animated animate__slow animate__delay-1s animate__fadeInUp" ><Emoji text=":muscle:" /><Emoji text=":tada:" /></h1>
                <button type="button" className="d-none w-75 btn btn-info btn-lg btn-block my-5 rounded-pill mx-auto limelite-tutorial step-2" llanimateclass=" animate__animated animate__slow animate__delay-1s animate__fadeInUp" onClick={() => setStep("hotkey")}> Next </button>
            </div>
          }
          {
            step === "hotkey" &&
            <div className="col-md-8 mx-auto text-center mt-5">
                <h3 className="font-weight-bolder mt-5 animate__animated animate__slow animate__fadeInUp">Not a fan of the hotkey</h3> 
                <h1 className={`text-dark display-3 pt-2 animate__animated animate__slow animate__fadeInUp`}>{hotKeyElements} ?!?!</h1>
                <h1 className="mt-4 display-1 mb-2 animate__animated animate__slow animate__delay-1s animate__fadeInUp"><strong>No worries</strong></h1>
                <h1 className="mt-4 display-4 mb-3 animate__animated animate__slow animate__delay-1s animate__fadeInUp">Open your extension and click the hotkey button</h1>
                <p className="text-dark animate__animated animate__slow animate__delay-1s animate__fadeInUp mb-3">Make sure to update the shortcut for "Toggle Spotlight"</p>
                <button type="button" className="w-75 btn btn-info btn-lg btn-block my-5 rounded-pill mx-auto animate__animated animate__slow animate__delay-2s animate__fadeInUp" onClick={() => setStep("customize")}> Next </button>
            </div>
          }
          {
            step === "customize" &&
            <div className="col-md-8 mx-auto text-center">
                <h3 className="font-weight-bolder mt-5 animate__animated animate__slow animate__fadeInUp">You can also customize your spotlight...</h3>
                <h1 className="mt-1 display-3 mb-4 animate__animated animate__slow animate__delay-1s animate__fadeInUp"><strong>Just open Limelite!</strong> </h1>
                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="embed-responsive embed-responsive-16by9 animate__animated animate__slow animate__delay-1s animate__fadeInUp">
                      <video onLoadStart={setVolume} ref={videoTag} autoPlay controls>
                        <source src={"https://getlimelite.s3-us-west-2.amazonaws.com/ControlDDemoNewestest.mp4"} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p className="animate__animated animate__slow animate__delay-2s animate__fadeInUp mt-3"><small>Run the world by Dayglow <a href="https://open.spotify.com/track/0AsUVUloJ52ZCXmqrMFgOI?si=4ss8iCvCSUu4nPmm1jtDEg" target="_blank">Play on spotify</a></small></p>
                  </div>
                </div>
                <button type="button" className="w-75 btn btn-info btn-lg btn-block mt-3 mb-5 rounded-pill mx-auto animate__animated animate__slow animate__delay-2s animate__fadeInUp" onClick={() => setStep("donzo")}> Next </button>
            </div>
          }
          {
            step === "donzo" &&
            <div className="col-md-8 mx-auto text-center mt-5">
                <h3 className="font-weight-bolder mt-5 animate__animated animate__slow animate__fadeInUp">And that's all there is to it!</h3>
                <h1 className="mt-1 display-1 mb-0 animate__animated animate__slow animate__delay-1s animate__fadeInUp"><strong>For now....</strong></h1>
                <h1 className="display-1 mb-2 animate__animated animate__slow animate__delay-1s animate__fadeInUp d-flex justify-content-center"><Emoji text=":wink:" /></h1>
                <h1 className="mt-4 display-3 mb-2 animate__animated animate__slow animate__delay-2s animate__fadeInUp">Give us a shout if you want to talk</h1>
                <a className="mt-3 display-2 font-weight-bolder text-success mb-2 animate__animated animate__slow animate__delay-2s animate__fadeInDown" href="mailto:hello@getlimelite.com">hello@getlimelite.com</a>
                <NavLink to="/" className="w-75 btn btn-success btn-lg btn-block my-5 rounded-pill mx-auto animate__animated animate__delay-2s animate__fadeInUp" onClick={() => setStep("ready")}> Let's Go <Emoji text=":tada:" /></NavLink>
            </div>
          }
        </div>
      </div>
    </div>
  </div>;
};
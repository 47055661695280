import React, { Component } from 'react'
import {Loader} from "./components/Loader"
import UserViewModel from "./models/UserViewModel"
import {NotificationBanner} from "./components/NotificationBanner"
import {Mixpanel} from "./helpers/Mixpanel"

const AppContext = React.createContext();

class AppProvider extends Component { 
  
  state = {
  	current_user:false,
    loading:true,
    logout: () => {
      this.state.current_user.logout(() => this.setState({ current_user: false }));
    },
    set:(updates,callback) => {
      this.setState(updates,callback)
    },
    loadUser: () => {
      UserViewModel.load("me",(user,error) => {
        if (user) {
          
          Mixpanel.people.set({
            "$email" : user.email,
            "$name": user.name,
            "$avatar" : user.picture_url,
            "paying_customer" : user.hasPaidPlan()
          })
          
          Mixpanel.identify(user.email)
  
          this.setState({current_user:user,loading:false})
        }
        else this.setState({error:error,loading:false})
      })
    }
  }

  componentDidMount(){

    UserViewModel.load("me",(user,error) => {
      if (user) {
        
        Mixpanel.people.set({
          "$email" : user.email,
          "$name": user.name,
          "$avatar" : user.picture_url,
          "paying_customer" : user.hasPaidPlan()
        })
        
        Mixpanel.identify(user.email)

        this.setState({current_user:user,loading:false})
      }
      else this.setState({error:error,loading:false})
    })

  }

  render() {

    let {loading} = this.state;

    return <AppContext.Provider value={this.state} >
        <NotificationBanner />
        {
          loading && <Loader />
        }
        {!loading && this.props.children}
      </AppContext.Provider>;
  }

}

export {
  AppProvider,
  AppContext
}
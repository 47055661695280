import React,{useState,useContext} from "react";
import {AppContext} from "../AppContext";
import { getParam } from '../helpers/QueryParameters';
import { Mixpanel } from '../helpers/Mixpanel'
import UserViewModel from '../models/UserViewModel'
import Emoji from "react-emoji-render";

const NotificationBanner = (props) => {
  
  let context = useContext(AppContext);
  let current_user = context.current_user;
  
  const [confirmedSent,setConfirmedSent] = useState(false);
  const [confirmed,setConfirmed] = useState((getParam("confirmed") && getParam("confirmed") == "true"))
  const [showMobileBanner,setShowMobileBanner] = useState(true);
  const [updatedPlan, setUpdatedPlan] = useState((getParam("updated_plan") && getParam("updated_plan") == "true"))
  const planName = getParam("plan_name")
  const [cardAdded,setCardAdded] = useState(getParam("card_added") && getParam("card_added") == "true")
  const [cardUpdated,setCardUpdated] = useState(getParam("card_updated") && getParam("card_updated") == "true")
  const [workspaceAdded,setWorkspaceAdded] = useState(getParam("workspace_added"))
  const [workspaceOwned,setWorkspaceOwned] = useState(getParam("updated_ownership"))
  
  //if (confirmed || updatedPlan || cardAdded) window.history.push({ pathname: location.pathname })

  const resendConfirmation = (e) => {

    Mixpanel.track("Resend Confirmation Email",{email:current_user.email})

    UserViewModel.confirmation((response) => {
      setConfirmedSent(true)
    })

    e.preventDefault();
    return false;

  }

  return <>
        {
        showMobileBanner &&
        <div className="alert alert-warning alert-dismissible fade show mb-0 rounded-0 text-center d-sm-none" role="alert">
          <p className="alert-inner--text mb-0"><strong>Hey</strong>...This site is best viewed on a desktop/laptop because well...<br/>it's a product for desktops/laptops so...<br/>go find a computer yeh?</p>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() =>  setShowMobileBanner(false)}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        }
        {
          workspaceAdded &&
          <div className="alert alert-info rounded-0 text-center mb-0" role="alert">
            <strong className="mr-1">Added to workspace <em>{workspaceAdded}</em></strong> <Emoji text=":clap:" />  <Emoji text=":muscle:" />
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setWorkspaceAdded(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {
          workspaceOwned &&
          <div className="alert alert-success rounded-0 text-center mb-0" role="alert">
            <strong className="mr-1">You now own workspace <em>{workspaceOwned}</em></strong> <Emoji text=":clap:" />  <Emoji text=":muscle:" />
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setWorkspaceOwned(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {
          confirmed &&
          <div className="alert alert-info rounded-0 text-center mb-0" role="alert">
            <strong className="mr-1">Account confirmed!</strong> <Emoji text=":clap:" />  <Emoji text=":muscle:" />
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setConfirmed(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }{
          cardAdded &&
          <div className="alert alert-success rounded-0 text-center mb-0" role="alert">
            <strong className="mr-1">Card successfully added!</strong> <Emoji text=":clap:" />  <Emoji text=":muscle:" />
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setCardAdded(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {
          cardUpdated &&
          <div className="alert alert-success rounded-0 text-center mb-0" role="alert">
            <strong className="mr-1">Card successfully updated!</strong> <Emoji text=":clap:" />  <Emoji text=":muscle:" />
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setCardUpdated(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {
          (current_user && !current_user.confirmed_at) &&
          <div className="alert alert-warning text-center rounded-0 mb-0" role="alert">
            <strong>Hey!</strong> verify the email we sent to <strong>{current_user.email}</strong>. Unless you're a robot then 00101101010 <Emoji text=":robot:" />. Don't see it? <a className="alert-link" href="#" onClick={resendConfirmation}>{confirmedSent ? 'Sent!' : 'Resend Email'}</a>
          </div>
        }
        {
          (current_user && current_user.activeSubscription() && current_user.activeSubscription().status == "past_due") &&
          <div className="alert alert-danger text-center rounded-0 mb-0" role="alert">
            <strong>Uh oh!</strong> your subscription is currently <strong>Past Due.</strong> <em>{current_user.activeSubscription().status_message}</em> Fix this <strong>ASAP</strong> for continued service <Emoji text=":sweat_smile:" />
          </div>
        }
        {
          updatedPlan &&
          <div className="alert alert-info rounded-0 text-center mb-0" role="alert">
            <strong className="mr-1">Successfully Updated to {planName}!</strong> <Emoji text=":clap:" />  <Emoji text=":muscle:" />
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setUpdatedPlan(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
  </>;
};

export {NotificationBanner};
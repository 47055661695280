import React from "react";
import { Link } from "react-router-dom";
import {AppContext} from '../../AppContext.js';
import ContactModal from "../ContactModal.js";
import {Mixpanel} from '../../helpers/Mixpanel';
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col
} from "reactstrap";


const FeedbackNavbar = () => {

    return <AppContext.Consumer>
    {
      (context) => 
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-light"
            expand="lg"
            id="navbar-main"
          >
            <ContactModal />
            <Container>
              <NavbarBrand className="mr-lg-5" to="/home" tag={Link}>
                <img
                  alt="Limelite | Bring your presentations to LIFE"
                  src={"https://getlimelite.s3-us-west-2.amazonaws.com/brand/logo-two-tone-neg.png"}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="12">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <a className="btn btn-sm btn-primary shadow ml-auto" target="_blank" onClick={() => Mixpanel.track("Reinstall Limelite clicked", {"position": "header"}) } href="https://chrome.google.com/webstore/detail/limelite-chrome-extension/idgikkomiedghejlmgieapbjmjpahgjh" > Reintstall Limelite </a>

              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>}
        </AppContext.Consumer>;
}

export default FeedbackNavbar;

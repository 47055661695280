import React from "react"
import {Loader} from "../components/Loader"
import {Mixpanel} from '../helpers/Mixpanel'
import { NavLink } from "react-router-dom"
import {AppContext} from '../AppContext'
import OnboardingNavbar from "../components/Navbars/OnboardingNavbar"
import OnboardingTabbar from "../components/Navbars/OnboardingTabbar"
import LimeliteBackground from './LimeliteBackground';
import HomeNavbar from "../components/Navbars/HomeNavbar"
import Emoji from "react-emoji-render"
import PlanViewModel from "../models/PlanViewModel"
import SubscriptionViewModel from "../models/SubscriptionViewModel"
import Moment from 'react-moment'
import 'moment-timezone'

Moment.globalLocal = true;

export default class Plans extends React.Component {

  state = {
    plans:[],
    interval:"year",
    loading:true,
    updating:false,
    error:false
  }

  componentDidMount(){
    Mixpanel.track("Plans Page Loaded")
    this.loadPlans();
  }

  loadPlans = () => {

    PlanViewModel.all({active:true},(plans,error) => {
      if (plans) this.setState({plans:plans,loading:false})
      else this.setState({error:error,loading:false})
    })
  }

  createNewSubscriptionFromPlanAndPrice = (plan_id,price_id) => {
    
    this.setState({updating:true})
    
    SubscriptionViewModel.create({plan_id:plan_id,price_id:price_id}, (response) => {
      if (response instanceof SubscriptionViewModel) this.props.history.push('/?updated_plan=true')
      else this.setState({error:response,updating:false})
    })

  }

  updateInterval = (interval,e) => {
    this.setState({interval:interval})
    e.preventDefault();
    e.stopPropagation();
    return false
  }

  render() {
    
    let {loading,plans,interval,updating,error} = this.state;

    return <AppContext.Consumer>
            {
                (context) => <div className={`wrapper ${context.current_user.onboarding_complete && 'profile-page'}`}>
              {context.current_user.onboarding_complete ? <HomeNavbar /> : <OnboardingNavbar />}
              {context.current_user.onboarding_complete && <LimeliteBackground />}
            <div className="mt-2 mb-5">
              <div className="container">
              {!context.current_user.onboarding_complete && <OnboardingTabbar />}
              <div className={!context.current_user.onboarding_complete ? "card border-0" : "card shadow mt--300 p-5"}>
                <div className="row">
                  <div className="col-md-8 ml-auto mr-auto text-center">
                    <h2 className="display-3 mb-2">{context.current_user.onboarding_complete ? "Ready to switch it up?" : "Pick the best plan for you"}</h2>
                    <p className="lead my-0">{context.current_user.onboarding_complete ? <span>We suggest putting your thang down flipping and reversing it <Emoji text=":stuck_out_tongue_winking_eye:" /></span> : "Take a free bite or go head and jump in, we won't judge ;)"}</p>
                  </div>
                </div>
                <ul className="nav nav-pills flex-column flex-md-row mt-3 mb-0 justify-content-center" id="tabs-icons-text" role="tablist">
                  <li className="nav-item pr-0">
                    <a className={`nav-link ${interval == "month" ? 'bg-light' : 'border-light'} text-dark mb-sm-3 mb-md-0`} href="#" onClick={(e) => this.updateInterval('month',e)}>Monthly</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${interval == "year" ? 'bg-light' : 'border-light'} text-dark mb-sm-3 mb-md-0`} href="#" onClick={(e) => this.updateInterval('year',e)} >Yearly</a>
                  </li>
                </ul>
                <div className="row justify-content-center">
                  {loading && <Loader containerClass={'mt-5'} />}
                  {
                    !loading && plans.map((plan) => {
                      
                    let price = plan.prices.find((p) => p.interval == interval);
                    let savings = false;

                    if (interval == "year"){
                      let monthly_price = plan.prices.find((p) => p.interval == "month")
                      if (monthly_price && monthly_price.amount > 0){
                        let monthly_year_price = monthly_price.amount * 12;
                        savings = Math.round(((monthly_year_price - price.amount)/monthly_year_price) * 100)
                      }
                    }
                    
                    let hasActiveSubscription = context.current_user.hasActivePlan();
                    let activeSubscription = hasActiveSubscription ? context.current_user.activeSubscription() : false
                    let isActivePlan = hasActiveSubscription && activeSubscription.plan.id == plan.id && (price.id == activeSubscription.price.id || activeSubscription.price.amount == 0);

                    let description_list_items_strings = plan.description.split(",")

                    let description_list_items = description_list_items_strings.map((s) => {
                      let description_emojis = s.match(/:.*?:/gs) || []
                      let description_string = s.replace(/:.*?:/gs,"")
                      return <li className="list-group-item"><span dangerouslySetInnerHTML={{__html:description_string}} /> {description_emojis.map((e) => <Emoji text={e} className="d-inline-block mr-1" /> )}</li>
                    })

                    if (!price) return <span/>;

                    return <div className="col-md-4 d-flex" key={`plan_${plan.id}_price_${price.id}_active_subscription_${activeSubscription ? activeSubscription.id : activeSubscription}`}>
                    <div className={`card card-pricing card-coin shadow-sm flex-fill ${isActivePlan&& "border-dark shadow-lg"}`}>
                      {(savings && !isActivePlan) && <span className="badge badge-pill badge-success position-absolute" style={{top:"12px",right:"5px"}}>{savings}% savings</span>}  
                      <div className="card-header">
                        <img src={plan.photo_url} className="img-center rounded-0" />
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <h6 className="text-uppercase">{plan.name}</h6>
                            {isActivePlan && <span className="badge badge-pill badge-info mb-2">Current Plan</span>} 
                            <h3 className="font-weight-bolder">${price.amount}{price.amount > 0 && <span>/<small>{plan.multiple_seats && 'user/'}{price.shortInterval()}</small></span>}</h3>
                            {plan.multiple_seats && <small className="text-muted">2 user minimum</small>}
                            <hr className="bg-info" />
                          </div>
                        </div>
                        <div className="row">
                          <ul className="list-group">
                            {description_list_items}
                          </ul>
                        </div>
                        
                      </div>
                      {
                        !hasActiveSubscription &&
                        <div className="card-footer text-center bg-transparent py-1">
                          {
                            (price.amount > 0 && plan.trial_period) && <NavLink to={`/plans/${plan.id}?interval=${interval}`} className={`btn btn-link text-default mb-1`} >Buy Now</NavLink>
                          }
                          {
                            plan.trial_period && 
                            <NavLink to={price.amount > 0 ? `/plans/${plan.id}?interval=${interval}${price.amount > 0 ? '&with_trial=true':''}`:'/tell-us-more?free_plan=true'} className={`btn btn-${price.amount > 0 ? 'default' : 'info'} rounded-pill shadow-sm mb-3`} >
                              {price.amount > 0 ? 'Start Free Trial' : "Let's do it"}
                            </NavLink>
                          }
                          {
                            !plan.trial_period && 
                            <NavLink to={price.amount > 0 ? `/plans/${plan.id}?interval=${interval}`:'/tell-us-more?free_plan=true'} className={`btn btn-${price.amount > 0 ? 'default' : 'info'} rounded-pill shadow-sm mb-3`} >
                              {price.amount > 0 ? 'Choose Plan' : "Let's do it"}
                            </NavLink>
                          }
                        </div>
                      }
                      {
                        hasActiveSubscription &&
                        <div className="card-footer text-center bg-transparent py-1">
                          {
                            !isActivePlan &&
                            [ <p className="text-muted" key="take_effect_message"><small>Would take effect <strong className="text-dark">{(activeSubscription.current_period_end && !activeSubscription.plan.multiple_seats) ? <Moment format="M/D/YYYY" unix>{activeSubscription.current_period_end}</Moment> : "Instantly" }</strong></small></p>,
                            <NavLink className={`btn btn-${((!context.current_user.hasMultipleSeatsPlan() && price.amount > 0) || plan.multiple_seats) ? 'default' : 'danger'} rounded-pill shadow-sm mb-3 px-4`} to={`/plans/${plan.id}?interval=${interval}`} disabled={updating} key="price_button">
                              {((!context.current_user.hasMultipleSeatsPlan() && price.amount > 0) || plan.multiple_seats) ? 'Upgrade' : "Downgrade"} {updating && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
                            </NavLink>]
                          }
                          {
                            (isActivePlan && price.amount > 0) && <p className="font-weight-bolder">Next bill on <strong className="text-info"><Moment format="M/D/YYYY" unix>{activeSubscription.current_period_end}</Moment></strong></p>
                          }
                          {
                            (price.amount == 0 && context.current_user.hasPaidPlan() && context.current_user.paidPlanToBeCancelled()) && <p className="font-weight-bolder"><small>{context.current_user.paidSubscription().plan.name} features available to you until <strong className="text-info"><Moment format="M/D/YYYY" unix>{context.current_user.paidSubscription().current_period_end}</Moment></strong></small></p>
                          }
                        </div>
                      }
                    </div>
                  </div>})
                  }
                  {
                    !loading &&
                    <div className="text-center text-muted mt-5">
                      <p>Not seeing the right plan for you?</p>
                      <p><a href="mailto:sales@getlimelite.com" className={`btn btn-success rounded-pill shadow-sm mb-3`} >
                        Contact Sales
                      </a></p>
                    </div>
                  }
                </div>
                <div className="text-center px-5 pt-2 pb-5"><small>Unfortunately we donut possess the skills to produce these magical icons. <br/> They were baked by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a>, <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> and <a href="https://www.flaticon.com/authors/photo3idea-studio" title="photo3idea_studio">photo3idea_studio</a> respectively from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></small></div>
              </div>
              </div>
            </div>
                </div>}</AppContext.Consumer>;
  }
}

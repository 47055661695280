import React, {useState, useEffect, useContext, useRef} from "react"
import { NavLink, useParams} from "react-router-dom"
import {AppContext} from '../AppContext.js'
import OnboardingNavbar from "../components/Navbars/OnboardingNavbar"
import OnboardingTabbar from "../components/Navbars/OnboardingTabbar"
import HomeNavbar from "../components/Navbars/HomeNavbar"
import LimeliteBackground from './LimeliteBackground';
import Emoji from "react-emoji-render"
import UserViewModel from '../models/UserViewModel'
import WorkspaceViewModel from '../models/WorkspaceViewModel'
import EmailShareInput from './EmailShareInput'
import AllowedDomains from './AllowedDomains'
import {Loader} from "../components/Loader"
import {Mixpanel} from '../helpers/Mixpanel'
import {useCopyToClipboard} from 'react-use'

export default function WorkspaceNew(props){

  const [workspace, setWorkspace] = useState({});
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const [sending, setSending] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const context = useContext(AppContext);
  const current_user = context.current_user;
  const [sent, setSent] = useState(false);
  const timeoutRef = useRef(null); 
  let { id } = useParams();

  useEffect(() => { 
    Mixpanel.track("Workspace New Page Loaded")
      WorkspaceViewModel.load(id,(w,error) => {
        if (w) {
          setWorkspace({...w})
        }
        setLoading(false)
      })
  }, []);

  const inviteUsers = (emails) => {
      
    let temp_emails = emails.filter((e) => !e.sent ).map((e) => e.email );

    Mixpanel.track("Inviting workspace users via Email",{emails:temp_emails,workspace_id:workspace.id})

    setSending(true)

    UserViewModel.invite({emails:temp_emails,workspace_id:workspace.id},(response) => {
      setEmails([...response])
      setSending(false)
      setSent(true)
    })

  }

  const saveWorkpace = (e) => {
    
    if (submitting) return;
    setSubmitting(true)

    Mixpanel.track("Saving Workspace",workspace)
    
    WorkspaceViewModel.update(workspace.objectId,workspace,(response) => {
      setSubmitting(false)
      if (response instanceof WorkspaceViewModel) setWorkspace({...response})
      else setError(response)
    })

  }


  useEffect(() => {    
    
    if (workspace.should_save){
      setError(false) // EFFECT TO RUN AFTER CHANGE IN VALUE
      
      if (timeoutRef.current !== null) {          // IF THERE'S A RUNNING TIMEOUT
        clearTimeout(timeoutRef.current);         // THEN, CANCEL IT
      }

      timeoutRef.current = setTimeout(()=> {
        timeoutRef.current = null;               
        saveWorkpace()
      },1000);       
    }

  },[workspace]);


  let workspaceLink = `${location.origin}/users/sign_up?w_t=${workspace.share_token}&i_t=${context.current_user.global_invite_token}`;
  
  return (<div className={`wrapper ${current_user.onboarding_complete && 'profile-page'}`}>
          {current_user.onboarding_complete ? <HomeNavbar /> : <OnboardingNavbar />}
          {current_user.onboarding_complete && <LimeliteBackground />}
          <div className="mt-2 mb-5">
            <div className="container">
              {!current_user.onboarding_complete && <OnboardingTabbar clickable={false} />}
              <div className={!context.current_user.onboarding_complete ? "card border-0" : "card shadow mt--300 p-5"}>
              <div className="row justify-content-center">
                {loading && <Loader containerClass="py-5 d-flex justify-content-center" />}
                {!loading && 
                <>
                  <div className="col-8 text-center">
                    {(current_user.onboarding_complete && !workspace.isNew()) ? <h2 className="font-weight-bolder display-4 mt-1 mb-5">Update your workspace settings</h2> : <h2 className="font-weight-bolder display-3 mt-4 mb-2">Beautiful, now let's get <br/>the team in on this yeh?</h2>}
                    {(!current_user.onboarding_complete || workspace.isNew()) && <p className="lead">Nameth your workspace below and start inviting <Emoji text=":muscle:" /></p>}
                  </div>

                  <div className={`${!current_user.onboarding_complete ? 'col-6' :'col-8'} text-center`}>
                    <form className="text-left">
                      <div className="form-group"> {submitting && <span className="spinner-grow spinner-grow-sm float-right" role="status" aria-hidden="true"></span>}
                        <label>Workspace Name</label>
                        <input type="text" className={`form-control ${(workspace.name && !current_user.onboarding_complete && !error) && 'is-valid'} ${error && 'is-invalid'}`} defaultValue={workspace.name} placeholder="Twerkspace" name="name" onChange={(e) => {
                          workspace['name'] = e.target.value;
                          workspace['should_save'] = true;
                          setWorkspace({...workspace})
                        }} />
                        {
                        !current_user.onboarding_complete && <div className="valid-feedback">
                          {workspace.name}...really? Jk well done, nice name
                        </div>
                        }
                        <div className="invalid-feedback">
                          {workspace.name} {error}
                        </div>
                      </div>
                      {
                      workspace.name && 
                      <>
                      <div className="form-group">
                        <label>Max Seats</label>
                        <input type="number" className={`form-control`} value={workspace.max_seats} placeholder="100" name="max_seats" onChange={(e) => {
                          workspace['max_seats'] = e.target.value;
                          workspace['should_save'] = true;
                          setWorkspace({...workspace})
                        }} />
                        <small className="form-text text-muted">
                          Set a max on your team size
                        </small>
                      </div>
                      <div className="form-group">
                        <label>Domains</label>
                        <div className="custom-control custom-switch ml-5">
                          <input type="checkbox" className="custom-control-input" checked={workspace.allow_all_domains} name="allow_all_domains" id="customSwitch1" onChange={(e) => {
                          workspace['allow_all_domains'] = e.target.checked;
                          workspace['should_save'] = true;
                          setWorkspace({...workspace})
                        }} />
                          <label className="custom-control-label" htmlFor="customSwitch1">Allow all domains</label>
                        </div>
                        <small className="form-text text-muted">
                          Customize emails allowed to join your workspace
                        </small>
                        {
                          !workspace.allow_all_domains && <AllowedDomains workspace={workspace} />
                        }
                      </div>
                      </>
                      }
                      {(workspace.name && (!current_user.onboarding_complete || workspace.isNew())) && 
                      <div className="card shadow-sm mt-3">
                        <div className="card-body">
                          <label className="font-weight-bolder">Invite your team</label>
                          <p className="form-text text-dark mb-1">Enter your team emails below and we'll send them the link</p>
                          <EmailShareInput key={`workspace_${workspace.id}_sending_${sending}_sent_${sent}_emails_${emails.length}`} emails={emails} sending={sending} sent={sent} onSend={inviteUsers}/>
                          <small>Seperate emails by spaces</small>
                          <p className="font-weight-bolder my-3 text-center pb-3 border-bottom">OR</p>
                          <p className="form-text text-dark mb-1">Share link below to your team to add them to the workspace <span className="text-info">(recommended)</span></p>
                          <div className="input-group input-group-sm shadow-sm my-3">
                            <input type="text" className="form-control" placeholder="" defaultValue={`https://getlimelite.com/users/sign_up?w_t=${workspace.share_token}&i_t=${current_user.global_invite_token}`} />
                            <div className="input-group-append">
                              <button className="btn btn-default" type="button" id="button-addon2" onClick={() => {
                                  Mixpanel.track("Share Workspace Link Copied",{source:"Workspace New Page"})
                                  copyToClipboard(workspaceLink)
                                }}>
                                {state.value == workspaceLink ? "Copied!":"Copy"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                        }
                        {
                          current_user.onboarding_complete ?  <>
                          <NavLink to="/home" className={`btn btn-default rounded-pill mx-auto btn-block mt-4 ${!workspace.name && "disabled"}`}>Done</NavLink>
                        </> :
                          <>
                            <NavLink to="/download-extension" className={`btn btn-default rounded-pill mx-auto btn-block mt-4 ${!workspace.name && "disabled"}`}>Next</NavLink>
                            {workspace.name && <NavLink to="/download-extension" className="btn btn-link btn-sm text-light btn-block mt-3 rounded-pill mx-auto"> I'll send invites later </NavLink>}
                          </>
                        }
                      </form>
                  </div>
                  </>}
              </div>
              </div>
            </div>
          </div>
        </div>);
};
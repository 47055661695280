import React from "react";
import { Link,  NavLink } from "react-router-dom";
import { Mixpanel } from '../../helpers/Mixpanel';
import ContactModal from "../ContactModal.js";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col
} from "reactstrap";


const LandingNavbar = () => {

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <ContactModal />
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="Limelite | Bring your presentations to LIFE"
                  src={"https://getlimelite.s3-us-west-2.amazonaws.com/brand/logo-neg.png"}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="12">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <ul className="navbar-nav align-items-lg-center">
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#clients" onClick={() => Mixpanel.track("Clients clicked", {"position": "header"}) } >
                      <span className="nav-link-inner--text">Clients</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#how-it-works" onClick={() => Mixpanel.track("How it works clicked", {"position": "header"}) } >
                      <span className="nav-link-inner--text">How it Works</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#what" onClick={() => Mixpanel.track("Reviews clicked", {"position": "header"}) }>
                      <span className="nav-link-inner--text">Say What?</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#faq" onClick={() => Mixpanel.track("What about clicked", {"position": "header"}) }>
                      <span className="nav-link-inner--text">What about..</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#contact" onClick={() => Mixpanel.track("Contact clicked", {"position": "header"}) } >
                      <span className="nav-link-inner--text">Contact</span>
                    </a>
                  </li>
                </ul>
                <form className="form-inline ml-auto">
                  <NavLink to={"/users/sign_up?e=Join Free"} className="btn btn-sm btn-default shadow" > Join Free </NavLink>
                  <NavLink to={ "/users/sign_in?e=Login"} className="btn btn-sm btn-neutral text-default" > Login </NavLink>
                </form>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
}

export default LandingNavbar;

import React, {useState} from "react"
import { UncontrolledTooltip } from 'reactstrap';

export default function EmailShareInput(props){

  const [emails, setEmails] = useState([...props.emails || []]);
  const [emailString, setEmailString] = useState("");

  let {sending,sent,onSend} = props;

  const removeEmail = (email) => {
      
    let emailIndex = emails.findIndex((e) => e == email )
    emails.splice(emailIndex,1)
    setEmails([...emails])

  }

  const updateEmails = (e) => {
    
    let value = e.target.value;
    setEmailString(value)
    if (value.split(" ").length < 2) return;

    emails.push({email:e.target.value.split(" ")[0],sent:false})

    setEmails([...emails])
    setEmailString("")

  }


  return <>
          {emails.map((e,index) => <span className={`badge badge-${e.sent ? 'success' : ''}${e.error ? 'danger' : ''}${(!e.sent && !e.error) ? 'info' : ''} badge-pill text-lowercase mr-1`} key={`email_share_${e.email}`}>{e.email} 
          {e.sent && <i className="fa fa-check ml-1" ></i> } 
          {e.error && <><i className="fa fa-exclamation-circle ml-1" id={`errorSendingEmail_${index}`} onClick={() => removeEmail(e)} ></i>
            <UncontrolledTooltip placement="top" target={`errorSendingEmail_${index}`}>
              {e.error}
            </UncontrolledTooltip></> } 
          {(!e.error && !e.sent) && <i className="fa fa-times ml-1" onClick={() => removeEmail(e)} ></i>}
          </span> )}
          <div className="input-group input-group-sm shadow-sm mt-2">
            <input type="text" className="form-control" placeholder="to: (enter contacts email)" value={emailString} onChange={updateEmails} />
            <div className="input-group-append">
              <button className="btn btn-default" disabled={sending} type="button" id="button-addon2" onClick={() => onSend(emails.length > 0 ? emails : [{email:emailString,sent:false}])}>
                {sent ? "Sent!":"Send Emails"} {sending && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
              </button>
            </div>
          </div>
          </>;
};
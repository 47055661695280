import React, {useContext} from "react";
import { Link, NavLink } from "react-router-dom";
import {AppContext} from '../../AppContext.js';
import { Mixpanel } from '../../helpers/Mixpanel';
import ContactModal from "../ContactModal.js";
import InvitedForModal from "../../views/InvitedForModal"
import PendingRoleModal from "../../views/PendingRoleModal"
import { getMetaTag } from '../../helpers/Helpers'
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col
} from "reactstrap";

const HomeNavbar = () => {

  const context = useContext(AppContext);
  const current_user = context.current_user;
  const env = getMetaTag("rails-env")
  
  return <header className="header-global">
          <InvitedForModal />
          <PendingRoleModal />
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <ContactModal contact={{email:context.current_user.email,name:context.current_user.name}} />
            <Container>
              <NavbarBrand className="mr-lg-5" to="/home" tag={Link}>
                <img
                  alt="Limelite | Bring your presentations to LIFE"
                  src={"https://getlimelite.s3-us-west-2.amazonaws.com/brand/logo-neg.png"}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="12">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item" >
                    <NavLink className="nav-link nav-link-icon" to="/home" activeClassName="" >
                      <span className="nav-link-inner--text" onClick={() => Mixpanel.track("Profile clicked", {"position": "header"}) }>Profile</span>
                    </NavLink>
                  </li>
                  { 
                  current_user.isSubscriptionOwner() && <li className="nav-item">
                    <NavLink className="nav-link nav-link-icon" to="/plans" activeClassName="" >
                      <span className="nav-link-inner--text"  onClick={() => Mixpanel.track("Plan clicked", {"position": "header"}) }>Plan</span>
                    </NavLink>
                  </li>
                  }
                  <li className="nav-item">
                    <NavLink className="nav-link nav-link-icon" to="/share" activeClassName=""  >
                      <span className="nav-link-inner--text" onClick={() => Mixpanel.track("Share clicked", {"position": "header"}) }>Share</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link nav-link-icon" to="/tutorial" activeClassName=""  >
                      <span className="nav-link-inner--text" onClick={() => Mixpanel.track("Tutorial clicked", {"position": "header"}) }>Tutorial</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" target="_blank" href="https://chrome.google.com/webstore/detail/limelite-chrome-extension/idgikkomiedghejlmgieapbjmjpahgjh" onClick={() => Mixpanel.track("Download clicked", {"position": "header"})}  >
                      <span className="nav-link-inner--text">Download</span>
                    </a>
                  </li>
                  
                  <li className="nav-item">
                    <a className="nav-link nav-link-icon" href="#contact">
                      <span className="nav-link-inner--text"  onClick={() => Mixpanel.track("Help clicked", {"position": "header"}) }>Help</span>
                    </a>
                  </li>
                </ul>

                <a className="btn btn-sm btn-neutral text-default ml-3" href="/users/sign_out" onClick={() => Mixpanel.track("Logout clicked", {"position": "header"}) } > Logout </a>
                {env === "review" && <a className="btn btn-sm btn-danger ml-2" href="/reset" > Reset Limelite </a>}
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>;
}

export default HomeNavbar;

import React, { useState}  from "react"

export default function LimeliteBackground(props){

    const [mouseAttributes, setMouseAttributes] = useState({xMousePos:0,yMousePos:0});
    const [hovering, setHovering] = useState(false);

    const updateMouseHouse = (event) => {
      var eventDoc, doc, body;

      event = event || window.event; 

      if (event.pageX == null && event.clientX != null) {
          eventDoc = (event.target && event.target.ownerDocument) || document;
          doc = eventDoc.documentElement;
          body = eventDoc.body;

          event.pageX = event.clientX +
            (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
            (doc && doc.clientLeft || body && body.clientLeft || 0);
          event.pageY = event.clientY +
            (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
            (doc && doc.clientTop  || body && body.clientTop  || 0 );
      }

      // Use event.pageX / event.pageY here
      setMouseAttributes({xMousePos:event.pageX,yMousePos:event.pageY})
    }

  return <section className="section section-lg section-shaped profile-page" onMouseMove={(e) => {
    if (!hovering) setHovering(true)
    updateMouseHouse(e)
  }}  onMouseEnter={() => setHovering(true)}  onMouseLeave={() => setHovering(false)} >
                <div className="shape shape-style-1 shape-success" >
                  <span className={`span-150 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-75 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-75 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                </div>
              </section>
}
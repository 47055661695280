import axios from 'axios';
import ViewModel from './ViewModel';
import WorkspaceUserViewModel from './WorkspaceUserViewModel';
import {toQueryString} from '../helpers/Helpers'

export default class WorkspaceViewModel extends ViewModel {
  
  static url = "/workspaces"

  static all = (params = {},callback) => {

    axios.get(`${this.url}.json?${toQueryString(params)}`).then((response) => {
      callback(response.data.map((p) => new WorkspaceViewModel(p) ))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static load = (id,callback) => {

    axios.get(`${this.url}/${id}.json`).then((response) => {
      callback(new WorkspaceViewModel(response.data))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static loadUsers = (id,callback) => {

    axios.get(`${this.url}/${id}/workspace_users.json`).then((response) => {
      callback(response.data.map((u) => new WorkspaceUserViewModel(u)))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static addUser = (id,user_id,callback) => {
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`${this.url}/${id}/workspace_users.json`,{workspace_user:{user_id:user_id}},options).then((response) => {
      callback(new WorkspaceUserViewModel(response.data))
    }).catch((error) => {
      console.log('error.response',error.response)
      callback(null,error.response.data.error)
    })
  }

  static loadRoles = (id,callback) => {

    axios.get(`${this.url}/${id}/roles.json`).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static loadDomains = (id,callback) => {

    axios.get(`${this.url}/${id}/allowed_domains.json`).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static addDomain = (id,domain,callback) => {

    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`${this.url}/${id}/allowed_domains.json`,{allowed_domain:{domain:domain}},options).then((response) => {
      callback(response.data)
    }).catch((error) => {
      console.log("error",error)
      callback(null,error.response.data.error)
    })
  }

  static removeDomain = (id,domain_id,callback) => {

    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.delete(`${this.url}/${id}/allowed_domains/${domain_id}.json`,options).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static create = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`${this.url}.json`,{workspace:data}, options).then((response) => {
      callback(new WorkspaceViewModel(response.data))
    }).catch((error) => {
      console.log(error.response.data)
      callback(`${Object.keys(error.response.data)[0]} ${error.response.data[Object.keys(error.response.data)[0]][0]}`)
    })

  }

  static update = (id, data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`${this.url}/${id}.json`,{workspace:data}, options).then((response) => {
      callback(new WorkspaceViewModel(response.data))
    }).catch((error) => {
      let keys = Object.keys(error.response.data)
      callback(error.response.data[keys[0]].pop())
    })

  }

  static destroy = (id, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.delete(`${this.url}/${id}.json`,options).then((response) => {
      callback(new WorkspaceViewModel(response.data))
    }).catch((error) => {
      callback(error.response.data.base.pop())
    })

  }

  isNew = () => {
    let now = new Date();
    let created_at = new Date(this.created_at);
    return  created_at.getTime() < (now.getTime() + 1200)
  }

}
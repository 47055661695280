export default class ViewModel {

  static csrfToken = (token) => token ? document.body.dataset.railsCsrfToken = token : document.body.dataset.railsCsrfToken;
  
  constructor(object) {
    Object.assign(this, object)
  }
}

// {
  
//   Object.assign(this, params)

//   this.object_name = this.object_name || '';
//   this.json = params;
//   this.today = () => new Date();
//   this.get = (key) => this.json[key];
//   this.set = function(key,value,callback) {
//     this[key] = value;
//     this.json[key] = value;
//     if (callback) callback(this);
//   };

//   this.load = (callback) => $.ajax({
//     url: this.url,
//     type: 'GET',
//     dataType: 'json',
//     cache: false,
//     success: function(data) {
//       Object.assign(this, data);
//       this.json = data;
//       this.loaded = true;
//       if (callback) callback(this);
//     }.bind(this),
//     error: function(xhr, status, err) {
//       this.error = err.toString();
//       if (callback) callback({error:this.error});
//     }.bind(this)
//   });

//   this.update = function(callback) {
    
//     var data = {};
//     data[this.object_name] = this.json;

//     $.ajax({
//       url: this.url,
//       type: 'PUT',
//       dataType: 'json',
//       data:data,
//       cache: false,
//       success: function(data) {
//         Object.assign(this, data);
//         this.json = data;
//         if (callback) callback(this);
//       }.bind(this),
//       error: function(xhr, status, err) {
//         this.error = err.toString();
//         if (callback) callback({error:this.error});
//       }.bind(this)
//     });

//   }

//   this.updateField = function(key,value,callback) {
    
//     var data = {};
//     data[this.object_name] = {};
//     data[this.object_name][key] = value;

//     $.ajax({
//       url: this.url,
//       type: 'PUT',
//       dataType: 'json',
//       data:data,
//       cache: false,
//       success: function(data) {
//         Object.assign(this, data);
//         this.json = data;
//         if (callback) callback(this);
//       }.bind(this),
//       error: function(xhr, status, err) {
//         this.error = err.toString();
//         if (callback) callback({error:this.error});
//       }.bind(this)
//     });

//   }

// };

// function UserModel(id) {
//   this.object_name = 'user';
//   this.base = SoviObject;
//   this.url = `/users/${id}`;
//   this.base(id);
// };

// UserModel.prototype = Object.create(SoviObject.prototype);
// UserModel.prototype.constructor = UserModel;
import React, { useState}  from "react"
import CardForm from "./CardForm"
import { getMetaTag } from '../helpers/Helpers'
import CardViewModel from '../models/CardViewModel'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

export default function Card(props){

  let {isNew,
    className,editable} = props;
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [destroyed, setDestroyed] = useState(false);
  const [created, setCreated] = useState(false);
  const [error, setError] = useState(false);
  let [card,setCard] = useState(props.card || {})
  const toggle = () => setModal(!modal);
  editable = editable === undefined ? true : editable

  const createCard = (result) => {

    if (card.id){
      CardViewModel.update(card.id,{payment_method_id:result.paymentMethod.id},(response) => {
        if (response instanceof CardViewModel) {
          setCreated(true)
          setTimeout(() => setModal(false),3500)
          setTimeout(() => location.href="/home?card_updated=true",4000)
        }
        else {
          setError(response)
          setLoading(false)
        }
      })
    }else{
      CardViewModel.create({payment_method_id:result.paymentMethod.id},(response) => {
        if (response instanceof CardViewModel) {
          setCreated(true)
          setTimeout(() => setModal(false),3500)
          setTimeout(() => location.href="/home?card_added=true",4000)
        }
        else {
          setError(response)
          setLoading(false)
        }
      })
    }
  }

  const saveCard = (e) => {
    
    e.preventDefault();
    setLoading(true)
    
    CardViewModel.update(card.id,card,(response) => {
      if (response instanceof CardViewModel) location.href="/home" ;
      else {
        setError(response)
        setLoading(false)
      }
    })

  }

  const destroyCard = (e) => {
    
    e.preventDefault();
    setLoading(true)
    
    CardViewModel.destroy(card.id,(response) => {
      if (response instanceof CardViewModel){
        setDestroyed(true)
        setTimeout(() => setConfirmModal(false),3000)
        setTimeout(() => setModal(false),3500)
        setTimeout(() => location.href="/home",4000)

      }
      else {
        setError(response)
        setConfirmModal(false)
        setLoading(false)
      }
    })

  }

  const updateCard = (e) => {
    let target = e.target;
    let {name,value} = target;
    card[name] = value;
    setCard(card);
  }

return [isNew ? <button className={className ? className : "btn btn-sm btn-link float-right"} onClick={toggle} key="new_card_button" >+ Add Card</button> : <li className="list-group-item" key={`card_${card.id}`}><strong className="text-capitalize">{card.brand}</strong> ending in {card.last4} {card.primary && <span className={`badge badge-dark ml-4 ${editable ? "" :"float-right"}`}>primary</span>} {editable && <button className="btn btn-sm btn-link float-right" onClick={toggle}>Update Card</button>}</li>,<Modal  key={isNew ? `new_card_modal`: `card_modal_${card.id}`} isOpen={modal} toggle={toggle}>
  <ModalHeader toggle={toggle} >{isNew ? "Add Card" : <span>Edit <strong className="text-capitalize">{card.brand}</strong> ending in <em>{card.last4}</em></span>}</ModalHeader>
  <ModalBody>
      <>
      {created ? <div className="text-center">
                    <h1 className="mb-4 mt-0"><i className="text-white ni ni-check-bold bg-success p-3 shadow-sm rounded-circle" /></h1>
                    <p> Card successfully added!</p>
                  </div> : <Elements stripe={loadStripe(getMetaTag('stripe-key'))}>
                            <CardForm key={`new_card_${error}`} afterCardCreation={createCard} error={error} createNewCard={true} updateCard={!isNew} />
                          </Elements> }
      </>
    {
      false &&
      <form onSubmit={saveCard}>
        {
          error && 
            <div className="alert alert-danger text-center" role="alert">
              <strong>Uh Oh!</strong> {error}
            </div>
        }
        <div className="form-group mb-2">
          <label>Number</label>
          <input type="text" className="form-control border-0 rounded-0" defaultValue={`*******${card.last4}`} disabled placeholder="" />
        </div>

        <div className="form-group mb-0">
            <label>Name on card</label>
          <input type="text" className="form-control border-0 rounded-0" defaultValue={card.name_on_card} name="name_on_card" onChange={updateCard} placeholder="Name on Card" />
        </div>
        <hr className="mt-1 mb-3" />
        <div className="row">
          <div className="form-group mb-0 col">
            <label>Month</label>
            <input type="text" className="form-control border-right border-top-0 border-bottom-0 border-left-0 rounded-0" defaultValue={card.exp_month} name="exp_month" onChange={updateCard} placeholder="MM" />
          </div>
          <div className="form-group mb-0 col">
            <label>Year</label>
            <input type="text" className="form-control border-right border-top-0 border-bottom-0 border-left-0 rounded-0" defaultValue={card.exp_year} name="exp_year" onChange={updateCard} placeholder="YY" />
          </div>
        </div>
        <p className="text-muted mt-3 mb-0 text-center"><small>All card numbers are encrypted at rest with AES-256 via <a href="https://stripe.com" target="_blank">Stripe</a></small></p>
        
        <button type="button" type="submit" disabled={loading} className="subscribe btn btn-default mt-4 btn-block rounded-pill shadow-sm">
          Save Card {loading && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
        </button>
        
        { 
          !isNew && <button type="button" disabled={loading} onClick={() => setConfirmModal(true)} className="subscribe btn btn-danger mt-2 btn-block rounded-pill shadow-sm">
            Remove Card {loading && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
          </button>
        }

      </form>
    }
    { 
          !isNew && <Modal size={"sm"} key={`card_modal_${card.id}_destroy_confirmation`} isOpen={confirmModal} toggle={() => setConfirmModal(false)} className={className}>
                      <ModalHeader toggle={() => setConfirmModal(false)} >Remove Card</ModalHeader>
                      <ModalBody>
                        {
                        destroyed ? <div className="text-center">
                          <h1 className="mb-4 mt-0"><i className="text-white ni ni-check-bold bg-success p-3 shadow-sm rounded-circle" /></h1>
                          <p> Card successfully removed!</p>
                        </div> : <div className="text-center">
                            <p className="mb-1">Are you sure you want to remove this card?</p>
                            <p><small><em className="text-dark">With an active subscription one valid card is required</em></small></p>
                            <button className="btn btn-danger rounded-pill mt-2" disabled={loading} onClick={destroyCard}>Confirm {loading && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>
                          </div>
                        }
                      </ModalBody>
                      <ModalFooter>
                        <button className="btn btn-secondary btn-sm rounded-pill" disabled={loading} onClick={() => setConfirmModal(false)}>Cancel</button>
                      </ModalFooter>
                      </Modal>
  }
  </ModalBody>
  <ModalFooter>
    <button className="btn btn-secondary btn-sm rounded-pill" disabled={loading} onClick={toggle}>Cancel</button>
  </ModalFooter>
</Modal>]

};
import React, { useState, useContext, useEffect, useRef }  from "react"
import {AppContext} from '../AppContext.js';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

// core components
import {useCopyToClipboard} from 'react-use'
import Card from "./Card"
import LimeliteBackground from './LimeliteBackground';
import HomeNavbar from "../components/Navbars/HomeNavbar.js";
import Workspaces from "./Workspaces";
import InvitationsModal from "./InvitationsModal"
import Moment from 'react-moment'
import 'moment-timezone'
import Emoji from "react-emoji-render"
import { NavLink } from "react-router-dom";
import {Mixpanel} from '../helpers/Mixpanel'
import UserViewModel from '../models/UserViewModel'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';

Moment.globalLocal = true;

export default function Home(props){

    const pictureInputEl = useRef(null);
    const [state, copyToClipboard] = useCopyToClipboard();
    const context = useContext(AppContext);
    const current_user = context.current_user;
    let inviteLink = `${location.origin}/users/sign_up?i_t=${current_user.global_invite_token}`;

    useEffect(() => {
      Mixpanel.track("Home Page Loaded")
    },[]);

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [showReferrals, setShowReferrals] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);

    const updateProfilePicture = (e) => {

      let file = e.target.files[0];
      let user = {}
  
      if (file){
  
        let reader = new FileReader()
  
        reader.onload = (oe) => {

          user['picture_url'] = oe.target.result;
  
          const formData = new FormData()
          formData.append('user[picture]', file)
          
          UserViewModel.updatePhoto(formData,(response) => {
              if (response instanceof UserViewModel) location.reload(); 
            }
          )
  
        };
  
        reader.readAsDataURL(file)
  
      }
  
    }

    return <div className="wrapper profile-page">
            <HomeNavbar />
            <LimeliteBackground />
            <section className="section bg-secondary">
              <div className="container">
                <div className="card card-profile shadow mt--450 mt-sm--300">
                  <div className="px-4">
                    <div className="row justify-content-center">
                      <div className="col-lg-2 order-lg-2">
                        <div className="card-profile-image d-flex justify-content-center">
                          <input className="d-none" type="file" ref={pictureInputEl} onChange={updateProfilePicture} />
                          <a href="#" onClick={(e) => {
                            e.preventDefault()
                            pictureInputEl.current.click()
                           }}>
                             <LazyLoadImage wrapperProps={{className:""}} effect="blur" src={current_user.picture_url} className="rounded-circle hover-opacity bg-white mx-auto" />
                          </a>
                          <div>
                            <p className="text-center mb-0"><i className="fa fa-camera text-muted display-1 mb-0" aria-hidden="true"></i></p>
                            <p className="text-center text-muted font-weight-bold mt-0">Edit</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 order-lg-3 text-lg-right align-self-lg-center">
                        <div className="card-profile-actions py-4 mt-lg-0 d-none d-sm-block">
                          <small className="form-text text-dark text-center">
                            {
                            current_user.hasPaidPlan() ? <><Emoji text=":money_mouth_face:" className="mr-1" height="15px" /><Emoji text=":money_mouth_face:" className="mr-1" height="15px" />
                            Share link below and get free Limelite
                            <Emoji text=":money_mouth_face:" className="ml-1" height="15px" /><Emoji text=":money_mouth_face:" className="ml-1" height="15px" /></>: <><img className="mr-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /><img className="mr-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /> 
                              Share link below for chance to win Donuts 
                              <img className="ml-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /> <img className="ml-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /></>
                            }
                          </small>
                          <div className="input-group input-group-sm shadow-sm mt-2">
                            <input type="text" className="form-control" placeholder="" value={`https://getlimelite.com/users/sign_up?i_t=${current_user.global_invite_token}`} onChange={() => console.log("no change")} />
                            <div className="input-group-append">
                              <button className="btn btn-default" type="button" id="button-addon2" onClick={() => {
                                  Mixpanel.track("Share Link Copied",{source:"Home Page"})
                                  copyToClipboard(inviteLink)
                                }}>
                                {state.value == inviteLink ? "Copied!":"Copy"}
                              </button>
                            </div>
                          </div>
                          {!current_user.hasPaidPlan() && <small className="form-text text-dark text-center mt-2">Love Limelite? Want to get <strong>it for free</strong>? <NavLink to="/share">Learn More</NavLink></small>}
                        </div>
                      </div>
                      <div className="col-lg-5 order-lg-1">
                        <div className="card-profile-stats d-none d-sm-flex justify-content-center ">
                          <div>
                            <span className="heading text-capitalize">First spotlight</span>
                            <span className="description"><Moment fromNow>{current_user.created_at}</Moment></span>
                          </div>
                          <div className="clickable hover-float hover-float-no-shadow" onClick={() => setShowReferrals(true)}>
                            <span className="heading">{current_user.accepted_invitations_count}</span>
                            <span className="description">Referrals</span>
                            <InvitationsModal key={`invitations_modal_${showReferrals}`} open={showReferrals} onClose={() => setShowReferrals(false)} />
                          </div>
                          <div>
                            <span className="heading">{current_user.spotlights()}</span>
                            <span className="description">Spotlights</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <h3>{current_user.name}</h3>
                      <div className="h6">{context.current_user.email}</div>
                      <div className="h6 font-weight-300">{context.current_user.company}</div>
                    </div>
                    <div className="text-center mt-5 pt-5 border-top d-sm-none">
                      <small className="form-text text-dark text-center">
                        {
                        current_user.hasPaidPlan() ? <><Emoji text=":money_mouth_face:" className="mr-1" height="15px" /><Emoji text=":money_mouth_face:" className="mr-1" height="15px" />
                        Share link below and get paid
                        <Emoji text=":money_mouth_face:" className="ml-1" height="15px" /><Emoji text=":money_mouth_face:" className="ml-1" height="15px" /></>: <><img className="mr-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /><img className="mr-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /> 
                          Share link below for chance to win Donuts 
                          <img className="ml-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /> <img className="ml-1" height="15px" src="https://getlimelite.s3-us-west-2.amazonaws.com/doughnut_1f369.png" /></>
                        }
                      </small>
                      <div className="input-group input-group-sm shadow-sm my-3">
                        <input type="text" className="form-control" placeholder="" value={`https://getlimelite.com/users/sign_up?i_t=${current_user.global_invite_token}`} onChange={() => console.log("no change")} />
                        <div className="input-group-append">
                          <button className="btn btn-default" type="button" id="button-addon2" onClick={() => {
                              Mixpanel.track("Share Link Copied",{source:"Home Page"})
                              copyToClipboard(inviteLink)
                            }}>
                            {state.value == inviteLink ? "Copied!":"Copy"}
                          </button>
                        </div>
                      </div>
                      {!current_user.hasPaidPlan() && <p className="form-text text-dark text-center mt-2">Want to get <strong>free Limelite for referrals</strong> $5 value? <NavLink to="/share">Learn More</NavLink></p>}
                    </div>
                    {
                    current_user.isSubscriptionOwner() &&
                    <div className="mt-5 py-5 border-top">
                      <div className="row justify-content-center">
                        {
                          current_user.hasMultipleSeatsPlan() && <Workspaces />
                        }
                        <div className="col-12 col-sm-10">
                          <label className="font-weight-bolder">Your Plan</label>
                          <NavLink to="/plans" className="btn btn-sm btn-link float-right d-none d-sm-block">+ Change Plan</NavLink><br/>
                          <div className="card mb-4" key={`active_subscription_${current_user.activeSubscription().id}`}>
                            <div className="card-body shadow-sm">
                            
                              <p className="border-bottom pb-2">
                                {current_user.activeSubscription().plan.name} {current_user.activeSubscription().price.amount > 0 && <span className="badge badge-info capitalize ml-2">{current_user.activeSubscription().price.interval}ly</span>}
                                {
                                  (current_user && current_user.activeSubscription() && current_user.activeSubscription().status == "past_due") &&
                                  <>
                                    <span className="badge badge-danger ml-2 clickable" id="pastDuePopover"><i className="fa fa-info-circle"></i> Past Due
                                    <Popover placement="top" trigger={"hover"} isOpen={popoverOpen} target="pastDuePopover" toggle={toggle}>
                                      <PopoverHeader>Past Due payment</PopoverHeader>
                                      <PopoverBody>
                                        <p>Your payment is past due.</p> 
                                        <p>The reason the bank gave was <em className="text-danger">{current_user.activeSubscription().status_message}</em></p>
                                        <p><a href="?message=I'm having trouble paying my bill#contact">Please contact us</a> if you're having trouble with anything</p>
                                        {current_user.activeSubscription().next_payment_attempt && <p>Payment will be retried <strong className="text-danger"><Moment fromNow unix>{current_user.activeSubscription().next_payment_attempt}</Moment></strong></p> }
                                    </PopoverBody>
                                    </Popover>
                                  </span>
                                  {current_user.activeSubscription().next_payment_attempt && <small className="float-right">Payment will be retried <strong className="text-danger"><Moment fromNow unix>{current_user.activeSubscription().next_payment_attempt}</Moment></strong></small> }
                                  </>
                                }
                              </p>
                              {current_user.activeSubscription().free_pass && <p className="mb-0"><span className="font-weight-bold">You have free access</span> until <strong className="text-info"><Moment format="M/D/YYYY" unix>{current_user.activeSubscription().current_period_end}</Moment></strong></p> }
                              {!current_user.activeSubscription().free_pass && <p className="mb-0"><span className="font-weight-bold">Next bill</span> - ${current_user.activeSubscription().discount_applied ? (current_user.activeSubscription().price.amount - current_user.activeSubscription().discount_applied) : current_user.activeSubscription().upcoming_invoice_subtotal/100} to be charged on <strong className="text-info">{current_user.activeSubscription().price.amount > 0 ? <Moment format="M/D/YYYY" unix>{current_user.activeSubscription().upcoming_invoice_next_payment_attempt}</Moment> : "never it's free"}</strong> {current_user.activeSubscription().price.amount == 0 && <><NavLink to="/plans" className="btn btn-sm btn-success rounded-pill shadow-sm d-none d-sm-block float-right text-capitalize">Upgrade to remove watermark</NavLink><NavLink to="/plans" className="btn btn-sm btn-success rounded-pill shadow-sm d-sm-none text-capitalize btn-block mt-3">Upgrade to remove watermark</NavLink></>}</p> }
                              {current_user.activeSubscription().discount_applied && <small>You earned a discount of <strong>${current_user.activeSubscription().discount_applied}</strong> on this bill - well done!</small>}
                              {current_user.activeSubscription().plan.multiple_seats && <em><small className="mt-4">Bill is prorated based on date users are added to workspace</small></em>}
                            </div>
                          </div>
                          <label className="font-weight-bolder">Your Cards</label>
                          <div className="card">
                            <div className="card-body shadow-sm">
                              {!current_user.hasCards() && <p className="mb-0 text-center" key="no_cards_descriptions" >You don't currently have any cards attached. <NavLink to="/plans" className="btn btn-link px-0">Upgrade plan today</NavLink></p>}
                              {
                                current_user.hasCards() && <ul className="list-group list-group-flush">
                                {current_user.cards.map((card) => <Card key={`${card.id}_card`} card={card} />)}
                                </ul>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    {
                    !current_user.isSubscriptionOwner() &&
                      <div className="mt-5 py-5 border-top">
                        <div className="row justify-content-center">
                          <Workspaces />
                          {current_user.hasCards() && <div className="col-12 col-sm-10">
                            <label className="font-weight-bolder">Your Cards</label>
                            <div className="card">
                              <div className="card-body shadow-sm">
                                {!current_user.hasCards() && <p className="mb-0 text-center" key="no_cards_descriptions" >You don't currently have any cards attached. <Card isNew={true} className={"btn btn-sm btn-link"} /></p>}
                                {
                                  current_user.hasCards() && <ul className="list-group list-group-flush">
                                  {current_user.cards.map((card) => <Card key={`${card.id}_card`} card={card} />)}
                                  </ul>
                                }
                              </div>
                            </div>
                          </div>}

                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </section>
        </div>;
}

import React, {useState, useEffect, useContext} from "react";
import {AppContext} from "../AppContext"
import WorkspaceViewModel from "../models/WorkspaceViewModel";
import WorkspaceUserViewModel from "../models/WorkspaceUserViewModel";
import UserViewModel from "../models/UserViewModel";
import Card from "./Card"
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PendingRoleModal = () => {

  const context = useContext(AppContext);
  const current_user = context.current_user;
  const [invitedToWorkspace,setInvitedToWorkspace] = useState({})
  const [pendingWorkspaceUser,setPendingWorkspaceUser] = useState({})
  const [modal,setModal] = useState(false)
  const [updatingInvitation,setUpdatingInvitation] = useState(false)
  const [error,setError] = useState(false)
  const toggle = () => setModal(!modal);
  
  const acceptInvitation = () => {
    
    setUpdatingInvitation(true)

    if (pendingWorkspaceUser.id){
      WorkspaceUserViewModel.update(pendingWorkspaceUser.id,{role_id:pendingWorkspaceUser.pending_role_id,updated_by_id:current_user.id},(w_u) => {
        setUpdatingInvitation(false)
        if (w_u && w_u instanceof WorkspaceUserViewModel) {
          setModal(false)
          window.location.href = `/home?updated_ownership=${invitedToWorkspace.name}`
        }else setError(w_u)
      })
    }
    
  }

  const denyInvitation = () => {
    setUpdatingInvitation(true)

    if (pendingWorkspaceUser.id){
      WorkspaceUserViewModel.update(pendingWorkspaceUser.id,{pending_role_id:null,updated_by_id:current_user.id},(w_u) => {
        setUpdatingInvitation(false)
        if (w_u && w_u instanceof WorkspaceUserViewModel) {
          setModal(false)
          window.location.href = `/home`
        }else setError(w_u)
      })
    }
  }

  useEffect(() => {
    WorkspaceUserViewModel.loadPendingRole((wus) => {

      if (wus.length > 0){
        setInvitedToWorkspace({...wus[0].workspace})
        setPendingWorkspaceUser({...wus[0]})
        setModal(true)
      }
    })
  },[])

  return <>
          {
            invitedToWorkspace.name && 
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Request to transfer ownership</ModalHeader>
              <ModalBody className="text-center">
                {
                  error && <div className="alert alert-danger" role="alert">
                      <strong>Uh oh!</strong> {error} {error.indexOf('declined') > -1 && <em>Update your card below</em>}
                    </div>
                }
                <p>You were invited to become the workspace owner of <br/><strong>{invitedToWorkspace.name}</strong>?</p>
                {!current_user.hasCards() && <div className="card shadow-sm text-left mb-3">
                    <div className="card-header p-2">
                      Add Card
                    </div>
                    <div className="card-body text-center">
                    <p className="text-dark">Card required before transfer of ownership.</p>
                    <Card isNew={true} className={"btn btn-sm btn-secondary rounded-pill"} />
                    </div>
                  </div>}
                
                {
                  current_user.hasCards() && <ul className="list-group mt-2 ">
                    {current_user.cards.map((card) => <Card key={`${card.id}_card`} card={card} />)}
                    </ul>
                }
                <p className="text-dark mt-2"><small><em>Upon transfer subscription cost will immediately be charged to card above</em></small></p>
                <button className="btn btn-success btn-sm my-3 mr-3 rounded-pill" onClick={acceptInvitation} disabled={updatingInvitation || !current_user.hasCards() || (error && error.indexOf("decline") > -1)}>Accept invite</button>
                <button className="btn btn-danger btn-sm my-3 rounded-pill" onClick={denyInvitation} disabled={updatingInvitation || !current_user.hasCards() || (error && error.indexOf("decline") > -1)}>No thanks</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggle}>Close</button>
              </ModalFooter>
            </Modal>
          }
        </>;
}

export default PendingRoleModal;

import axios from 'axios';
import ViewModel from './ViewModel';
import {toQueryString} from '../helpers/Helpers'

export default class WorkspaceUserViewModel extends ViewModel {
  
  static url = "/workspace_users"

  static all = (params = {},callback) => {

    axios.get(`${this.url}.json?${toQueryString(params)}`).then((response) => {
      callback(response.data.map((p) => new WorkspaceUserViewModel(p) ))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static load = (id,callback) => {

    axios.get(`${this.url}/${id}.json`).then((response) => {
      callback(new WorkspaceUserViewModel(response.data))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static loadPending = (callback) => {

    axios.get(`${this.url}/pending.json`).then((response) => {
      callback(response.data.map((wu) => new WorkspaceUserViewModel(wu)))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static loadPendingRole = (callback) => {

    axios.get(`${this.url}/pending_role.json`).then((response) => {
      callback(response.data.map((wu) => new WorkspaceUserViewModel(wu)))
    }).catch((error) => {
      callback(null,error.response.data.error)
    })
  }

  static create = (data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.post(`${this.url}.json`,{workspace_user:data}, options).then((response) => {
      callback(new WorkspaceUserViewModel(response.data))
    }).catch((error) => {
      console.log(error.response.data)
      callback(`${Object.keys(error.response.data)[0]} ${error.response.data[Object.keys(error.response.data)[0]][0]}`)
    })

  }

  static update = (id, data, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.put(`${this.url}/${id}.json`,{workspace_user:data}, options).then((response) => {
      callback(new WorkspaceUserViewModel(response.data))
    }).catch((error) => {
      callback(error.response.data.base.pop())
    })

  }

  static destroy = (id, callback) => {
    
    const options = {
      headers: {
        'X-CSRF-Token': ViewModel.csrfToken()
      }
    };

    axios.delete(`${this.url}/${id}.json`,options).then((response) => {
      callback(new WorkspaceUserViewModel(response.data))
    }).catch((error) => {
      callback(error.response.data.base.pop())
    })

  }

  isOwner = () => {
    return ["workspace_owner"].indexOf(this.role.name) > -1;
  }

  isAdmin = () => {
    return ["workspace_admin"].indexOf(this.role.name) > -1;
  }

  canManageUsers = () => {
    return ["workspace_owner", "workspace_admin", "workspace_manager"].indexOf(this.role.name) > -1;
  }

  canManageUser = (user) => {
    
    if (this.id === user.id) { return false }
    
    if (this.role.name === "workspace_owner") { return true }

    if (this.role.name === "workspace_admin") {
      return ["workspace_member", "workspace_manager"].indexOf(user.role.name) > -1;
    }
    
    if (this.role.name === "workspace_manager") {
      return ["workspace_member"].indexOf(user.role.name) > -1;
    }
    
    return false;

  }

  canInviteUsers = () => {
    return ["workspace_owner", "workspace_admin","workspace_manager"].indexOf(this.role.name) > -1;
  }

  isSelf = (user) => {
    this.id == user.id && this.role.name !== "workspace_owner"
  }
  
}
import React from "react";

import UserViewModel from '../models/UserViewModel'
import { getParam } from '../helpers/QueryParameters'
import Emoji from "react-emoji-render"
import {Mixpanel} from '../helpers/Mixpanel'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink } from "react-router-dom";

class Signup extends React.Component {

  state = {
    hovering:false,
    xMousePos:0,
    yMousePos:0,
    signing_up:false,
    logging_in:false,
    resetting_password:false,
    password_reset:false,
    saving_password:false,
    password_save:false,
    view:this.props.view || 'sign_up',
    from_extension:false,
    redirecting_to:false,
    inviter_token:false,
    inviter:false,
    invited:false,
    user:{
      email:"",
      name:"",
      password:"",
      reset_password_token:"",
      password_confirmation:"",
    },
    error:false
  };

  componentDidMount() {

    let urlParams = new URLSearchParams(window.location.search);
    let from_extension = getParam('from_extension') == "true"
    
    let f = getParam('f')
    let e = getParam('e')
    let error = getParam('error') || false
    let inviter_token = getParam('i_t') || getParam('invitation_token')
    let reset_password_token = getParam('reset_password_token')
    
    let {user} = this.state;

    if (reset_password_token) user.reset_password_token = reset_password_token;
    
    if (f) Mixpanel.track("Download Extension Clicked",{placement:f})
    if (e) Mixpanel.track(`${e} Clicked`)

    Mixpanel.track("Sign Up Page Loaded",{view:this.state.view,from_extension:from_extension})

    UserViewModel.setPlatform();

    this.setState({from_extension:from_extension,inviter_token:inviter_token,user:user,error:error},() => {
      if (inviter_token) this.loadInviter(inviter_token)
    });
  }

  loadInviter = (inviter_token) => {
    UserViewModel.inviter(inviter_token,(response) => {
      if (response.inviter && response.inviter instanceof UserViewModel) {
        Mixpanel.track("User Was Invited",{inviter:response.inviter.email,invited:response.invited ? response.invited.email : {},invited_for:response.invited_for ? response.invited_for.name : {}})
        let user = this.state.user;
        if (response.invited) user.email = response.invited.email;
        this.setState({inviter:response.inviter,invited:response.invited || false,invited_for:response.invited_for || false,user:user})
      }
    })
  }

  updateUser = (e) => {
    let target = e.target;
    let {name,value} = target;
    let {user} = this.state;
    user[name] = value;
    this.setState(user)
  }

  createUser = () => {
    Mixpanel.track("Creating User")
    let {user} = this.state;
    this.setState({signing_up:true,error:false})
    UserViewModel.create(user,(response) => {
      if (response instanceof UserViewModel) this.props.context.set({current_user:response},() => this.props.history.push(response.onboardingPath()))
      else this.setState({error:response,signing_up:false})
    })
  }

  loginUser = () => {
    Mixpanel.track("Logging In User")
    let {user} = this.state;
    this.setState({logging_in:true,error:false})
    UserViewModel.login(user,(response) => {
      if (response instanceof UserViewModel) this.props.context.set({current_user:response},() => this.props.history.push(response.onboardingPath()))
      else this.setState({error:response,logging_in:false})
    })
  }

  resetPassword = () => {
    Mixpanel.track("Resetting User Password")
    let {user} = this.state;
    this.setState({saving_password:true,error:false})
    UserViewModel.resetPassword(user,(response) => {
      if (response instanceof UserViewModel) this.setState({password_saved:true,saving_password:false});
      else this.setState({error:response,saving_password:false})
    })
  }

  resetPasswordEmail = () => {
    Mixpanel.track("Sending Reset Password Email")
    let {user} = this.state;
    this.setState({resetting_password:true,error:false})
    UserViewModel.resetPasswordEmail(user,(response) => {
      if (response) this.setState({password_reset:true,resetting_password:false});
      else this.setState({error:response,resetting_password:false})
    })
  }

  updateView = (e,view) => {
    this.setState({view:view},this.props.history.push(`/users/${view}`))
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  updateMouseHouse = (event) => {
    var eventDoc, doc, body;

    event = event || window.event; 

    if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX = event.clientX +
          (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
          (doc && doc.clientLeft || body && body.clientLeft || 0);
        event.pageY = event.clientY +
          (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
          (doc && doc.clientTop  || body && body.clientTop  || 0 );
    }

    // Use event.pageX / event.pageY here
    this.setState({xMousePos:event.pageX,yMousePos:event.pageY})
  }

  render() {

    let {
      hovering,
      yMousePos,
      xMousePos,
      user,
      error,
      view,
      signing_up,
      logging_in,
      from_extension,
      inviter,
      invited,
      invited_for,
      resetting_password,
      password_reset,
      saving_password,
      password_saved,
      redirecting_to
    } = this.state;

    let google_link = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=765322707119-41sn9dc9pe00kca6tfiskr68bahcm9vp.apps.googleusercontent.com&redirect_uri=${location.protocol.replace(':','')}%3A%2F%2F${location.hostname}${location.hostname === "localhost" ? `%3A${location.port}`:''}%2Fusers%2Fsign_in%2Fauth%2Fgoogle_oauth2%2Fcallback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
    let linkedin_link = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=776bgxwrlbhwbh&redirect_uri=${location.protocol.replace(':','')}%3A%2F%2F${location.hostname}${location.hostname === "localhost" ? `%3A${location.port}`:''}%2Fusers%2Fsign_in%2Fauth%2Flinkedin%2Fcallback&scope=r_liteprofile+r_emailaddress`
    console.log("invited",invited)

    return <>
      <div className="d-none text-center p-3 mt-3">
        <h1 className="display-2 p-3">Limelite is a spotlight chrome extension</h1>
        <p className="h3 text-muted">Go find a laptop and we'll show you more <Emoji text=":wink:" /></p>
        <a href="https://getlimelite.com" className="btn btn-link btn-lg">https://getlimelite.com</a>
      </div>
      <div className="mh-sm-100 d-sm-flex">
          <div className="w-100 w-sm-50 d-none d-sm-block">
            <span onMouseMove={this.updateMouseHouse}>
          
            <div className={`limelite-hole-local show_mouse ${!hovering && 'inactive'}`} style={hovering ? {position:"absolute",top:`${yMousePos - 50}px`,left:`${xMousePos - 50}px`,opacity:0.4} : {}} />
          
              <section className="section section-lg section-shaped register-page h-100 d-flex" onMouseMove={() => !hovering && this.setState({hovering:true})} onMouseEnter={() => {
                Mixpanel.track("Entered Left Side")
                this.setState({hovering:true})
                }}  onMouseLeave={() => {
                  Mixpanel.track("Exited Left Side")
                  this.setState({hovering:false})}
                } >
                <div className="shape shape-style-1 shape-success" >
                  <span className={`span-150 animate__animated  ${hovering ? 'animate__fadeOutDown' : 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-75 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-75 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-50 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                  <span className={`span-100 animate__animated ${hovering ? 'animate__fadeOutDown': 'animate__fadeInUp'}`}></span>
                </div>
                <div className="align-self-center w-75 mx-auto text-center">
                  {
                    view == "sign_up" && 
                    <span>
                      <h1 className="text-white text-center display-4 mb-5"><span className={hovering ? 'animate__animated animate__fadeInUp font-weight-bold' : 'd-none'}>What was that? <br/> Now you've got my attention <Emoji text=":wink:" className="d-inline-block" /></span><span className={hovering ? 'd-none' : 'font-weight-bold'}>Make your demos count. Guide attention with a simple spotlight <Emoji text=":eyes:" className="d-inline-block" /></span></h1>
                    </span>
                  }
                  {view == "password/new" && <h1 className="text-white text-center display-4 mb-5">Forgot your password? <br/> It happens no worries</h1>}
                  {view == "password/edit" && <h1 className="text-white text-center display-4 mb-5">Almost there. <br/> Let's set up a new password</h1>}
                  {view == "sign_in" && <h1 className="text-white text-center display-4 mb-5">Good to see you again! <br/> We've missed you :)</h1>}
                  <LazyLoadImage effect="blur" className="shadow-lg rounded-circle bg-white w-75 mb-5 border-default p-3" width={"100%"} alt={"https://getlimelite.s3-us-west-2.amazonaws.com/Report-pana-with-logo-and-lite100.png"} src={"https://getlimelite.s3-us-west-2.amazonaws.com/Report-pana-with-logo-and-lite.png"} />
                  {view == "sign_up" && <h1 className="text-white text-center display-4">Sign up for free today.</h1>}
                  <h1 className="text-white text-center display-4 d-sm-none">Login Below</h1>
                </div>
              </section>
            </span>
          </div>
          <div className="w-100 w-sm-50 d-flex flex-column">
            {
              from_extension &&
              <div className="alert alert-info rounded-0 text-center" role="alert">
                <p className="lead text-white my-0"><strong className="mr-1">Almost there!</strong> Login below to gain access to the Limelite extension.</p>
              </div>
            }
            {
              inviter &&
              <div className="alert alert-info rounded-0 text-center" role="alert">
                <p className="lead text-white my-0"><strong className="mr-1 font-weight-bold">You've been invited!</strong> <br/> {inviter.name || inviter.email} wants you to join {invited_for ? <span> their workspace <br/> <strong className="font-weight-bold">{invited_for.name}</strong> on Limelite <Emoji text=":hugging_face:" /></span> : <span>Limelite and <br/> we're happy to have you <Emoji text=":hugging_face:" /></span>}. <br/><small><NavLink to="/" className="btn btn-sm btn-link text-white"><u>Learn More about LimeLite</u></NavLink></small></p>
              </div>
            }
            {
              password_reset &&
              <div className="alert alert-info rounded-0 text-center" role="alert">
                <p className="lead text-white my-0"><strong className="mr-1 font-weight-bold">We've reset your password!</strong> <br/> {user.email} has been email with instructions.</p>
              </div>
            }
            <div className="d-flex flex-fill justify-content-center align-items-center">
                <div className="align-self-center w-100 w-sm-50 px-4 px-sm-0 py-5 py-sm-0 text-center">
                <h1 className={`display-4 ${["password/new","password/edit"].indexOf(view) == -1 ? 'mb-4':'mb-0'}`}>
                  {view == "sign_up" && "Get Started Free"}
                  {view == "sign_in" && "Hey! You're back"}
                  {view == "password/new" && "Let's get you back in"}
                  {(view == "password/edit" && !password_saved) && "Reset password below"}
                  {(view == "password/edit" && password_saved) && "Password reset!"}
                </h1>
                {
                      ["sign_in","sign_up"].indexOf(view) > -1 &&
                <>
                <a href={google_link} onClick={() => {
                  Mixpanel.track("Auth with Google Clicked")
                  this.setState({redirecting_to:"Google"})
                }} className="btn btn-block btn-neutral text-default btn-icon mb-3">
                  <span className="btn-inner--icon">
                    <img src={"https://getlimelite.s3-us-west-2.amazonaws.com/common/google.svg"} />
                  </span>
                  <span className="btn-inner--text">{view == "sign_up" ? "Sign up" : "Login"} with Google</span>
                </a>
                <a href={linkedin_link} onClick={() => {
                  Mixpanel.track("Auth with LinkedIN Clicked")
                  this.setState({redirecting_to:"LinkedIN"})
                }} className="btn btn-block btn-neutral text-default btn-icon">
                  <span className="btn-inner--icon">
                    <img src={"https://getlimelite.s3-us-west-2.amazonaws.com/common/linkedin.png"} />
                  </span>
                  <span className="btn-inner--text">{view == "sign_up" ? "Sign up" : "Login"} with LinkedIN</span>
                </a>
                {
                redirecting_to &&
                <div className="alert alert-info mt-3 py-2" role="alert">
                  <strong>Great!</strong> We are redirecting you to {redirecting_to} - this should only take a moment
                </div>
                }
                </>
                }
                <div className={`card-body px-xl-0 ${["password/new","password/edit"].indexOf(view) > -1 && 'py-1'}`}>
                  <div className={`text-center text-muted pb-3 ${["password/new","password/edit"].indexOf(view) > -1 ? "mb-4" : "mb-5"} border-bottom`}>
                    {["sign_in","sign_up"].indexOf(view) > -1 && <small>Or {view == "sign_up" ? "Sign up" : "Login"} with email</small>}
                    {view === "password/new" && <small>Enter your email below and we'll send you a reset link</small>}
                    {(view === "password/edit" && !password_saved) && <small>Enter new password below</small>}
                    {(view === "password/edit" && password_saved) && <small>Time to go login!</small>}
                  </div>                
                  {
                  error && <div className="alert alert-danger" role="alert">
                      <strong>Uh oh!</strong> {error} {error.indexOf('domain not allowed') > -1 && <a className="btn btn-neutral text-danger btn-sm mt-3 rounded-pill" href="?c_t=y">Sign up separately here</a>}
                    </div>
                  }
                  <form role="form">
                    
                    {
                    view == "sign_up" &&
                      <div className="form-group mb-3">
                        <div className="input-group input-group-lg input-group-alternative border">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="ni ni-single-02"></i></span>
                          </div>
                          <input className="form-control text-capitalize" disabled={redirecting_to !== false} placeholder="Steve Perry" type="text" name="name" onChange={this.updateUser} value={user.name} />
                        </div>
                      </div>
                    }
                    { view !== "password/edit" &&
                    <div className="form-group mb-3">
                      <div className="input-group input-group-lg input-group-alternative border">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                        </div>
                        <input className="form-control" disabled={redirecting_to !== false} placeholder="Email" type="email" name="email" onChange={this.updateUser} defaultValue={invited ? invited.email : ""} value={user.email} />
                      </div>
                    </div>
                    }
                    {
                      (["sign_in","sign_up"].indexOf(view) > -1 || (view === "password/edit" && !password_saved)) && <div className="form-group">
                      <div className="input-group input-group-lg input-group-alternative border">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                        </div>
                        <input className="form-control" disabled={redirecting_to !== false} placeholder="Password" type="password" name="password" onChange={this.updateUser} value={user.password} />
                      </div>
                    </div>
                    }
                    { (view === "password/edit" && !password_saved) &&  <div className="form-group">
                    <div className="input-group input-group-lg input-group-alternative border">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                    </div>
                    <input className="form-control" disabled={redirecting_to !== false} placeholder="Confirm Password" type="password" name="password_confirmation" onChange={this.updateUser} value={user.password_confirmation} />
                  </div>
                </div>
                    }
                    <div className="text-center my-5">
                      {view == "sign_up" && <button type="button" className="btn btn-default btn-lg my-3 btn-block" onClick={this.createUser} disabled={signing_up || redirecting_to !== false}>Sign Up Free {signing_up && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>}
                      {view == "sign_in" && <button type="button" className="btn btn-primary btn-lg my-3 btn-block" onClick={this.loginUser} disabled={logging_in || redirecting_to !== false}>Login {logging_in && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>}
                      {view == "password/new" && <button type="button" className="btn btn-primary btn-lg my-3 btn-block" onClick={this.resetPasswordEmail} disabled={resetting_password}>Send Reset Email {resetting_password && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>}
                      {(view == "password/edit" && !password_saved) && <button type="button" className="btn btn-primary btn-lg my-3 btn-block" onClick={this.resetPassword} disabled={saving_password}>Save Password {saving_password && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }</button>}
                      {(view == "password/edit" && password_saved) && <button type="button" className="btn btn-info btn-lg my-3 btn-block" onClick={(e) => this.updateView(e,"sign_in")} disabled={saving_password}>Go to login</button>}
                    </div>
                    {
                    view == "sign_up" && 
                      <>
                      <small className="form-text text-muted mb-0">By signing up you agree to our <a href="/terms" target="_blank" onClick={() => Mixpanel.track("Terms clicked - Sign Up Page") } >Terms and Conditions.</a></small>
                      <small className="form-text text-muted mb-0">Already have an account? <a href="#" onClick={(e) => {
                      Mixpanel.track("Login here Clicked")
                      this.updateView(e,"sign_in")
                      }} >Login here.</a></small>
                      </>
                    }
                    {
                    (view == "sign_in" || view == "password/edit") && 
                      <>
                      <small className="form-text text-muted mb-0">Don't have an account yet? <a href="#" onClick={(e) => {
                      Mixpanel.track("Sign up here Clicked")
                      this.updateView(e,"sign_up")
                      }}>Sign up here.</a></small><small className="form-text text-muted mb-0">Having trouble signing in? <a href="#" onClick={(e) => {
                        Mixpanel.track("Reset Password Clicked")
                        this.updateView(e,"password/new")
                        }} >Reset password here.</a></small>
                      </>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>;
  }
}

export default Signup;

import React, { useState}  from "react"
import { getParam } from '../helpers/QueryParameters'
import {Mixpanel} from '../helpers/Mixpanel'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'

export default function CardForm(props){

  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  let {createNewCard,updateCard,amount} = props;

  const [sending, setSending] = useState((!props.error && props.sending) || false);

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    setSending(true)
    await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
      },
    }).then((result) => {
      if (result.error) {
        setSending(false)
        Mixpanel.track("Error Creating Card",{error:result.error.message})
        setError(result.error.message)
      }
      else props.afterCardCreation(result)
    });
    
  };

  return <form onSubmit={handleSubmit}>
      {
        error && 
          <div className="alert alert-danger" role="alert">
            <strong>Uh Oh!</strong> {error}
          </div>
      }
      <div className="form-group mb-0">
        <input type="text" className="form-control border-0 rounded-0" autoFocus onChange={(e) => {setName(e.target.value)}} placeholder="Name on Card" />
      </div>
      <hr className="mt-1 mb-3" />
      <CardElement />
      <p className="text-muted mt-3 mb-0 text-center"><small>All card numbers are encrypted at rest with AES-256 via <a href="https://stripe.com" target="_blank">Stripe</a></small></p>
      <button type="button" type="submit" disabled={sending} className="subscribe btn btn-default mt-4 btn-block rounded-pill shadow-sm">
        {createNewCard && <span>{updateCard ? "Update Card" : "Create Card"}</span>}
        {
         !createNewCard && 
          <span>
          {getParam("with_trial") == "true" ? "Start 7-day Trial": `Pay $${amount}` } {sending && <span className="spinner-grow spinner-grow-sm position-absolute" role="status" aria-hidden="true"></span> }
          </span>
        }
      </button>
      {props.helperText && <p className="text-center mb-0"><small>{props.helperText}</small></p>}
    </form>;

};
import React from "react";
import classnames from "classnames";
import ViewModel from '../models/ViewModel';
import { getParam } from '../helpers/QueryParameters';
import {Mixpanel} from '../helpers/Mixpanel';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal
} from "reactstrap";

class ContactModal extends React.Component {

  state = {
    showModal:false,
    nameFocused:false,
    emailFocused:false,
    phoneFocused:false,
    sent:false,
    sending:false,
    contact:this.props.contact ? this.props.contact : {
      name:'',
      email:'',
      phone:'',
      message:getParam('message')
    },
    error:false
  };

  componentDidMount() {
    this.checkforContactForm();
    window.addEventListener("hashchange", this.checkforContactForm, false);
  }

  componentWillUnmount() {
      window.removeEventListener("hashchange", this.checkforContactForm, false);
  }

  checkforContactForm = () => {
    if (window.location.hash.indexOf("contact") > -1) this.toggleModal();
    let message = getParam('message');
    if (message) {
      console.log("message present",message)
      let {contact} = this.state;
      contact.message = message
      this.setState({contact:contact})
    }
  };

  toggleModal = () => {
    
    Mixpanel.track("Contact Form Toggled");

    this.setState({
      showModal:!this.state.showModal
    }, () => { if(!this.state.showModal) window.location.hash = '' } );
  };

  updateContact = (e) => {
    var {contact} = this.state;
    let target = e.target;
    contact[target.name] = target.value;
    this.setState({contact:contact,error:false});
  };

  submitContact = () => {
    
    let {contact} = this.state;
    
    if (!contact.name || contact.name.trim() === "") return this.setState({error:"Please enter your name"})
    if (!contact.email || contact.email.trim() === "") return this.setState({error:"Please enter your email"})
    if (!contact.message || contact.message.trim() === "") return this.setState({error:"Please enter a message"})

    fetch(`/contact`,
        {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': ViewModel.csrfToken() 
            },
            body: JSON.stringify(contact),
      }).then(function(response) {
        return response.json();
      }).then((data) => {
            
        this.setState({sending:true,error:false},
          () => setTimeout(() => this.setState({sending:false,sent:true},
            () => setTimeout(() => this.setState({showModal:false}, 
              () => { if(!this.state.showModal) window.location.hash = '' })
            ,3000))
          ,1000))

      }).catch(function(error) {
        console.log("error",error);
      });;
    
    Mixpanel.track("Submitting contact form");

  };


  render() {
    
    let {error,sending,sent,contact} = this.state;

    return <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModal}
              toggle={() => this.toggleModal()}
            >
            <div className="modal-body p-0">
               <Card className="bg-gradient-secondary shadow">
                {
                  error && <div className="alert alert-danger rounded-0">
                      {error}
                    </div>
                }
                <CardBody className="p-lg-5">

                {
                    sent ? <div className="text-center">
                        <h1 className="mb-4 mt-0"><i className="text-white ni ni-check-bold bg-success p-3 shadow-sm rounded-circle" /></h1>
                        <p> Thanks for reaching out - we'll get back to you ASAP!</p>
                      </div> : <span>
                  <h4 className="mb-4 text-center">Questions or concerns?</h4>
                    <a className="btn btn-block btn-info mb-4" href="#hs-chat-open"  onClick={() => {
                      Mixpanel.track("Open Chat clicked", {"position": "contact_form"});
                      this.toggleModal();
                      } }>
                      <span className="nav-link-inner--text" >Open Chat</span>
                    </a>

                  <h6 className="text-dark text-center font-weight-bolder border-bottom pb-3 mb-3">
                    OR
                  </h6>
                  <p className="mt-0 text-center">
                    Send us a message and we'll get back to you
                  </p>
                  <FormGroup
                    className={classnames("mt-3", {
                      focused: this.state.nameFocused
                    },(error && error === "Please enter your name") && 'has-danger')}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your name"
                        type="text"
                        name="name"
                        defaultValue={contact.name}
                        onChange={this.updateContact}
                        onFocus={e => this.setState({ nameFocused: true })}
                        onBlur={e => this.setState({ nameFocused: false })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: this.state.emailFocused
                    },(error && error === "Please enter your email") && 'has-danger')}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email address"
                        type="email"
                        name="email"
                        defaultValue={contact.email}
                        onChange={this.updateContact}
                        onFocus={e => this.setState({ emailFocused: true })}
                        onBlur={e => this.setState({ emailFocused: false })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: this.state.phoneFocused
                    },(error && error === "Please enter your phone number") && 'has-danger')}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-chat-round" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="555-555-5555"
                        type="text"
                        name="phone"
                        defaultValue={contact.phone}
                        onChange={this.updateContact}
                        onFocus={e => this.setState({ phoneFocused: true })}
                        onBlur={e => this.setState({ phoneFocused: false })}
                      />
                    </InputGroup>
                    <small className="form-text text-muted float-right mb-2">*Phone Number Optional</small>
                  </FormGroup>
                  <FormGroup className={classnames("mb-4",(error && error === "Please enter a message") && 'has-danger')} >
                    <Input
                      className="form-control-alternative"
                      cols="80"
                      name="message"
                      defaultValue={contact.message}
                      onChange={this.updateContact}
                      placeholder="Type a message..."
                      rows="4"
                      type="textarea"
                    />
                  </FormGroup>
                  <div>
                    <Button
                      block
                      className="btn-round"
                      color="default"
                      size="lg"
                      type="submit"
                      disabled={sending}
                      onClick={this.submitContact}
                    >
                      Send Message {sending && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
                    </Button>
                  </div></span>}
                </CardBody>
              </Card>
            </div>
                  </Modal>;
  }
}

export default ContactModal;

import React, { useState, useContext, useEffect, useRef }  from "react"
import {AppContext} from '../AppContext.js';
import Workspace from './Workspace'


export default function Workspaces(props){

    const context = useContext(AppContext);
    const current_user = context.current_user;

    return <div className="col-12 col-sm-10">
    <label className="font-weight-bolder">Your Workspaces</label>
    <div className="card mb-4" key={`active_subscription_${current_user.id}_workspaces`}>
      <div className="card-body shadow-sm">
        {
          current_user.workspace_users.filter((w_u) =>  current_user.subscriptionFromWorkpace(w_u.workspace) && current_user.subscriptionFromWorkpace(w_u.workspace).plan.multiple_seats ).map((w_u) => <Workspace key={`workspace_${w_u.workspace.id}_for_user_${w_u.user_id}`} workspace_user={w_u} workspace={w_u.workspace} />)
        }
      </div>
    </div>
  </div>;

}

import React, { useState, useContext, useEffect }  from "react"
import {AppContext} from '../AppContext.js';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Mixpanel} from '../helpers/Mixpanel'
import WorkspaceViewModel from '../models/WorkspaceViewModel'
import WorkspaceUserViewModel from '../models/WorkspaceUserViewModel'


export default function WorkspaceUserButton(props){

    const afterRemove = props.afterRemove;
    const context = useContext(AppContext);
    const current_user = context.current_user;
    const [workspaceUser,setWorkspaceUser] = useState({...new WorkspaceUserViewModel(props.workspace_user)})
    const [workspace,setWorkspace] = useState({...new WorkspaceViewModel(props.workspace)})
    const [error, setError] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [confirmingTransfer, setConfirmingTransfer] = useState(false);
    const [transferring, setTransferring] = useState(false);
    const [cancellingTransfer, setCancellingTransfer] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [roles, setRoles] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const toggleConfirming = () => setConfirming(!confirming)
    const toggleConfirmingTransfer = () => setConfirmingTransfer(!confirmingTransfer)
    const toggleCancellingTransfer = (e) => {
      setCancellingTransfer(!cancellingTransfer)
      e.stopPropation();
      e.preventDefault();
      return false;
    }

    const updateRole = (role) => {
      if (role.name == "workspace_owner"){
        setConfirmingTransfer(true)
      }else{
        WorkspaceUserViewModel.update(workspaceUser.id,{role_id:role.id,updated_by_id:current_user.id},(response) => {
          if (response instanceof WorkspaceUserViewModel) setWorkspaceUser({...response})
          else setError(response)
        })
      }
    }

    
    const removeUser = () => {
      setRemoving(true)
      WorkspaceUserViewModel.destroy(workspaceUser.id,() => {
        setRemoving(false)
        setConfirming(false)
        setModal(false)
        afterRemove()
      })
    }

    const transferToUser = () => {
      setTransferring(true)
      WorkspaceUserViewModel.update(workspaceUser.id,{pending_role_id:roles.find((r) => r.name === "workspace_owner").id,updated_by_id:current_user.id},() => {
        setTransferring(false)
        setConfirmingTransfer(false)
        setModal(false)
        afterRemove()
      })
    }

    const cancelTransferToUser = () => {
      setCancelling(true)
      WorkspaceUserViewModel.update(workspaceUser.id,{pending_role_id:null,updated_by_id:current_user.id},() => {
        setCancelling(false)
        setCancellingTransfer(false)
        setModal(false)
        afterRemove()
      })
    }

    useEffect(() => { 
      if (modal){
        Mixpanel.track("Loading Workspace Roles")
        WorkspaceViewModel.loadRoles(workspace.objectId,(roles,error) => {
          if (roles) {
            console.log("roles from loadRoles",roles)
            setRoles([...roles])
          }
        })
      }
    }, [modal]);

    return <>
            <button className="btn btn-secondary rounded-pill btn-sm shadow-sm float-right" onClick={toggle}>{workspaceUser.role.name.replace('workspace','').replace('_',' ')}</button>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Update user role</ModalHeader>
              <ModalBody className="text-center">
                <p className="text-center">Choose <strong>{workspaceUser.user.name}'s</strong> new role</p>
                <div className="list-group shadow-sm">
                  {roles.map((r) => <div onClick={() => {
                    if (!(workspaceUser.pending_role_id && workspaceUser.pending_role_id === r.id)) updateRole(r)
                  }} key={`role_${r.id}_user_role_${workspaceUser.role.id}`} className={`list-group-item text-left list-group-item-action ${(workspaceUser.pending_role_id && workspaceUser.pending_role_id === r.id) && 'border border-dark'} ${r.name === workspaceUser.role.name && 'border border-info'}`}>
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1 text-capitalize">{r.name.replace("workspace_","")}</h5>
                        {r.name === workspaceUser.role.name && <i className="fa fa-check-circle text-info" aria-hidden="true"></i> }
                        {(workspaceUser.pending_role_id && workspaceUser.pending_role_id === r.id) && <span><small>Pending Transfer</small> <i className="ml-2 fa fa-clock-o text-dark" aria-hidden="true"></i></span> }
                      </div>
                      <p className="mb-1">{r.description}</p>
                      {(workspaceUser.pending_role_id && workspaceUser.pending_role_id === r.id) && <>
                        <p className="text-dark mt-0"><em>User will need to add valid payment method to be eligible for transer</em></p>
                        <button key={`cancelling_transfer_${workspaceUser.id}_role`} className="btn btn-dark btn-sm rounded-pill mt-2" onClick={toggleCancellingTransfer}>Cancel Transfer</button>
                        </> }
                    </div>)
                  }
                </div>
                {(!workspaceUser.pending_role_id && current_user.isOwnerOfWorkspace(workspace)) && <><button key={`confirming_transfer_${workspaceUser.id}`} className="btn btn-info mb-0 mt-5 rounded-pill" onClick={toggleConfirmingTransfer}>Transfer Ownership</button> <br/></> }
                {(workspaceUser.pending_role_id && current_user.isOwnerOfWorkspace(workspace)) && <><button key={`cancelling_transfer_${workspaceUser.id}`}  className="btn btn-dark mb-0 mt-5 rounded-pill" onClick={toggleCancellingTransfer}>Cancel Transfer</button> <br/></> }
                <button className={`btn btn-danger mb-3 mt-${current_user.isOwnerOfWorkspace(workspace) ? "3":"5"} rounded-pill`} onClick={toggleConfirming}>Remove user</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggle}>Close</button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={confirming} toggle={toggleConfirming} size={"sm"}>
              <ModalHeader toggle={toggleConfirming}>Confirm Remove</ModalHeader>
              <ModalBody className="text-center">
                <p>Are you sure you want to remove <br/><strong>{workspaceUser.user.name}</strong>?</p>
                <button className="btn btn-danger my-3 rounded-pill" onClick={removeUser} disabled={removing}>{removing ? "Removing..." : "Remove user"}</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggleConfirming}>Close</button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={confirmingTransfer} toggle={toggleConfirming}>
              <ModalHeader toggle={toggleConfirmingTransfer}>Confirm Transfer</ModalHeader>
              <ModalBody className="text-center">
                <p className="lead mb-0">Want to transfer ownership to <span className="font-weight-bold">{workspaceUser.user.name}</span>?</p>
                <p className="text-dark font-weight-bold mb-0 mt-3"><small>They will need to add valid payment before transfer will complete</small></p>
                <p className="text-info font-weight-bold mb-0"><small><em>Your bill this month will be pro-rated on based off accepted transfer</em></small></p>
                <button className="btn btn-info mt-4 mb-3 rounded-pill" onClick={transferToUser} disabled={transferring}>{transferring ? "Transfer..." : "Confirm transfer"}</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggleConfirmingTransfer}>Close</button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={cancellingTransfer} toggle={toggleCancellingTransfer} size={"sm"}>
              <ModalHeader toggle={toggleCancellingTransfer}>Cancel Transfer</ModalHeader>
              <ModalBody className="text-center">
                <p className="mb-0">Want to cancel transferring ownership to <strong>{workspaceUser.user.name}</strong>?</p>
                <button className="btn btn-dark my-3 rounded-pill" onClick={cancelTransferToUser} disabled={cancelling}>{cancelling ? "Cancelling..." : "Cancel transfer"}</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggleCancellingTransfer}>Close</button>
              </ModalFooter>
            </Modal>
          </>;

}

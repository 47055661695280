import React, {useState, useEffect, useContext} from "react";
import {AppContext} from "../AppContext"
import UserViewModel from "../models/UserViewModel";
import {Loader} from "../components/Loader"
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'react-moment'
import 'moment-timezone'

Moment.globalLocal = true;

const InvitationsModal = (props) => {

  const context = useContext(AppContext);
  const current_user = context.current_user;
  const [invitations,setInvitations] = useState([])
  const [loading,setLoading] = useState(true)
  const [modal,setModal] = useState(props.open || false)
  const [error,setError] = useState(false)
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (modal){
      UserViewModel.invitations((response) => {
        setLoading(false)
        if (response.length) {
          setInvitations([...response])
        }
      })
    }
  },[modal])

  return <> 
          <Modal isOpen={modal} toggle={props.onClose || toggle}>
            <ModalHeader toggle={props.onClose || toggle}>Invitations</ModalHeader>
            <ModalBody className="text-center">
                {
                  loading && <Loader containerClass="py-5 d-flex justify-content-center" />
                }
                {invitations.length > 0 && <div className="list-group shadow-sm">
                  {invitations.map((i) => <div key={`invited_${i.id}_by_user_${current_user.id}`} className={`list-group-item text-left`}>
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{i.email}</h5>
                        <small>
                          {i.invitation_accepted_at ? <span><Moment fromNow>{i.invitation_accepted_at}</Moment> 
                            </span> : <span>
                              {i.invitation_sent_at ? <span><Moment fromNow>{i.invitation_accepted_at}</Moment></span> : ""}
                            </span>}
                        </small>
                      </div>
                      <p className="mb-1">{i.invitation_accepted_at ? <span className="text-success">Accepted</span> : <span className="text-info">Waiting on acceptance</span>}</p>
                    </div>)}
                </div>}
                {invitations.length == 0 && <p>No one has accepted any invitations :/</p>}
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-secondary btn-sm rounded-pill" onClick={props.onClose || toggle}>Close</button>
            </ModalFooter>
          </Modal>
        </>;
}

export default InvitationsModal;

import React, {useState, useEffect, useContext} from "react";
import {AppContext} from "../AppContext"
import WorkspaceViewModel from "../models/WorkspaceViewModel";
import WorkspaceUserViewModel from "../models/WorkspaceUserViewModel";
import UserViewModel from "../models/UserViewModel";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const InvitedForModal = () => {

  const context = useContext(AppContext);
  const current_user = context.current_user;
  const [invitedToWorkspace,setInvitedToWorkspace] = useState({})
  const [pendingWorkspaceUser,setPendingWorkspaceUser] = useState({})
  const [modal,setModal] = useState(false)
  const [updatingInvitation,setUpdatingInvitation] = useState(false)
  const [error,setError] = useState(false)
  const toggle = () => setModal(!modal);
  
  const acceptInvitation = () => {
    
    setUpdatingInvitation(true)

    if (pendingWorkspaceUser.id){
      WorkspaceUserViewModel.update(pendingWorkspaceUser.id,{invitation_status:"completed"},(w_u) => {
        if (w_u && w_u instanceof WorkspaceUserViewModel) {
          setModal(false)
          window.location.href = `/home?workspace_added=${invitedToWorkspace.name}`
        }else setError(w_u)
      })
    }else{
      WorkspaceViewModel.addUser(invitedToWorkspace.objectId,current_user.id,(w_u) => {
        setUpdatingInvitation(false)
        if (w_u && w_u instanceof WorkspaceUserViewModel) {
          setModal(false)
          window.location.href = `/home?workspace_added=${invitedToWorkspace.name}`
        }else setError(w_u)
      })
    }
    
  }

  const denyInvitation = () => {
    setUpdatingInvitation(true)
    
    if (pendingWorkspaceUser.id){
      WorkspaceUserViewModel.destroy(pendingWorkspaceUser.id,(w_u) => {
        if (w_u) {
          setModal(false)
          window.location.href = `/home`
        }else setError(w_u)
      })
    }else{
      UserViewModel.clearSession(() => {
        setModal(false)
        setUpdatingInvitation(false)
      })
    }
  }

  useEffect(() => {
    UserViewModel.inviter(null,(response) => {
      if (response && response.invited_for && response.invited_for instanceof WorkspaceViewModel) {
        setInvitedToWorkspace({...response.invited_for})
        setModal(true)
      }else{
        WorkspaceUserViewModel.loadPending((wus) => {
          if (wus.length > 0){
            setInvitedToWorkspace({...wus[0].workspace})
            setPendingWorkspaceUser({...wus[0]})
            setModal(true)
          }
        })
      }
    })
  },[])

  return <>
          {
            invitedToWorkspace.name && 
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Invited to Workspace</ModalHeader>
              <ModalBody className="text-center">
                <p className="mb-0">You were invited to join the workspace <strong>{invitedToWorkspace.name}</strong>?</p>
                <p><small><em>Joining workspace will prorate and cancel any existing <strong>paid subscriptions</strong>.</em></small></p>
                <button class="btn btn-success btn-sm my-3 mr-3 rounded-pill" onClick={acceptInvitation} disabled={updatingInvitation}>Accept invite</button>
                <button class="btn btn-danger btn-sm my-3 rounded-pill" onClick={denyInvitation} disabled={updatingInvitation}>No thanks</button>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-secondary btn-sm rounded-pill" onClick={toggle}>Close</button>
              </ModalFooter>
            </Modal>
          }
        </>;
}

export default InvitedForModal;

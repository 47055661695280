const getParam = (key) => {
  let params = new URLSearchParams(location.search);
  return params.get(key)
}

const setParam = (key, value) => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = document.location.search.substr(1).split('&');
  let i=0;

  for(; i<kvp.length; i++){
      if (kvp[i].startsWith(key + '=')) {
          let pair = kvp[i].split('=');
          pair[1] = value;
          kvp[i] = pair.join('=');
          break;
      }
  }

  if(i >= kvp.length){
      kvp[kvp.length] = [key,value].join('=');
  }

  // can return this or...
  let params = kvp.join('&');

  // reload page with new params
  document.location.search = params;

  if (history.pushState) {
      window.history.pushState("object or string", "Page Title", "/newURL");
  } else {
      document.location.href = "/newURL";
  }
  
}

const setParams = (object) => {
  Object.keys(object).forEach((key) => setParam(key,object[key]))
}

const serialize = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

export {
  getParam,
  setParam,
  setParams,
  serialize
};
import React,{useEffect,useContext} from "react";
import {AppContext} from "../AppContext"
import {NavLink} from "react-router-dom"
import OnboardingNavbar from "../components/Navbars/OnboardingNavbar"
import OnboardingTabbar from "../components/Navbars/OnboardingTabbar"
import {useWindowSize} from 'react-use'
import Emoji from "react-emoji-render"
import Confetti from 'react-confetti'
import UserViewModel from '../models/UserViewModel'
import {Mixpanel} from '../helpers/Mixpanel'

const Download = (props) => {
  
  const { width, height } = useWindowSize()
  const context = useContext(AppContext);

  useEffect(() => {
    Mixpanel.track("Download Page Loaded")
    UserViewModel.update({onboarding_complete:true},context.loadUser)
  },[]);


  return <div className="wrapper">
    <Confetti
      width={width}
      height={height}
      recycle={false}
      numberOfPieces={500}
    />
    <OnboardingNavbar />
    <div className="mt-2 mb-5">
      <div className="container">
        <OnboardingTabbar clickable={false} />
        <div className="card border-0">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-center">
                <h3 className="font-weight-bolder mt-5"><Emoji text=":tada:" /> Yay! You did it! <Emoji text=":tada:" /></h3>
                <h1 className="mt-1 display-1 mb-2"><strong>Welcome to Limelite</strong></h1>
                <p className="lead my-0">Now for the best part....the actual app <Emoji text=":upside_down_face:" /></p>
                <a type="button" className="w-75 btn btn-primary btn-lg btn-block mt-5 rounded-pill mx-auto" target="_blank" href="https://chrome.google.com/webstore/detail/limelite-chrome-extension/idgikkomiedghejlmgieapbjmjpahgjh" onClick={() => Mixpanel.track("Clicked Download Limelite")} > Download Limelite</a>
                <NavLink to="/home" className="btn btn-link btn-sm text-light btn-block mt-3 mb-4 rounded-pill mx-auto"> I've already downloaded Limelite </NavLink>
                <p className="display-5 font-weight-bolder">It's in the chrome store. <br/>After download we'll bring you back here for a <span className="text-info">short tutorial</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export {Download};
import React from "react";

const Loader = (props) => {
  return <div className={props.containerClass || `container d-flex h-100 align-items-center justify-content-center`}>
  <div className="spinner-grow text-default" role="status">
    <span className="sr-only">Loading...</span>
  </div>
</div>;
};

export {Loader};